import React, { useState } from "react";
import PropTypes from "prop-types";

const SingleInput = (props) => {
  return (
    <div className="form-group">
      <label className="form-label">{props.title}</label>
      <input
        className="input-fields"
        id={props.id}
        name={props.name}
        readOnly={props.readOnly}
        type={props.inputType}
        value={props.content}
        onChange={props.controlFunc}
        autoComplete={props.autocomplete ? "off" : ""}
        placeholder={props.placeholder}
        onFocus={() => props.handleFocus(props.name)}
      />
      <span className="text-danger">{props.error}</span>
    </div>
  );
};

SingleInput.propTypes = {
  inputType: PropTypes.oneOf([
    "text",
    "number",
    "email",
    "password",
    "hidden",
    "tel",
  ]).isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  controlFunc: PropTypes.func,
  readOnly: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
};

export default SingleInput;
