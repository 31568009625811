import {request} from "../constants/axios-warpers";
import qs from "qs";

const fetchingHospitals = async () => {
  const response = await request.get("hospitals?");

  return response.data["hydra:member"];
};

const fetchOneHospital = async (id) => {
  const response = await request.get("hospitals/" + id);
  return response.data;
};

const addHospital = async (form) => {
  const response = await request.post("hospitals", form);

  return response.data;
};

const editHospital = async (formPayload) => {
  const {id} = formPayload;
  const response = await request.put("hospitals/" + id, formPayload);

  return response.data;
};

const fetchingHospitalsSearch = async (filters = {}) => {
  let searchInput = false;
  if (filters.search) {
    searchInput = true;
    delete filters.search;
  }

  const respone = await request.get("hospitals?" + qs.stringify(filters));
  return respone.data["hydra:member"];
};

const fetchingHospitalsInput = async (filters = {}) => {
  let searchInput = false;
  if (filters.search) {
    searchInput = true;
    delete filters.search;
  }

  const respone = await request.get("hospitals?" + qs.stringify(filters));
  return respone.data["hydra:member"];
};

const hospitalRequest = async (hospitalData) => {
  const response = await request.post(`hospitals/${hospitalData}/requests`);
  return response.data;
};

const hospitalApiService = {
  fetchingHospitals,
  fetchOneHospital,
  addHospital,
  editHospital,
  fetchingHospitalsSearch,
  fetchingHospitalsInput,
  hospitalRequest,
};

export default hospitalApiService;
