import React, { useEffect, useState } from "react";
import NavigationBar from "../../../components/common/navigation-bar";
import { useSelector, useDispatch } from "react-redux";
import { fetchOne, reset } from "../../../slice/userSlice";
import { Link, useParams } from "react-router-dom";
import Notification from "../../../components/notification/notification";
import Header from "../../../components/common/header";
import Paginator from "react-hooks-paginator";
import Loader from "../../../components/Loader/loader";
import "../../../less/user-notifications-tab.less";
import "../../../less/pagination.less";
import "../../../less/tabs.less";

const UserNotification = () => {
  const { viewedUser, isLoading } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const params = useParams();

  const pageLimit = 10;

  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState([]);

  useEffect(() => {
    dispatch(fetchOne(params.id));
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    setData(viewedUser?.notifications);
  }, [viewedUser?.notifications]);

  useEffect(() => {
    setCurrentData(data?.slice(offset, offset + pageLimit));
  }, [offset, data]);

  const getTitle = () => {
    return `${viewedUser?.firstName || ""} ${viewedUser?.lastName || ""}`;
  };

  const renderNotificationsList = () =>
    currentData?.map((notification) => (
      <Notification key={notification.id} notification={notification} />
    ));

  return (
    <div className="user-notifications-tab">
      <Header />
      <Loader loading={isLoading} />
      <NavigationBar
        title={getTitle()}
        actionComponent={null}
        backAction="/users-list/active"
        backText="Lista"
      />

      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 details-tabs text-center">
            <div>
              <Link to={"/users/" + viewedUser?.id}>Informatii</Link>
            </div>
            <div>
              <Link to={"/users/" + viewedUser?.id + "/access-history"}>
                Istoric acces
              </Link>
            </div>
            <div className="active">Notificari</div>
          </div>
        </div>
        <div className="row details-tab-contents">
          <div className="user-info-view container-fluid">
            <div className="row">
              <div className="list-group">{renderNotificationsList()}</div>

              <div className="text-center">
                <Paginator
                  totalRecords={data?.length || 0}
                  pageLimit={pageLimit}
                  pageNeighbours={2}
                  setOffset={setOffset}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserNotification;
