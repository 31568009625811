import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SingleInput from "../../../components/forms/general/single-input";

const HospitalForm = (props) => {
  const [form, setFormData] = useState({});
  useEffect(() => {
    setFormData({
      ...form,
      name: props.name,
      street: props.street,
      streetNumber: props.streetNumber,
      zipCode: props.zipCode,
      country: props.country,
      county: props.county,
      city: props.city,
      contactPerson: props.contactPerson,
    });
  }, [props]);

  return (
    <div className="col-sm-12 col-md-12 inner-form no-avatar-forms">
      <div className="col-sm-12 col-md-12 fields-container">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <SingleInput
              inputType="text"
              title="Nume Asociatie &#42;"
              name="name"
              content={form.name || ""}
              controlFunc={(e) => props.handleChanges(e)}
              placeholder=""
              error={props.errors.name}
              handleFocus={props.handleFocus}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-7">
            <SingleInput
              inputType="text"
              title="Strada &#42;"
              name="street"
              content={form.street || ""}
              controlFunc={(e) => props.handleChanges(e)}
              placeholder=""
              error={props.errors.street}
              handleFocus={props.handleFocus}
            />
          </div>
          <div className="col-sm-12 col-md-2">
            <SingleInput
              inputType="number"
              title="Nr. &#42;"
              name="streetNumber"
              content={form.streetNumber || ""}
              controlFunc={(e) => props.handleChanges(e)}
              placeholder=""
              error={props.errors.streetNumber}
              handleFocus={props.handleFocus}
            />
          </div>
          <div className="col-sm-12 col-md-3">
            <SingleInput
              inputType="number"
              title="Cod Postal &#42;"
              name="zipCode"
              content={form.zipCode || ""}
              controlFunc={(e) => props.handleChanges(e)}
              placeholder=""
              error={props.errors.zipCode}
              handleFocus={props.handleFocus}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-4">
            <SingleInput
              inputType="text"
              title="Oras &#42;"
              name="city"
              placeholder=""
              content={form.city || ""}
              controlFunc={(e) => props.handleChanges(e)}
              error={props.errors.city}
              handleFocus={props.handleFocus}
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <SingleInput
              inputType="text"
              title="Judet &#42;"
              name="county"
              placeholder=""
              content={form.county || ""}
              controlFunc={(e) => props.handleChanges(e)}
              error={props.errors.county}
              handleFocus={props.handleFocus}
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <SingleInput
              inputType="text"
              title="Tara &#42;"
              name="country"
              placeholder=""
              content={form.country || ""}
              controlFunc={(e) => props.handleChanges(e)}
              error={props.errors.country}
              handleFocus={props.handleFocus}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 bottom-action-flex">
            {props.actionContainer}
          </div>
        </div>
      </div>
    </div>
  );
};

HospitalForm.propTypes = {
  handleChanges: PropTypes.func.isRequired,
};

export default HospitalForm;
