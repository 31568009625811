import React from "react";
import PropTypes from "prop-types";
import SingleInput from "../../../components/forms/general/single-input";
import UploadInput from "../../../components/forms/general/upload-input";

const UserForm = (props) => {
  const {
    firstName,
    lastName,
    plainPassword,
    phone,
    email,
    image,
    avatarFilePath,
    id,
    roles,
  } = props;

  const handleChanges = (e) => {
    let element = e.target;
    const dataRecord = {
      firstName,
      lastName,
      plainPassword,
      phone,
      email,
      image,
      avatarFilePath,
      id,
      roles,
    };

    props.sendValues({
      ...dataRecord,
      [element.name]: element.value,
    });

    element.parentNode.classList.remove("has-error");
  };

  return (
    <div className="col-sm-12 col-md-12 col-xs-12 inner-form">
      <UploadInput
        containerClass={["col-sm-2", "col-md-2", "user-avatar"]}
        image={avatarFilePath}
        resource="user"
      />
      <div className="col-sm-10 col-md-10 col-xs-10 fields-container">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-xs-12">
            <SingleInput
              id="firstName"
              inputType="text"
              title="Prenume &#42;"
              name="firstName"
              content={firstName || ""}
              controlFunc={handleChanges}
              placeholder=""
              required
              error={props.errors.firstName}
              handleFocus={props.handleFocus}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-xs-12">
            <SingleInput
              id="lastName"
              inputType="text"
              title="Nume &#42;"
              name="lastName"
              content={lastName || ""}
              controlFunc={handleChanges}
              placeholder=""
              error={props.errors.lastName}
              handleFocus={props.handleFocus}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-xs-12">
            <SingleInput
              id="email"
              inputType="email"
              title="Email &#42;"
              name="email"
              content={email || ""}
              controlFunc={handleChanges}
              placeholder=""
              error={props.errors.email}
              handleFocus={props.handleFocus}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-xs-12">
            <SingleInput
              id="phone"
              autocomplete
              inputType="tel"
              title="Telefon &#42;"
              name="phone"
              content={phone || ""}
              controlFunc={handleChanges}
              placeholder=""
              error={props.errors.phone}
              handleFocus={props.handleFocus}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-xs-12">
            <SingleInput
              autocomplete
              id="plainPassword"
              inputType="password"
              title={id ? "Parola" : "Parola *"}
              name="plainPassword"
              content={plainPassword || ""}
              controlFunc={handleChanges}
              placeholder=""
              error={props.errors.plainPassword}
              handleFocus={props.handleFocus}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-xs-12 bottom-action-flex">
            {props.actionsContainer}
          </div>
        </div>
      </div>
    </div>
  );
};

UserForm.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  plainPassword: PropTypes.string.isRequired,
};

UserForm.defaultProps = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  plainPassword: "",
};

export default UserForm;
