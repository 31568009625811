import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import PermissionsGate from "../../security/permisionGate";
import { canAddUserAcces } from "../../security/customPermission";

const AddUserAccessButton = (props) => {
  return (
    <PermissionsGate customPermissionFn={canAddUserAcces}>
      <div className={props.containerClass}>
        <Link
          to={"/users/" + props.userId + "/state"}
          className={props.buttonClass}
        >
          Adauga acces
        </Link>
      </div>
    </PermissionsGate>
  );
};

AddUserAccessButton.propTypes = {
  buttonClass: PropTypes.string.isRequired,
};

AddUserAccessButton.defaultProps = {
  containerClass: "col-sm-3 col-md-3 button-container",
};

export default AddUserAccessButton;
