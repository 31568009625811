export const validateForm = (formData) => {
  let valid = true;
  Object.keys(formData).map((key) => {
    if (formData[key] === "" || typeof formData[key] === "undefined") {
      document
        .getElementsByName(key)[0]
        .closest(".form-group")
        .classList.add("has-error");
    }
  });

  if (document.getElementsByClassName("has-error").length > 0) {
    valid = false;
  }

  return valid;
};

export const getFormErrors = (values, schema) => {
  try {
    const isValid = schema.validateSync(values, {
      abortEarly: false,
    });
    if (isValid) {
      return {};
    }
  } catch (err) {
    const errors = err.inner.reduce((acc, error) => {
      return {
        ...acc,
        [error.path]: error.message,
      };
    }, {});
    return errors;
  }
};
