import React, { useState } from "react";
import ModalWithPortal from "./modal-portal";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetPopup } from "../../slice/userSlice";

const ModalPopup = () => {
  const {modalPopup} = useSelector(state => state.users) 
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(resetPopup());
  };
  return (
    modalPopup?.show && (
      <ModalWithPortal modalState={modalPopup?.show}>
        <div className="popup">
          <p className="text-center">{modalPopup?.content}</p>
          <button
            className="green-link"
            onClick={handleClick}
          >
            Inainte
          </button>
        </div>
      <div className="popup-bg" />
      </ModalWithPortal>
    )
  );
};

export default ModalPopup;
