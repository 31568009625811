import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import PermissionsGate from "../../security/permisionGate";
import { canAddPatient } from "../../security/customPermission";

const EditPatientButton = (props) => {
  return (
    <PermissionsGate customPermissionFn={canAddPatient}>
      <div className={props.containerClass}>
        <Link
          to={"/patients-list/" + props.patientId + "/edit"}
          className={props.buttonClass}
        >
          Editeaza pacient
        </Link>
      </div>
    </PermissionsGate>
  );
};


EditPatientButton.propTypes = {
  containerClass: PropTypes.string,
  buttonClass: PropTypes.string.isRequired,
};

EditPatientButton.defaultProps = {
  containerClass: "col-sm-3 col-md-3 col-xs-3 button-container show",
};

export default EditPatientButton;
