import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Field } from "react-final-form";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";

import NavigationBar from "../../../components/common/navigation-bar";
import Loader from "../../../components/Loader/loader";
import SavePatientButton from "../../../components/Buttons/save-patient-button";
import { ReactSelectAdapter } from "../../../components/forms/Adapter/adapter";
import { toast } from "react-toastify";

import {
  setHospitalInput,
  setPatientResidentInput,
  setPatientSupervisorInput,
  setSupervisorsInput,
} from "../../../slice/patientSlice";

import {
  mapResponseCustomStates,
  mapResponseErrorObject,
  usersDefaultValues,
} from "../../../helpers/response-map-functions";
import { selectedHospitalUsers } from "../../../helpers/response-map-functions";
import { prepareFormValues, validateFormValues } from "../data/helpers";
import {
  patientStatuses,
  genderOptions,
  patientStudies,
  patientEthnicGroup,
} from "../data/selectOptions";
import "../../../less/datepicker.less";
import ModalPopup from "../../../components/common/modal-popup";
import {
  idToIriTransformer,
  iriToIdTransformer,
} from "../../../helpers/iri-transformer";
import { useDetermineRouteByRole } from "../../../security/useDetermineRouteByRole";
import ErrorField from "../../../components/forms/ErrorField";

const Patient = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loggedInUser, selectedState } = useSelector((state) => state.users);
  const { isLoading: isLoadingGeneral } = useSelector(
    (state) => state.patients
  );

  const redirectTo = useDetermineRouteByRole({
    role: "ROLE_SUPPORT_MEDIC",
    route: "/all-support-request",
    defaultRoute: "/patients-list/active",
  });

  const { data: hospitalsInput, isLoading: loadingPinput } = useSelector(
    (state) => state.patients.hospitalsInput
  );
  const { data: supervisorsInput, isLoading: loadingSinput } = useSelector(
    (state) => state.patients.supervisorsInput
  );
  const { data: residentsInput, isLoading: loadingRinput } = useSelector(
    (state) => state.patients.residentsInput
  );
  const regId = idToIriTransformer("registries", localStorage.getItem("regId"));
  const regName = localStorage.getItem("regName")?.toLocaleLowerCase();
  const { viewedPatient } = useSelector((state) => state.patients);
  const patientCase = viewedPatient?.patientCases?.filter(
    (item) => item?.registry === regId
  );
  const patientCaseId = iriToIdTransformer(patientCase?.[0]?.["@id"]);
  const isLoading =
    loadingPinput || loadingRinput || loadingSinput || isLoadingGeneral;
  const [supervisorsList, setSupervisorsList] = useState([]);
  const [residentsList, setResidentsList] = useState([]);
  const [isSameUser, setIsSameUser] = useState(false);
  const [sameFilters, setIsSameFilters] = useState([]);

  const { modalPopup } = useSelector((state) => state.users);
  const supervisorOptions = mapResponseCustomStates(
    supervisorsList,
    hospitalsInput?.[0] || []
  );
  const residentOptions = useMemo(
    () => mapResponseCustomStates(residentsList, hospitalsInput?.[0] || []),
    [residentsList, hospitalsInput?.[0]]
  );
  let isInitialValuesEmpty = Object.keys(props.initialValues)?.length < 1;

  const residentDefaultValue = usersDefaultValues({
    isInitialValuesEmpty,
    selectedState,
    role: "ROLE_RESIDENT",
    userOptions: residentOptions,
  });

  useEffect(() => {
    if (
      selectedState?.roles?.indexOf("ROLE_FAMILY_MEDIC") !== -1 &&
      regName === "telemedicina"
    ) {
      setIsSameUser(true);
      if (residentOptions && residentOptions.length > 0) {
        const { ["@id"]: itemFound } = loggedInUser.states.find(
          (item) => item.id === selectedState?.id
        );

        const filteredResidentOptions = residentOptions?.filter(
          (item) => item?.value === itemFound
        );

        setIsSameFilters(filteredResidentOptions);
      }
    }
  }, [selectedState, residentOptions]);

  useEffect(() => {
    let hospitalFilters = {};
    if (loggedInUser?.roles.indexOf("ROLE_USER") !== -1) {
      hospitalFilters["id"] = selectedState?.hospital.id;
    }
    dispatch(setHospitalInput(hospitalFilters));

    const supervisorFilters = {};
    const residentFilters = {};
    residentFilters["states.hospital"] = selectedState?.hospital["@id"];
    supervisorFilters["states.hospital"] = selectedState?.hospital["@id"];

    if (
      null !== selectedState &&
      selectedState?.roles.indexOf("ROLE_HOSPITAL_ADMIN") !== -1
    ) {
      supervisorFilters["states.hospital"] = selectedState?.hospital["@id"];
      residentFilters["states.hospital"] = selectedState?.hospital["@id"];
    }

    if (
      null !== selectedState &&
      selectedState?.roles.indexOf("ROLE_SUPERVISOR") !== -1
    ) {
      // residentFilters["states.hospital"] = selectedState?.hospital["@id"];
      // residentFilters["states.supervisor"] = loggedInUser["@id"];
      dispatch(setPatientResidentInput(residentFilters));
      dispatch(setSupervisorsInput([loggedInUser]));

      return;
    }

    dispatch(setPatientResidentInput(residentFilters));
    dispatch(setPatientSupervisorInput(supervisorFilters));
  }, []);

  useEffect(() => {
    setSupervisorsList(supervisorsInput);
  }, [supervisorsInput]);

  useEffect(() => {
    setResidentsList(residentsInput);
  }, [residentsInput]);
  const onSubmit = async (values) => {
    const formData = prepareFormValues(values);
    const caseForm = {
      registry: `api/v1/registries/${localStorage.getItem("regId")}`,
      residentState: values.resident?.value,
      supervisorState: values.supervisor?.value,
      hospital: selectedState?.hospital?.["@id"],
    };

    if (props.patientToAddCase) {
      try {
        const response = await dispatch(
          props.submitSecondAction({
            ...caseForm,
            patient: idToIriTransformer(
              "patients",
              props?.patientToAddCase?.id
            ),
          })
        ).unwrap();
        if (response) {
          toast.success("Informatii adaugate cu succes!");
          navigate(`/patients-list/${props?.patientToAddCase?.id}/forms`);
          return;
        }
      } catch (err) {
        toast.error(err);
      }
    }
    try {
      const isInitialValues = Object.keys(props.initialValues).length > 0;
      const response = await dispatch(props.submitAction(formData)).unwrap();
      const responsePatientCase = await dispatch(
        props.submitSecondAction({
          ...caseForm,
          patient: response?.["@id"],
          ...(isInitialValues ? { id: patientCaseId } : {}),
        })
      ).unwrap();
      if (response && responsePatientCase) {
        toast.success(
          `Informatii ${
            isInitialValues > 0 ? "editate" : "adaugate"
          } cu succes!`
        );
        if (!isInitialValues) {
          navigate(`/patients-list/${response.id}/forms`);
        }
      }
    } catch (err) {
      if (err.violations?.length > 0) {
        return mapResponseErrorObject(err.violations);
      } else {
        toast.error(err.message);
      }
    }
  };
  const handleHospitalChange = (selectedValue, setFormValue) => {
    if (loggedInUser?.roles.indexOf("ROLE_SUPER_ADMIN") !== -1) {
      setFormValue("supervisor", "");
      setFormValue("resident", "");
      setSupervisorsList(
        selectedHospitalUsers(selectedValue.value, supervisorsInput)
      );
      setResidentsList(
        selectedHospitalUsers(selectedValue.value, residentsInput)
      );
    }
  };

  return (
    <>
      <NavigationBar
        title={props.title}
        actionComponent={
          <SavePatientButton
            buttonClass="green-link"
            patientId={props.initialValues?.id || ""}
          />
        }
        action="save"
        backAction={redirectTo}
      />
      <Loader loading={isLoading} />
      <div className="container-fluid">
        <div className="container forms-container">
          <Form
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            onSubmit={onSubmit}
            validate={validateFormValues}
            initialValues={props.initialValues}
            keepDirtyOnReinitialize
            render={({ handleSubmit, form, submitErrors }) => (
              <form
                id="patient-form"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                {/*Forma Patient*/}
                <div className="col-sm-12 col-md-12 inner-form no-avatar-forms">
                  <div className="fields-container">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Prenume &#42;</label>
                          <Field
                            name="firstName"
                            component="input"
                            className="input-fields"
                            disabled={props?.patientToAddCase}
                          />
                          <ErrorField name="firstName" />
                          {submitErrors && (
                            <div className="text-danger ml-1">
                              {submitErrors?.["firstName"]}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Nume &#42;</label>
                          <Field
                            name="lastName"
                            component="input"
                            className="input-fields"
                            type="text"
                            disabled={props?.patientToAddCase}
                          />
                          <ErrorField name="lastName" />
                          {submitErrors && (
                            <div className="text-danger ml-1">
                              {submitErrors?.["lastName"]}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">CNP &#42;</label>
                          <Field
                            name="cnp"
                            component="input"
                            className="input-fields"
                            type="number"
                            initialValue={
                              props?.cnp
                                ? props?.cnp
                                : props?.initialValues?.cnp ?? undefined
                            }
                            disabled={props?.patientToAddCase}
                          />
                          <ErrorField name="cnp" />
                          {submitErrors && (
                            <div className="text-danger ml-1">
                              {submitErrors?.["cnp"]}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Varsta</label>
                          <Field
                            name="age"
                            component="input"
                            className="input-fields"
                            type="number"
                            disabled={props?.patientToAddCase}
                          />
                          <ErrorField name="age" />
                          {submitErrors && (
                            <div className="text-danger ml-1">
                              {submitErrors?.["age"]}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Sex</label>
                          <Field
                            name="gender"
                            component={ReactSelectAdapter}
                            options={genderOptions}
                            isDisabled={props?.patientToAddCase}
                          />
                          <ErrorField name="gender" />
                          {submitErrors && (
                            <div className="text-danger ml-1">
                              {submitErrors?.["gender"]}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Nivel educatie</label>
                          <Field
                            name="educationLevel"
                            component={ReactSelectAdapter}
                            options={patientStudies}
                            isDisabled={props?.patientToAddCase}
                          />
                          <ErrorField name="educationLevel" />
                          {submitErrors && (
                            <div className="text-danger ml-1">
                              {submitErrors?.["educationLevel"]}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Etnie &#42;</label>
                          {/* <Field
                                    name="ethnicGroup"
                                    component="select"
                                    className="input-fields"
                                    disabled={props?.patientToAddCase}
                                />
                                <ErrorField name="ethnicGroup"/>
                                {submitErrors && (
                                    <div className="text-danger ml-1">
                                      {submitErrors?.['ethnicGroup']}
                                    </div>
                                )} */}
                          <Field
                            name="ethnicGroup"
                            component={ReactSelectAdapter}
                            options={patientEthnicGroup}
                            isDisabled={props?.patientToAddCase}
                          />
                          <ErrorField name="ethnicGroup" />
                          {submitErrors && (
                            <div className="text-danger ml-1">
                              {submitErrors?.["ethnicGroup"]}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-12">
                        <div className="form-group">
                          <label className="form-label">Adresa domiciliu</label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <label className="form-label">Localitate</label>
                        <Field
                          name="city"
                          component="input"
                          className="input-fields"
                          disabled={props?.patientToAddCase}
                        />
                        {submitErrors && (
                          <div className="text-danger ml-1">
                            {submitErrors?.["city"]}
                          </div>
                        )}
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <label className="form-label">Comuna</label>
                        <Field
                          name="commune"
                          component="input"
                          className="input-fields"
                          disabled={props?.patientToAddCase}
                        />
                        {submitErrors && (
                          <div className="text-danger ml-1">
                            {submitErrors?.["commune"]}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Judet</label>
                          <Field
                            name="county"
                            component="input"
                            className="input-fields"
                            disabled={props?.patientToAddCase}
                          />
                          <ErrorField name="county" />
                          {submitErrors && (
                            <div className="text-danger ml-1">
                              {submitErrors?.["county"]}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 mt-12">
                        <div className="form-group">
                          <label className="form-label">Medici</label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {`${
                              regName === "telemedicina"
                                ? "Medic familie*"
                                : "Medic Teren*"
                            }`}
                          </label>
                          <Field
                            name="resident"
                            component={ReactSelectAdapter}
                            initialValue={residentDefaultValue}
                            options={isSameUser ? sameFilters : residentOptions}
                          />
                          <ErrorField name="resident" />
                          {submitErrors && (
                            <div className="text-danger ml-1">
                              {submitErrors?.["resident"]}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">{`${
                            regName === "telemedicina"
                              ? "Medic suport ecografie"
                              : "Medic Specialist"
                          }`}</label>
                          <Field
                            name="supervisor"
                            component={ReactSelectAdapter}
                            options={supervisorOptions}
                          />
                          <ErrorField name="supervisor" />
                          {submitErrors && (
                            <div className="text-danger ml-1">
                              {submitErrors?.["supervisor"]}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Status</label>
                          <Field
                            name="active"
                            component={ReactSelectAdapter}
                            initialValue={patientStatuses.find(
                              (item) => item.value === true
                            )}
                            options={patientStatuses}
                            isDisabled={props?.patientToAddCase}
                          />
                          {submitErrors && (
                            <div className="text-danger ml-1">
                              {submitErrors?.["active"]}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bottom-actions-container centered-actions">
                  <Link to={redirectTo} className="back-link">
                    Inapoi
                  </Link>
                  <input
                    type="submit"
                    className="green-button"
                    value="Salveaza"
                  />
                </div>
              </form>
            )}
          />
        </div>
      </div>
      {modalPopup.show && <ModalPopup content={modalPopup.content} />}
    </>
  );
};

Patient.propTypes = {
  title: PropTypes.string,
  // submitAction: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  // submitSecondAction: PropTypes.func.isRequired,
};

Patient.defaultProps = {
  title: "Pacient",
  initialValues: {},
};

export default Patient;
