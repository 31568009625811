import React, { useEffect } from "react";
import Loader from "../../../components/Loader/loader";
import Header from "../../../components/common/header";
import { useSelector } from "react-redux";

const HospitalsDashboard = (props) => {
  const { isLoading } = useSelector((state) => state.hospitals);

  return (
    <div>
      <Header />

      <Loader loading={isLoading} />
      {props.children}
    </div>
  );
};

export default HospitalsDashboard;
