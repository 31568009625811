import React, { useState } from "react";
import PropTypes from "prop-types";
import SubGroup from "./SubGroup";
import SubGroupNav from "./SubGroupNav";

const Group = (props) => {
  const [activeSubGroupTab, setActiveSubGroupTab] = useState(0);

  const changeSubGroupTab = (event) => {
    if (event.target.classList.contains("active")) {
      return;
    }

    setActiveSubGroupTab(parseInt(event.target.getAttribute("data-index")));
  };

  return (
    <div className={props.isActive ? "" : "hidden"}>
      <div className="details-tabs justified">
        {props.group.subGroups.map((subGroup, index) => {
          return (
            <span
              onClick={changeSubGroupTab}
              className={activeSubGroupTab === index ? "active" : ""}
              key={index}
              data-index={index}
            >
              {subGroup.name}
            </span>
          );
        })}
      </div>

      <div className="form-sub-group-contents">
        {props.group.subGroups.map((subGroup, index) => {
          return (
            <SubGroup
              subGroup={subGroup}
              isActive={activeSubGroupTab === index}
              key={index}
              canEdit={props.canEdit}
            />
          );
        })}
      </div>

      <SubGroupNav
        activeSubGroupTab={activeSubGroupTab}
        changeSubGroupTab={changeSubGroupTab}
        subGroups={props.group.subGroups}
        canEdit={props.canEdit}
      />
    </div>
  );
};

Group.propTypes = {
  group: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
};

Group.defaultProps = {
  isActive: true,
};

export default Group;
