export const tabs = [
    {
      name: "Toate cererile suport",
      filter:  {
        name: null,
      }
    },
    {
      name: "Cereri fara raspuns",
      filter:  {
        name:'hasMonitoringForm',
      }
    },
    {
      name: "Cererile mele",
      filter:  {
        name:'patientCase.supervisorState.user.id',
      }
    },
  ];