import React from "react";
import { useSelector } from "react-redux";
import { ROLES } from "./permission-map";
import { useGetRole } from "./useGetRole";
import { caravanPermission } from "./caravanPermission";

const useGetEditFormPermission = (formType) => {
  const { states: loggedInUserStates } = useSelector(
    (state) => state?.users?.loggedInUser
  );

  const regName = localStorage.getItem('regName')?.toLocaleLowerCase()
  const { roles } = useSelector((state) => state.users.selectedState ?? {});
  const [userRole] = roles ?? [];

  const { role } = useGetRole();
  const isSuperAdmin = role === ROLES["admin"];
  const canEdit = caravanPermission[regName]?.[formType]?.['canEdit']?.includes(userRole) || isSuperAdmin

  return [canEdit]
};

export default useGetEditFormPermission;
