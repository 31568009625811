import React from "react";

const savePatientButton = (props) => {
  return (
    <div className="col-sm-3 col-md-3 col-xs-3 button-container show">
      <button form="patient-form" className="green-button">
        Salveaza
      </button>
    </div>
  );
};

export default savePatientButton;
