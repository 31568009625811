import React, { useState } from "react";
import PropTypes from "prop-types";

import Group from "./Group";
import NavigationBar from "../../../../components/common/navigation-bar";

import "../../../../less/tabs.less";
import "../../../../less/patient-form.less";

const PatientForm = (props) => {
  const [activeGroupTab, setActiveGroupTab] = useState(0);

  const changeGroupTab = (event) => {
    if (event.target.classList.contains("active")) {
      return;
    }

    setActiveGroupTab(parseInt(event.target.getAttribute("data-index")));
  };

  return (
    <div>
      <NavigationBar
        title={props.form.template.name}
        action="add-pacient-form"
        backAction={props.backAction}
      />
      <div className="container patient-form">
        <div className="row">
          <div className="form-group-tabs details-tabs text-center">
            {props.form.template.groups.map((group, index) => {
              return (
                <div
                  onClick={changeGroupTab}
                  className={
                    "clickable-tab " +
                    (activeGroupTab === index ? "active" : "")
                  }
                  key={index}
                  data-index={index}
                >
                  {group.name}
                </div>
              );
            })}
          </div>
        </div>

        <div className="form-group-contents forms-container">
          <form
            className="form-horizontal"
            autoComplete="off"
            onSubmit={props.handleSubmit}
          >
            {props.form.template.groups.map((group, index) => {
              return (
                <Group
                  group={group}
                  isActive={activeGroupTab === index}
                  key={index}
                  canEdit={props.canEdit}
                />
              );
            })}
          </form>
        </div>
      </div>
    </div>
  );
};

PatientForm.propTypes = {
  form: PropTypes.shape({
    patient: PropTypes.object,
    template: PropTypes.object,
  }),
  values: PropTypes.object,
  backAction: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default PatientForm;
