import React, { useEffect } from "react";
import NavigationBar from "../../../components/common/navigation-bar";
import Header from "../../../components/common/header";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getUserResults, reset } from "../../../slice/userSlice";
import {
  getHospitalsSearchResults,
  resetHslice,
} from "../../../slice/hospitalSlice";
import {
  getPatientSearchResults,
  resetPlist,
} from "../../../slice/patientSlice";
import ListingUsers from "../../users/components/UsersList";
import PatientsList from "../../patients/components/PatientsList";
import Loader from "../../../components/Loader/loader";
import { Hospital } from "../../../components/common/list-items";

const SearchResults = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const {
    searchResultsUsers,
    loggedInUser,
    selectedState,
    isLoading: isUserLoading,
  } = useSelector((state) => state.users);
  const { patientSearchResults, isLoading: isPatientLoading } = useSelector(
    (state) => state.patients
  );
  const { hospitalsSearchResult, isLoading: isHospitalLoading } = useSelector(
    (state) => state.hospitals
  );

  const isLoading = isUserLoading || isPatientLoading || isHospitalLoading;
  const fetchData = (query) => {
    let userFilters = {
      fullName: query,
    };
    if (loggedInUser?.roles.indexOf("ROLE_USER") !== -1) {
      userFilters["states.hospital"] = selectedState?.hospital["@id"];
      if (selectedState?.roles.indexOf("ROLE_SUPERVISOR") !== -1) {
        userFilters["states.supervisor"] = selectedState["@id"];
      }
    }

    dispatch(getUserResults(userFilters));
    dispatch(getHospitalsSearchResults({ name: query }));

    let patientFilters = {
      fullName: query,
    };
    if (loggedInUser?.roles.indexOf("ROLE_USER") !== -1) {
      if (selectedState?.roles.indexOf("ROLE_SUPERVISOR") !== -1) {
        patientFilters["supervisor.states"] = selectedState["@id"];
      }
      if (selectedState?.roles.indexOf("ROLE_RESIDENT") !== -1) {
        patientFilters["resident.states"] = selectedState["@id"];
      }
    }
    dispatch(getPatientSearchResults(patientFilters));
  };
  useEffect(() => {
    fetchData(params.query);
    return () => {
      dispatch(reset());
      dispatch(resetPlist());
      dispatch(resetHslice());
    };
  }, [params.query]);

  const renderUsers = () => {
    if (searchResultsUsers?.length === 0) {
      return null;
    }

    return (
      <div>
        <h3>Medici</h3>
        <ListingUsers
          searchQuery={params.query}
          users={searchResultsUsers}
          getListAction={getUserResults}
        />
      </div>
    );
  };

  const renderPatients = () => {
    if (patientSearchResults.length === 0) {
      return null;
    }

    return (
      <div>
        <h3>Pacienti</h3>
        <PatientsList
          searchQuery={params.query}
          patients={patientSearchResults}
          getListAction={getPatientSearchResults}
        />
      </div>
    );
  };

  const renderHospitals = () => {
    if (hospitalsSearchResult?.length === 0) {
      return null;
    }

    return (
      <div>
        <h3>Spitale</h3>
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <td>Nume</td>
              <td>Oras</td>
              <td>Persoana de contact</td>
              <td>Date de contact</td>
              <td>Actiuni</td>
            </tr>
          </thead>
          <tbody>
            {hospitalsSearchResult?.map((hospital, i) => {
              return <Hospital key={i} hospital={hospital} />;
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      <Header />
      <div>
        <NavigationBar
          search
          title={"Rezultate pentru cautarea: " + '"' + params.query + '"'}
          backAction={"/"}
        />
        <Loader loading={isLoading} />
        <div className="container-fluid">
          <div
            className="container search-results-page"
            style={{ marginBottom: "25px" }}
          >
            {renderUsers()}
            {renderPatients()}
            {renderHospitals()}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchResults;
