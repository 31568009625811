import React from "react";
import { Link } from "react-router-dom";

export const Logo = (props) => {
  return (
    <div className="logo-container">
      <Link to={props.login ? "/login" : "/"} />
    </div>
  );
};

export const FooterLinks = () => {
  return (
    <div className="copyright">
      <p>&copy; {new Date().getFullYear()} Evozon. All Rights Reserved</p>
      <p>
        <Link className="footer-links" to="">
          Terms of Service
        </Link>
        <Link className="footer-links" to="">
          Privacy Policy
        </Link>
      </p>
    </div>
  );
};
