import React from 'react';
import PropTypes from 'prop-types';
import Field from "./Field";

const SubGroup = (props) => (
  <div className={props.isActive ? '' : 'hidden'}>
    {props.subGroup.fields.map((field, fieldIri) => (
      <Field
        field={field}
        key={fieldIri}
        canEdit={props.canEdit}
      />
    ))}
  </div>
);


SubGroup.propTypes = {
  subGroup: PropTypes.object.isRequired,
  isActive: PropTypes.bool
};

SubGroup.defaultProps = {
  isActive: true
};

export default SubGroup;
