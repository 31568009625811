export const canViewUserActiveListPage = ({ loggedInUser, selectedState }) => {
  if (!loggedInUser) {
    return false;
  }

  if (loggedInUser?.roles?.indexOf("ROLE_SUPER_ADMIN") !== -1) {
    return true;
  }

  if (
    null !== selectedState &&
    selectedState?.roles?.indexOf("ROLE_HOSPITAL_ADMIN") !== -1
  ) {
    return true;
  }

  if (
    null !== selectedState &&
    selectedState?.roles?.indexOf("ROLE_SUPERVISOR") !== -1
  ) {
    return true;
  }

  return false;
};

export const canAddHospitalPage = ({ loggedInUser, selectedState }) => {
  if (!loggedInUser) {
    return false;
  }

  if (loggedInUser?.roles?.indexOf("ROLE_SUPER_ADMIN") !== -1) {
    return true;
  }

  return false;
};

export const canAddUserPage = ({ loggedInUser, selectedState }) => {
  if (!loggedInUser) {
    return false;
  }

  if (loggedInUser?.roles?.indexOf("ROLE_SUPER_ADMIN") !== -1) {
    return true;
  }

  if (
    null !== selectedState &&
    selectedState?.roles?.indexOf("ROLE_HOSPITAL_ADMIN") !== -1
  ) {
    return true;
  }
  return false;
};

export const canEditHospitalPage = ({ loggedInUser, selectedState }) => {
  if (!loggedInUser) {
    return false;
  }

  if (loggedInUser?.roles?.indexOf("ROLE_SUPER_ADMIN") !== -1) {
    return true;
  }

  return false;
};

export const canViewPatientList = ({ loggedInUser, selectedState }) => {
  if (!loggedInUser) {
    return false;
  }

  if (loggedInUser?.roles?.indexOf("ROLE_SUPER_ADMIN") !== -1) {
    return true;
  }

  if (selectedState?.hospital?.hasOwnProperty("@id")) {
    return true;
  }

  return false;
};

export const canViewSupportRequest = ({ loggedInUser, selectedState }) => {
  if (!loggedInUser) {
    return false;
  }

  if (loggedInUser?.roles?.indexOf("ROLE_SUPER_ADMIN") !== -1) {
    return true;
  }

  if (
    null !== selectedState &&
    selectedState?.roles?.indexOf("ROLE_SUPPORT_MEDIC") !== -1
  ) {
    return true;
  }

  if (selectedState?.hospital?.hasOwnProperty("@id")) {
    return true;
  }

  return false;
};

export const canViewAppointments = ({ loggedInUser, selectedState }) => {
  if (!loggedInUser) {
    return false;
  }

  if (
    null !== selectedState &&
    selectedState?.roles?.indexOf("ROLE_RESIDENT") !== -1
  ) {
    return true;
  }

  if (
    null !== selectedState &&
    selectedState?.roles?.indexOf("ROLE_SUPERVISOR") !== -1
  ) {
    return true;
  }

  if (
    null !== selectedState &&
    selectedState?.roles?.indexOf("ROLE_HOSPITAL_ADMIN") !== -1 &&
    selectedState?.hospital?.name?.toLowerCase() === "caravana"
  ) {
    return true;
  }

  return false;
};
