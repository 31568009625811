import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import Popover from "./popover";
import {
    logout,
    reset,
    exitImpersonation,
    setUser,
    clearUsersInput,
} from "../../slice/userSlice";
import {clearPatientsInput} from "../../slice/patientSlice";
import {clearHospitalsInput} from "../../slice/hospitalSlice";
import PermissionsGate from "../../security/permisionGate";
import {canViewUsersList} from "../../security/customPermission";

const TopUser = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {loggedInUser, switchUserEmail, selectedState} = useSelector((state) => state.users);
    const showAppointmentSection = selectedState?.hospital?.name?.toLowerCase() === 'caravana'

    const onLogout = () => {
        dispatch(logout());
        dispatch(reset());
        navigate("/login", {replace: true});
    };
    const onExitImpersonation = () => {
        Promise.resolve(dispatch(exitImpersonation()))
            .then(() => dispatch(setUser()))
            .then(() =>
                Promise.resolve(
                    dispatch(clearUsersInput()),
                    dispatch(clearPatientsInput()),
                    dispatch(clearHospitalsInput())
                )
            )
            .then(navigate("/"));
    };

    const rotateCaretDown = () => {
        const userIconsCaret = document.querySelectorAll(".user-name .icon__caret");
        userIconsCaret.forEach(element => {
            element.classList.add("icon__caret--active");
        })
    };

    const rotateCaretUp = () => {
        const userIconsCaret = document.querySelectorAll(".user-name .icon__caret");
        userIconsCaret.forEach(element => {
            element.classList.remove("icon__caret--active");
        })
    }

    let user = loggedInUser;
    if (!user) {
        return null;
    }

    return (
        <div className="top-user">
            <div className={"user-info"}>
                <i className="user-icon"/>
                <span className="user-name">
          {(user?.firstName || user?.lastName) &&
              user?.firstName + " " + user?.lastName}
                    <i className="icon__caret"/>
        </span>
            </div>
            <Popover
                onOpen={rotateCaretDown}
                onClose={rotateCaretUp}
                containerClass={"app-popover--from-right app-popover--narrow"}
                openedByElements={[".user-name",  ".top-bar-responsive .user-name"]}
            >
                <UsersListLink/>
                <Link className="menu-item" to="/hospitals/list">
                    Lista asociatii
                </Link>

                {showAppointmentSection && 
                    <Link className="menu-item" to="/appointments">
                        Programari
                    </Link>
                }
               
                <Link className="menu-item" to={"/users/" + user.id}>
                    Profilul meu
                </Link>
                {switchUserEmail ? (
                    <span className="menu-item" onClick={() => onExitImpersonation()}>
            Iesire impersonare
          </span>
                ) : (
                    <span className="menu-item" onClick={() => onLogout()}>
            Delogare
          </span>
                )}
            </Popover>
        </div>
    );
};

export default TopUser;

/*@canViewUsersList*/

const UsersListLink = () => {
    return (
        <PermissionsGate customPermissionFn={canViewUsersList}>
            <Link className="menu-item" to="/users-list/active">
                Lista medici
            </Link>
        </PermissionsGate>
    );
};
