import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import PermissionsGate from "../../security/permisionGate";
import { canAddPatient } from "../../security/customPermission";

const AddPatientButton = (props) => {
  return (
    <PermissionsGate customPermissionFn={canAddPatient}>
      <div className={props.containerClass}>
        <Link to="/add-patient" className={props.buttonClass}>
          Adauga pacient
        </Link>
      </div>
    </PermissionsGate>
  );
};

AddPatientButton.propTypes = {
  containerClass: PropTypes.string,
  buttonClass: PropTypes.string.isRequired,
};

AddPatientButton.defaultProps = {
  containerClass: "col-sm-3 col-md-3 col-xs-3 button-container ",
  //show
};

export default AddPatientButton;
