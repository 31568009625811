import React from "react";
import { Outlet } from "react-router-dom";
import HomeDashboard from "./HomeDashboard";

const HomeLayout = () => {
  return (
    <>
      <HomeDashboard>
        <Outlet />
      </HomeDashboard>
    </>
  );
};

export default HomeLayout;
