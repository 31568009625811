import React from "react";

const CnpButton = () => {
  return (
    <div className="col-sm-3 col-md-3 col-xs-3 button-container show">
      <button form="cnp-form" className="green-button">
        Verifica CNP
      </button>
    </div>
  );
};

export default CnpButton;
