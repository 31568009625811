import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader/loader";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Logo, FooterLinks } from "../../../components/Login/common";
import { Link } from "react-router-dom";
import {
  login,
  reset,
  requestAplicationAccess,
} from "../../../slice/userSlice";
import { toast } from "react-toastify";
import "../../../less/login.less";

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [registerData, setRegisterData] = useState({
    name: "",
    contact: "",
    info: "",
  });
  const { password, email } = formData;
  const { name, contact, info } = registerData;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isSucess, isError, isLoading, message, loggedIn } = useSelector(
    (state) => state.users
  );
  useEffect(() => {
    if (isError) {
      toast.error(message);
      dispatch(reset());
    }
    if (isSucess && loggedIn) {
      dispatch(reset());
      navigate("/");
    }
  }, [isError, isSucess, loggedIn, navigate, message]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onRegisterChange = (e) => {
    setRegisterData({ ...registerData, [e.target.name]: e.target.value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (!email || !password) {
      toast.error("Introduceti email si parola!");
    } else {
      const userData = {
        email,
        password,
      };
      dispatch(login(userData));
    }
  };

  const onSubmitRegister = (e) => {
    e.preventDefault();
    if (!name || !contact || !info) {
      toast.error("Va rugam completati toate campurile!");
    } else {
      dispatch(requestAplicationAccess(registerData))
        .unwrap()
        .then((response) => {
          if (response) {
            toast.success(
              "Cererea a fost trimisa catre conducere. Veti fi contactat pentru mai multe detalii. Multumim pentru inregistrare!"
            );
          }
          setRegisterData({
            name: "",
            contact: "",
            info: "",
          });
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div>
      <Loader loading={isLoading} />
      <div className="container login-container">
        <div className="row">
          <Logo login />
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <h4 className="text-center">
              <strong>Descriere Proiect</strong>
            </h4>
            <ul>
              <li className="text-muted">
                Institutul Regional de Gastroenterologie si Hepatologie "prof dr
                Octavian Fodor" din Cluj-Napoca, in calitate de partener
                principal, alaturi de partenerii sai Asociatia Caravana cu
                Medici - filiala Cluj si LadiSpital Rejkiavik anunta lansarea
                proiectului :
              </li>
              <br />
              <li className="text-muted">
                ‟HE-RO-I: State-of-the-art colaborativ ROmânia-Islanda în
                managementul HE patopatiilor cronice și a complicațiilor
                acestora, ciroza hepatică și cancerului hepatic, destinat
                grupurilor vulnerabile din Romania, inclusiv Romi”
              </li>
            </ul>
            <h4 className="text-center">
              <strong>Finantare</strong>
            </h4>
            <ul>
              <li className="text-muted">
                Finantat in cadrul mecanismului financiar SEE prin programul
                Provocări în Sănătatea Publică la nivel european implementat de
                catre Ministerul Sanatatii din Romania.
              </li>
            </ul>
            <br />
          </div>
        </div>
        <p className="text-center">Intra in aplicatie sau cere acces.</p>
        <div
          className="row"
          style={{
            display: "flex",
            alignItems: "stretch",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <div
            className="col-sm-6 col-md-6 login-form"
            style={{ marginTop: "20px" }}
          >
            <form
              onSubmit={onSubmit}
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div className="form-group">
                <input
                  type="text"
                  name="email"
                  placeholder="Email"
                  onChange={onChange}
                  value={email}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  onChange={onChange}
                  placeholder="Password"
                  value={password}
                  className="form-control"
                />
              </div>
              <button className="btn btn-default">Login</button>

              <p className="forgot-password">
                Ati uitat parola?
                <Link to="/reset">Click aici</Link>
              </p>
            </form>
          </div>
          <div
            className="col-sm-6 col-md-6  login-form"
            style={{ marginTop: "20px" }}
          >
            <form onSubmit={onSubmitRegister}>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  placeholder="Nume..."
                  onChange={onRegisterChange}
                  value={name}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="contact"
                  placeholder="Email sau telefon..."
                  onChange={onRegisterChange}
                  value={contact}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <textarea
                  name="info"
                  placeholder="Informatii despre spital..."
                  onChange={onRegisterChange}
                  value={info}
                  className="form-control"
                  style={{ maxWidth: "100%", minHeight: "100px" }}
                />
              </div>
              <button className="btn btn-success">Trimite cererea</button>
            </form>
          </div>
        </div>
        <div className="row">
          <FooterLinks />
        </div>
      </div>
    </div>
  );
};

export default Login;
