import React from "react";

const Loader = (props) => {
  return (
    <div
      className={
        props.loading
          ? "cssload-jumping-container"
          : "cssload-jumping-container hidden"
      }
    >
      <div className="cssload-jumping">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default Loader;
