import React from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";

import RequireAuth from "./components/requireauth/requireauth";
import Login from "./modules/login/components/Login";
import Home from "./modules/home/components/Home";
import SearchResults from "./modules/search/pages/SearchResults";
import ForgotPassword from "./modules/login/components/ForgotPassword";
import InternmentForm from "./pages/InternmentForm";
import MonitoringForm from "./pages/MonitoringForm";
import {
  canViewUserActiveListPage,
  canAddHospitalPage,
  canAddUserPage,
  canEditHospitalPage,
  canViewSupportRequest,
  canViewAppointments
} from "./security/customPagePermission";

// User
import UserLayout from "./modules/users/components/UserLayout";
import UsersActiveList from "./modules/users/components/UsersActiveList";
import UsersInactiveList from "./modules/users/components/UsersInactiveList";
import UserInfo from "./modules/users/components/UserInfo";
import UsersAccessHistory from "./modules/users/components/UsersAccessHistory";
import UserNotification from "./modules/users/components/UserNotification";
import AddUser from "./modules/users/components/AddUser";
import AddUserWithState from "./modules/users/components/AddUserWithState";
import EditUser from "./modules/users/components/EditUser";

// Hospital
import HospitalsLayout from "./modules/hospital/components/HospitalsLayout";
import HospitalsList from "./modules/hospital/components/HospitalsList";
import HospitalInfo from "./modules/hospital/components/HospitalInfo";
import AddHospital from "./modules/hospital/components/AddHospital";
import EditHospital from "./modules/hospital/components/EditHospital";
import AddUserStateNew from "./pages/AddUserStateNew";
import EditUserStateNew from "./pages/EditUserStateNew";

// Patient
import PatientRoutes from "./modules/patients/pages/PatientRoutes";
import PasswordReset from "./modules/login/components/PasswordReset";
import AllSuportRequests from "./modules/patients/components/AllSuportRequests";
import Appointments from "./modules/patients/components/Appointments";
const MainRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="reset" element={<ForgotPassword />} />
        <Route path="confirm/:token" element={<PasswordReset />} />

        <Route element={<RequireAuth />}>
          <Route index element={<Home />} />
          <Route
            element={<RequireAuth pagePermission={canViewSupportRequest} />}
          >
            <Route path="all-support-request" element={<AllSuportRequests />} />
          </Route>
          <Route path="*" element={<PatientRoutes />} />
          <Route
            element={<RequireAuth pagePermission={canViewAppointments} />}
          >
            <Route path="appointments" element={<Appointments />} />
          </Route>
          {/* TODO - <UserRoutes /> */}
          {/* TODO - <HospitalRoutes />*/}
          <Route
            element={<RequireAuth pagePermission={canViewUserActiveListPage} />}
          >
            <Route path="users-list" element={<UserLayout />}>
              <Route path="active" element={<UsersActiveList />} />
              <Route path="inactive" element={<UsersInactiveList />} />
            </Route>
          </Route>
          <Route path="users/:id" element={<UserInfo />} />
          <Route
            path="users/:id/access-history"
            element={<UsersAccessHistory />}
          />
          <Route
            path="users/:id/notifications"
            element={<UserNotification />}
          />
          <Route element={<RequireAuth pagePermission={canAddUserPage} />}>
            <Route path="add-user" element={<AddUserWithState />} />
          </Route>
          <Route path="users/:id/edit" element={<EditUser />} />
          <Route path="/users/:id/state" element={<AddUserStateNew />} />
          <Route path="/states/:id" element={<EditUserStateNew />} />
          <Route path="hospitals" element={<HospitalsLayout />}>
            <Route path="list" element={<HospitalsList />} />
            <Route path=":id" element={<HospitalInfo />} />
            <Route
              element={<RequireAuth pagePermission={canAddHospitalPage} />}
            >
              <Route path="add-hospital" element={<AddHospital />} />
            </Route>
            <Route
              element={<RequireAuth pagePermission={canEditHospitalPage} />}
            >
              <Route path="/hospitals/:id/edit" element={<EditHospital />} />
            </Route>
          </Route>
          <Route path="/search/:query" element={<SearchResults />} />
          <Route path="/internment-forms/:id" element={<InternmentForm />} />
          <Route path="/monitoring-forms/:id" element={<MonitoringForm />} />
        </Route>
      </Routes>
      <ToastContainer
        limit={1}
        position={toast.POSITION.TOP_CENTER}
        autoClose={2000}
      />
      <CookieConsent
        buttonText="Accept"
        cookieName="beliverCookie"
        style={{ backgroundColor: "#fff", color: "#333" }}
        buttonStyle={{ backgroundColor: "#471340", color: "#fff" }}
        // onAccept={handleAcceptCookie}
      >
        Folosim Cookies pe Website-ul nostru pentru a va oferi o experienta
        relevanta prin a va retine preferintele si vizitele repetate. Apasand
        "Accept", sunteti de acord să permiteti colectarea de informatii prin
        Cookies.
      </CookieConsent>
    </>
  );
};

export default MainRoutes;
