import React from "react";
import Header from "../../../components/common/header";
import Loader from "../../../components/Loader/loader";
import { useSelector } from "react-redux";

const HomeDashboard = (props) => {
  const { isLoading } = useSelector((state) => state.patients);
  return (
    <div>
      <Header />

      <Loader loading={isLoading} />
      {props.children}
    </div>
  );
};

export default HomeDashboard;
