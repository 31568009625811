import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Loader from "../Loader/loader";
import {
  fetchFormTypes,
  createFormFromFormTypeRequest,
  savePatientFormCases,
} from "../../slice/formSlice";
import { getOnePatient } from "../../slice/patientSlice";
import "../../less/new-patient-form-popup.less";
import withFormButtonAndPopup from "../Buttons/hocWithFormButtonAndPopup";

const NewInternmentForm = (props) => {
  const { viewedPatient, isLoading: loadingPatient } = useSelector(
    (state) => state.patients
  );
  const { formTypes, isLoading: loadingForm } = useSelector(
    (state) => state.patientForms
  );
  const { selectedState } = useSelector(
    (state) => state?.users
  );
  const selectedRegistry = localStorage.getItem("regId");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchFormTypes(selectedRegistry));
  }, []);

  const [selectedFormTypes, setSelectedFormTypes] = useState([]);

  const formTypeToInput = (formType, index) => {
    const fieldId = "form_type_field_" + index;
    return [
      <div key={fieldId} className="checkbox">
        <label htmlFor={fieldId}>
          <input
            name="form_type"
            id={fieldId}
            value={formType["@id"]}
            onChange={handleChange}
            type="checkbox"
          />
          {formType.name}
        </label>
      </div>,
    ];
  };

  const handleChange = (event) => {
    const checkbox = event.target;

    let formTypes = selectedFormTypes;
    if (checkbox.checked) {
      formTypes.push(checkbox.value);
    } else {
      formTypes.splice(selectedFormTypes.indexOf(checkbox.value), 1);
    }

    setSelectedFormTypes([...selectedFormTypes, formTypes]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectedFormTypes.length > 0) {
      const fnCalls = [];
      selectedFormTypes.forEach((formType) => {
        fnCalls.push(
          dispatch(
            createFormFromFormTypeRequest({
              formTypes: formType,
              patientCases: viewedPatient.patientCases[0]["@id"],
              id: viewedPatient["@id"],
            })
          )
        );
      });

      Promise.all(fnCalls)
        .then(() => props.closePopupCallback())
        .then(() => {
          let filters = {};

          if (
            null !== selectedState &&
            (selectedState?.roles.indexOf("ROLE_FAMILY_MEDIC") !== -1 ||
              selectedState?.roles.indexOf("ROLE_SUPPORT_MEDIC") !== -1)
          ) {
            filters["patientCase.registry.id"] = selectedRegistry;
          }
          dispatch(savePatientFormCases({ id: viewedPatient.id, ...filters }));
        });
    }
  };

  return (
    <div className="new-patient-form">
      <div className="popup">
        <Loader loading={loadingPatient || loadingForm} />
        <h2>Adauga formular Screening</h2>
        <form onSubmit={handleSubmit}>
          {formTypes?.map(formTypeToInput)}
          <div className="text-center">
            <span
              type="button"
              className="back-link"
              onClick={props.closePopupCallback}
            >
              Inapoi
            </span>
            <button type="submit" className="btn btn-success">
              Salveaza
            </button>
          </div>
        </form>
      </div>
      <div className="popup-bg" onClick={props.closePopupCallback || null} />
    </div>
  );
};

NewInternmentForm.propTypes = {
  closePopupCallback: PropTypes.func,
  viewedPatient: PropTypes.object.isRequired,
};

export default withFormButtonAndPopup(NewInternmentForm);
