import React from "react";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";

const Table = (props) => {
  const { columns, data, onSort, expandedData, ...rest } = props;
  const hasExpandColumn = columns.some((column) => column.key === "expand");

  return (
    <div className="container">
      <div className={"row"}>
        <div className={"col-xs-12"}>
          <table className={`table table-striped table-hover ${props.clsName}`}>
            <TableHeader columns={columns} onSort={onSort}></TableHeader>
            <TableBody
              columns={columns}
              data={data}
              hasExpandColumn={hasExpandColumn}
              expandedData={expandedData}
              {...rest}
            ></TableBody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Table;
