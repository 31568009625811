import { PERMISSIONS } from "./permission-map";
import { useGetRole } from "./useGetRole";
import { useSelector } from "react-redux";

const hasPermission = ({ permissions, scopes }) => {
  const scopesMap = {};
  scopes.forEach((scope) => {
    scopesMap[scope] = true;
  });

  return permissions?.some((permission) => scopesMap[permission]);
};

export default function PermissionsGate({
  children,
  RenderError = () => <></>,
  errorProps = null,
  scopes = [],
  customPermissionFn = null,
  componentProps,
}) {
  const { role } = useGetRole();
  const { loggedInUser, selectedState, viewedUser } = useSelector(
    (state) => state.users
  );

  const permissions = PERMISSIONS[role];
  let permissionGranted = false;
  if (typeof customPermissionFn === "function") {
    permissionGranted = customPermissionFn({
      loggedInUser,
      selectedState,
      viewedUser,
      componentProps,
    });
  } else {
    permissionGranted = hasPermission({ permissions, scopes });
  }

  if (!permissionGranted && !errorProps) return <RenderError />;

  return <>{children}</>;
}
