import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import PropTypes from "prop-types";
import "../../../less/forms.less";

const SelectInput = (props) => {
  const animatedComponents = makeAnimated();
  const handleClick = (e) => {
    let element = e.target,
      errorContainer = element.closest(".has-error");

    if (null === errorContainer) {
      return;
    }

    errorContainer.classList.remove("has-error");
  };

  let value = props?.value;
  if (value && !value?.hasOwnProperty("value")) {
    if (props.multi) {
      value = props?.options?.filter((option) =>
        props.value?.includes(option.value)
      );
    } else {
      value = props?.options?.find((option) => option.value === props?.value);
    }
  }
  const isNotDisabled = props.canEdit === undefined ? true : props.canEdit

  return (
    <div className="form-group">
      <label className={props.labelClass}>{props.title}</label>
      <Select
        className={props.selectContainerClass}
        name={props.name}
        title={props.title}
        options={props.options}
        onChange={props.onChange}
        onFocus={handleClick}
        value={value}
        clearable={true}
        placeholder=""
        components={animatedComponents}
        closeOnSelect={props.closeOnSelect}
        isMulti={props.multi}
        isDisabled={!isNotDisabled}
      />
    </div>
  );
};

/*SelectInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  multi: PropTypes.bool.isRequired,
  labelClass: PropTypes.string,
  selectContainerClass: PropTypes.string,
  closeOnSelect: PropTypes.bool,
};*/

SelectInput.defaultProps = {
  labelClass: "form-label",
  multi: false,
  selectContainerClass: "form-input",
  closeOnSelect: true,
};

export default SelectInput;
