import { useState } from "react";

import ReactDatePicker from "react-datepicker";
import "../../../less/datepicker.less";
import "../../../less/appointments.less";
import "react-datepicker/dist/react-datepicker.css";

const AppointmentFilters = (props) => {
  const [dates, setDates] = useState();
  const [showFilters, setShowFilters] = useState(false);
  const isDisabled = !dates?.end?.value && !dates?.start?.value

  const handleReset = () => {
    const isFiltered = Object.keys(props.filters ?? {})?.length > 0
    if(isFiltered) {
      props.handleFilters({});
    }
    setDates({})
  };

  const handleChange = (e, key) => {
    const date = e?.toLocaleDateString('RO-ro');
    setDates((prev) => ({
      ...prev,
      [key]: {
        name: key === 'end' ? 'to' : 'from',
        value: date,
      },
    }));
  }

  return (
    <>
      <div className="col-xs-12 col-sm-8 col-md-6 col-lg-5 filters-container">
        <button
          className="btn btn-filter"
          onClick={() => setShowFilters((s) => !s)}
        >
          Filtreaza
        </button>
        <div className="form-group col-sm-12 col-md-12" hidden={!showFilters}>
          <div>
            <label>Din Data</label>
            <ReactDatePicker
              id="from"
              name="from"
              selected={
                dates?.start && typeof dates.start?.value === "string"
                  ? new Date(dates?.start.value.split(".").reverse().join("-"))
                  : null
              }
              dateFormat="dd-MM-yyyy"
              onChange={(e) => handleChange(e, "start")}
            />
          </div>
          <div>
            <label className="form-label">Pana la data</label>
            <ReactDatePicker
              id="to"
              name="to"
              selected={
                dates?.end && typeof dates.end?.value === 'string'
                  ? new Date(dates?.end.value?.split(".").reverse().join("-"))
                  : null
              }
              dateFormat="dd-MM-yyyy"
              onChange={(e) => handleChange(e, "end")}
            />
          </div>
          <div className="mt-10 actions-container">
            <button
              className="green-button mt-10 mr-10 col-xs-12 col-sm-5"
              disabled={isDisabled}
              onClick={() => props.handleFilters(dates)}
            >
              Aplica filtre
            </button>
            <button
              className="green-button mt-10 col-xs-12 col-sm-5"
              disabled={isDisabled}
              onClick={(handleReset)}
            >
              Reseteaza
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppointmentFilters;
