import React, { useState } from "react";
import "../../../less/upload-image.less";
import { request } from "../../../constants/axios-warpers";
import { toast } from "react-toastify";

const UploadImage = ({ closeModal, internment: internmentId }) => {
  const [file, setFile] = useState();
  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files?.[0]);
    }
  };

  const handleUploadClick = async () => {
    if (!file) {
      toast.info("Selectati un fisier!");
      return;
    }
    const formData = new FormData();
    formData.append("file_upload[file]", file);
    formData.append("file_upload[abstractForm]", internmentId);
    try {
      const response = await request.post("/form/upload", formData);
      if (response) {
        toast.success("Imaginea a fost incarcata cu succes");
        closeModal?.();
      }
    } catch (error) {
      const listError = error?.response?.data?.errors || error?.message;
      toast.error(listError.toString());
    }
  };

  return (
    <>
      <div className="popup responsive">
        <div className="upload-image">
          <div className="form-controls">
            <label htmlFor="image-upload" className="image-upload">
              Selecteaza fisierul:
            </label>
            <input id="image-upload" type="file" onChange={handleFileChange} />
          </div>
          <span className="text-muted" style={{ padding: "5px" }}>
            <small> *supported files formats: mp4, jpg and avi.</small>
          </span>
          <div className="container-actions text-center">
            <span type="button" className="back-link" onClick={closeModal}>
              Inapoi
            </span>
            <button
              type="button"
              className="green-button ml-5"
              onClick={handleUploadClick}
            >
              Incarca
            </button>
          </div>
        </div>
      </div>
      <div className="popup-bg" onClick={closeModal || null} />
    </>
  );
};

export default UploadImage;
