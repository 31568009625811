import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";

import { setFormValues } from "../../../../slice/formSlice";
import SelectInput from "../../../../components/forms/general/select-input";
import "../../../../less/forms.less";
import "../../../../less/datepicker.less";

const Field = (props) => {
  const fieldValue = useSelector((state) =>
    state.patientForms.formValues[props.field.id]
      ? state.patientForms.formValues[props.field.id].value
      : ""
  );
  const dispatch = useDispatch();

  const handleChange = (event) => {
    dispatch(setFormValues(props.field, event.target.value));
  };

  const handleDateChange = (value) => {
    dispatch(
      setFormValues(props.field, value ? value.toLocaleDateString("ro-RO") : "")
    );
  };

  const handleSelectChange = (changes) => {
    dispatch(
      setFormValues(
        props.field,
        props.field.allowMultipleValues
          ? changes.map((change) => change.value)
          : changes.value
      )
    );
  };

  const renderSelect = () => {
    const htmlType = props.field.fieldType.htmlType;

    if (["checkbox", "radio", "select"].indexOf(htmlType) === -1) {
      return null;
    }

    return (
      <SelectInput
        onChange={handleSelectChange}
        labelClass="col-sm-2 control-label"
        selectContainerClass="col-sm-10 option-wrap"
        value={fieldValue}
        name={props.field.id.toString()}
        title={props.field.label}
        options={Object.entries(props.field.options).map((option) => ({
          label: option[1],
          value: option[0],
        }))}
        closeOnSelect={!props.field.allowMultipleValues}
        multi={props.field.allowMultipleValues}
        canEdit={props.canEdit}
      />
    );
  };

  const renderDate = () => {
    if (props.field.fieldType.htmlType !== "date") {
      return null;
    }

    return (
      <div className="form-group">
        <label className="col-sm-2 control-label" htmlFor={props.field["@id"]}>
          {props.field.label}
        </label>
        <div className="col-sm-10">
          <DatePicker
            name={props.field.id.toString()}
            onChange={handleDateChange}
            selected={
              fieldValue && typeof fieldValue === "string"
                ? new Date(fieldValue.split(".").reverse().join(".")) //parseISO(fieldValue, "dd-mm-yyyy")
                : null
            }
            dateFormat="dd-MM-yyyy"
            disabled={!props.canEdit}
          />
        </div>
      </div>
    );
  };

  const renderText = () => {
    if (props.field.fieldType.htmlType !== "text") {
      return null;
    }

    return (
      <div className="form-group">
        <label className="col-sm-2 control-label" htmlFor={props.field["@id"]}>
          {props.field.label}
        </label>
        <div className="col-sm-10">
          <input
            className="form-control"
            id={props.field["@id"]}
            type="text"
            name={props.field.id.toString()}
            onChange={handleChange}
            value={fieldValue}
            disabled={!props.canEdit}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderText()}
      {renderDate()}
      {renderSelect()}
    </div>
  );
};

Field.propTypes = {
  field: PropTypes.object.isRequired,
};

export default Field;
