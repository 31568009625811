import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import NavigationBar from "../components/common/navigation-bar";
import {useSelector, useDispatch} from "react-redux";
import format from "date-fns/format";
import {
  mapResponse,
  mapResponseCustom,
  mapResponseObject,
  selectedHospitalUsers,
  mapResponseObjectCustom,
} from "../helpers/response-map-functions";
import {
  fetchSupervisorsList,
  fetchingSpecializationsList,
  fetchingTitlesList,
  reset,
  getUserState,
  updateUserState,
} from "../slice/userSlice";
import {getHospitals, resetHslice} from "../slice/hospitalSlice";
import {Field, Form} from "react-final-form";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Header from "../components/common/header";
import Loader from "../components/Loader/loader";
import "../less/datepicker.less";
import "react-datepicker/dist/react-datepicker.css";
import {idToIriTransformer} from "../helpers/iri-transformer";
import "../less/forms.less";
import {toast} from "react-toastify";
import { rolesListCaravana, rolesListTelemedicina } from "../modules/users/data/helpers";

const EditUserStateNew = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {viewedState, selectedState, loggedInUser, isLoading} = useSelector(
      (state) => state.users
  );

  const {data: supervisorsList, isLoading: loadingSupervisorsList} =
      useSelector((state) => state.users.supervisorsList);
  const {data: specializationsList, isLoading: loadingSpecializationsList} =
      useSelector((state) => state.users.specializationsList);
  const {data: titleList, isLoading: loadingTitleList} = useSelector(
      (state) => state.users.titleList
  );
  const {hospitals, isLoading: loadingHospitals} = useSelector(
      (state) => state.hospitals
  );

  const [hospitalsList, setHospitalsList] = useState([]);
  const [supervisorsState, setSupervisorsState] = useState([]);
  const [hospitalType, setHospitalType] = useState()

  useEffect(() => {
    dispatch(getUserState(params.id));
    dispatch(fetchingSpecializationsList());
    dispatch(fetchingTitlesList());
    dispatch(fetchSupervisorsList());
    dispatch(getHospitals());
    return () => {
      dispatch(reset());
      dispatch(resetHslice());
    };
  }, []);

  useEffect(() => {
    if (viewedState && viewedState?.hospital) {
      setHospitalType(viewedState?.hospital?.name)
      setSupervisorsState(
          selectedHospitalUsers(viewedState?.hospital["@id"], supervisorsList)
      );
    }
    loggedInUser?.roles.indexOf("ROLE_SUPER_ADMIN") !== -1
        ? setHospitalsList(hospitals)
        : setHospitalsList([selectedState?.hospital]);
  }, [viewedState, supervisorsList, hospitals]);

  let rolesList = []
  if (hospitalType?.toLocaleLowerCase() === "telemedicina") {
    rolesList = rolesListTelemedicina;
  } else if (hospitalType?.toLocaleLowerCase() === "caravana") {
    rolesList = rolesListCaravana;
  } else {
    rolesList = []
  }

  const option1 = mapResponseCustom(supervisorsState);
  const option2 = specializationsList;

  const option4 = mapResponse(hospitalsList);
  const option5 = mapResponse(titleList);

  let preFillData = {
    startDate: viewedState?.startDate
        ? Date.parse(viewedState?.startDate)
        : null,
    endDate: viewedState?.endDate
        ? Date.parse(viewedState?.endDate)
        : undefined,
    hospital: viewedState?.hospital
        ? mapResponseObject(viewedState?.hospital)
        : null,
    roles: viewedState?.roles
        ? rolesList.find((item) => item.value === viewedState?.roles[0])
        : null,
    specializations:
        viewedState?.specializations?.length > 0
            ? mapResponseObject(viewedState?.specializations[0])
            : null,
    supervisor: viewedState?.supervisor
        ? mapResponseObjectCustom(viewedState?.supervisor)
        : null,
    title: viewedState?.title ? mapResponseObject(viewedState?.title) : null,
  };

  const Error = ({name}) => (
      <Field
          name={name}
          subscription={{touched: true, error: true}}
          render={({meta: {touched, error}}) =>
              touched && error ? <span className="text-danger">{error}</span> : null
          }
      />
  );

  const onSubmit = async (values, form) => {
    let data = {
      startDate: values?.startDate
          ? format(values?.startDate, "dd-MM-yyyy")
          : format(new Date(), "dd-MM-yyyy"),
      endDate: values?.endDate
          ? format(values?.endDate, "dd-MM-yyyy")
          : undefined,
      hospital: values?.hospital?.value,
      roles: [values?.roles?.value],
      specializations: values.specializations?.value
          ? [values.specializations?.value]
          : undefined,
      supervisor: values?.supervisor?.value,
      title: values?.title?.value,
      user: idToIriTransformer("users", viewedState?.user?.id),
    };
    try {
      const response = await dispatch(updateUserState({form: data, id: viewedState.id})).unwrap();
      if (response) {
        toast.success('Accesul a fost modificat cu succes!')
        navigate(`/users/${viewedState?.user?.id}/access-history`)
      }
    } catch (err) {
      toast.error(err)
    }

  };

  const validate = (values) => {
    const errors = {};
    if (!values.roles) {
      errors.roles = "Required";
    }
    // if (!values.title) {
    //   errors.title = "Required";
    // }
    if (!values.hospital) {
      errors.hospital = "Required";
    }

    return errors;
  };

  const ReactSelectAdapter = ({input, ...rest}) => (
      <Select {...input} {...rest} searchable/>
  );

  const DatePickerAdapter = ({input, ...rest}) => (
      <DatePicker {...input} {...rest} selected={input.value}/>
  );

  const handleHospitalChange = (selectedValue, setFormValue) => {
    setFormValue("supervisor", "");
    setFormValue("roles", " ");
    const superV = selectedHospitalUsers(selectedValue.value, supervisorsList);
    setHospitalType(selectedValue.label)
    setSupervisorsState(superV);
  };

  return (
      <div>
        <Header/>
        <NavigationBar
            title={"Editeaza accesul"}
            action="edit-user-state"
            backText="Lista"
            backAction={"/users-list/active"}
        />
        <Loader
            loading={
                isLoading ||
                loadingHospitals ||
                loadingSpecializationsList ||
                loadingSupervisorsList ||
                loadingTitleList
            }
        />
        <div className="container-fluid">
          <div className="container forms-container">
            <Form
                mutators={{
                  setValue: ([field, value], state, {changeValue}) => {
                    changeValue(state, field, () => value);
                  },
                }}
                onSubmit={onSubmit}
                keepDirtyOnReinitialize
                initialValues={preFillData}
                validate={validate}
                render={({handleSubmit, form, values}) => (
                    <form
                        id="user-state-form"
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                      {/*Forma User State*/}
                      <div className="col-sm-12 col-md-12 inner-form no-avatar-forms">
                        <div className="fields-container">
                          <div className="row">
                            <div className="col-sm-12 col-md-6">
                              <div className="form-group">
                                <label className="form-label">Start Date</label>
                                <Field
                                    name="startDate"
                                    component={DatePickerAdapter}
                                    required
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                              <div className="form-group">
                                <label className="form-label">Data Final</label>
                                <Field name="endDate" component={DatePickerAdapter}/>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-6">
                              <div className="form-group">
                                <label className="form-label">Asociatie &#42;</label>
                                <Field
                                    title="Asociatie &#42;"
                                    name="hospital"
                                    options={option4}
                                    render={(props) => {
                                      return (
                                          <ReactSelectAdapter
                                              {...props}
                                              onChange={(value) => {
                                                handleHospitalChange(
                                                    value,
                                                    form.mutators.setValue
                                                );
                                                props.input.onChange(value);
                                              }}
                                          />
                                      );
                                    }}
                                />
                                <Error name="hospital"/>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                              <div className="form-group">
                                <label className="form-label">Rol &#42;</label>
                                <Field
                                    title="Rol &#42;"
                                    name="roles"
                                    component={ReactSelectAdapter}
                                    options={rolesList}
                                />
                                <Error name="roles"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bottom-actions-container centered-actions">
                        <Link to={"/users-list/active"} className="back-link">
                          Inapoi
                        </Link>
                        <input
                            type="submit"
                            className="green-button"
                            value="Salveaza"
                        />
                      </div>
                    </form>
                )}
            />
          </div>
        </div>
      </div>
  );
};

export default EditUserStateNew;
