import React from "react";
import { Link } from "react-router-dom";
import ListActionsDropdown from "./list-actions-dropdown";
import { SCOPES } from "../../security/permission-map";
import PermissionsGate from "../../security/permisionGate";
import {
  canAddUserAcces,
  canImpersonate,
  canViewRequestAccesLink,
} from "../../security/customPermission";
import { requstHospitalAccess } from "../../slice/hospitalSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export const UserAccessInfoHospitals = (props) => {
  let userStateInfoHospitals = props.userStateInfoHospitals;

  return (
    <span>
      {userStateInfoHospitals.hospital
        ? userStateInfoHospitals.hospital.name
        : ""}
    </span>
  );
};

export const UserAccessInfoHospitalsCity = (props) => {
  let userStateInfoHospitalsCity = props.userStateInfoHospitalsCity;

  return (
    <span>
      {userStateInfoHospitalsCity.hospital
        ? userStateInfoHospitalsCity.hospital.city
        : ""}
    </span>
  );
};

export const UserAccessInfoHospitalsTitle = (props) => {
  let userStateInfoHospitalsTitle = props.userStateInfoHospitalsTitle;

  return <span>{userStateInfoHospitalsTitle?.title?.name}</span>;
};

export const HospitalsAdmins = (props) => {
  let hospitalAdmin = props.hospitalAdmin;

  return (
    <span>
      {hospitalAdmin.firstName} {hospitalAdmin.lastName}
    </span>
  );
};

export const HospitalsAdminsContactDetails = (props) => {
  let hospitalAdmin = props.hospitalAdmin;

  return (
    <span>
      {"tel:" + hospitalAdmin.phone + ", email:" + hospitalAdmin.email}
    </span>
  );
};

export const ImpersonateLink = (props) => {
  return (
    <PermissionsGate customPermissionFn={canImpersonate} componentProps={props}>
      <a className="menu-item" onClick={() => props.impersonate(props.user)}>
        Impersonare
      </a>
    </PermissionsGate>
  );
};

export const RequestAccessLink = (props) => {
  const dispatch = useDispatch();
  const { id } = props.hospital;
  const handleClick = () => {
    dispatch(requstHospitalAccess(id))
      .unwrap()
      .then(() => {
        toast.success("Cererea de acces a fost trimisa!");
      })
      .catch((error) => {
        toast.error("Eroare");
      });
  };
  return (
    <PermissionsGate customPermissionFn={canViewRequestAccesLink}>
      <a className="menu-item" onClick={handleClick}>
        Solicita acces
      </a>
    </PermissionsGate>
  );
};

export const User = (props) => {
  let user = props.user;

  return (
    <tr>
      <td>
        <div className="cell name-container"></div>
        <Link className="title-link" to={"/users/" + user.id}>
          {user.firstName + " " + user.lastName}
        </Link>
      </td>
      <td>
        <div className="cell states-container">
          {props.user.states.map((state, i) => {
            return (
              <UserAccessInfoHospitals
                key={"state_" + i + "_id_" + user.id}
                userStateInfoHospitals={state}
              />
            );
          })}
        </div>
      </td>
      <td>
        <div className="cell states-container">
          {props.user.states.map((state, i) => {
            return (
              <UserAccessInfoHospitalsCity
                key={"state_" + i + "_id_" + user.id}
                userStateInfoHospitalsCity={state}
              />
            );
          })}
        </div>
      </td>
      <td>
        <div className="cell states-container">
          {props.user.states.map((state, i) => {
            return (
              <UserAccessInfoHospitalsTitle
                key={"state_" + i + "_id_" + user.id}
                userStateInfoHospitalsTitle={state}
              />
            );
          })}
        </div>
      </td>
      <td>
        <div className="cell">
          <ListActionsDropdown>
            <Link className="menu-item" to={"/users/" + user.id}>
              Informatii medic
            </Link>
            <AddAccessLink user={user} />
            <EditUserLink user={user} />
            <ImpersonateLink impersonate={props.impersonate} user={user} />
          </ListActionsDropdown>
        </div>
      </td>
    </tr>
  );
};

export const EditHospitalLink = (props) => {
  return (
    <PermissionsGate scopes={[SCOPES.canEdit]}>
      <Link
        className="menu-item"
        to={"/hospitals/" + props.hospital.id + "/edit"}
      >
        Editeaza Asociatie
      </Link>
    </PermissionsGate>
  );
};

export const EditUserLink = (props) => {
  return (
    <PermissionsGate customPermissionFn={canAddUserAcces}>
      <Link className="menu-item" to={"/users/" + props.user.id + "/edit"}>
        Editeaza medicul
      </Link>
    </PermissionsGate>
  );
};

export const AddAccessLink = (props) => {
  return (
    <PermissionsGate customPermissionFn={canAddUserAcces}>
      <Link className="menu-item" to={"/users/" + props.user.id + "/state"}>
        Adauga acces
      </Link>
    </PermissionsGate>
  );
};

export const Hospital = (props) => {
  let hospital = props.hospital,
    eachAdmin = props.hospital.administrators;

  return (
    <tr>
      <td>
        <div className="cell name-container"></div>
        <Link className="title-link" to={"/hospitals/" + hospital.id}>
          {hospital?.name}
        </Link>
      </td>
      <td className="city">
        <div className="cell">
          <span className="city-span">{hospital.city}</span>
        </div>
      </td>
      <td>
        <div className="cell states-container">
          {Object.keys(eachAdmin).map((i) => {
            return <HospitalsAdmins key={i} hospitalAdmin={eachAdmin[i]} />;
          })}
        </div>
      </td>
      <td>
        <div className="cell states-container">
          {Object.keys(eachAdmin).map((i) => {
            return (
              <HospitalsAdminsContactDetails
                key={i}
                hospitalAdmin={eachAdmin[i]}
              />
            );
          })}
        </div>
      </td>
      <td>
        <div className="cell">
          <ListActionsDropdown>
            <Link className="menu-item" to={"/hospitals/" + hospital.id}>
              Informatii spital
            </Link>
            <EditHospitalLink hospital={hospital} />
          </ListActionsDropdown>
        </div>
      </td>
    </tr>
  );
};

export const ListItem = (props) => {
  return (
    <div className="item-container clearfix">
      <div className="avatar-container">
        <div className="avatar-default avatar-hospital" />
      </div>
      <div className="info-container">
        <span className="name">{props.title}</span>
        <p>{props.description}</p>
        <p className={props.contactPerson ? "" : "hidden"}>
          Persoana de contact:{" "}
          <span className="contact">{props.contactPerson}</span>
        </p>
      </div>
      <LinkItem
        info={"/patients/" + props.id}
        edit={"/patients/" + props.id + "/edit"}
      />
    </div>
  );
};

const LinkItem = (props) => {
  return (
    <div className="actions-container">
      <a className="actions-button" />
      <div className="links-container hidden">
        <Link to={props.info}>Informatii</Link>
        <Link to={props.edit}>Editeaza</Link>
      </div>
    </div>
  );
};
