import React from "react";
import { Logo } from "../Login/common";
import TopUser from "./user-dropdown";
import HospitalSwitcher from "./hospital-switcher";
import HeaderNotifications from "./header-notifications";

const Header = () => {
  return (
     <>
         <div className="header">
             <Logo />
             <div className={"right-header-wrapper"}>
                 <TopUser />
                 <HeaderNotifications/>
             </div>
         </div>
         <div className={"header-responsive"}>
             <div className="top-bar">
                 <Logo />
                 <HospitalSwitcher />
                 <TopUser />
                 <HeaderNotifications/>
             </div>
             <div className="top-bar-responsive">
                 <div className="first-section">
                     <Logo/>
                     <HeaderNotifications/>
                 </div>
                 <TopUser/>
                 <HospitalSwitcher />
             </div>
         </div>
     </>
  );
};

export default Header;
