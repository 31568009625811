import { Link } from "react-router-dom";
import {
  EditHospitalLink,
  RequestAccessLink,
} from "../../../components/common/list-items";
import ListActionsDropdown from "../../../components/common/list-actions-dropdown";

export const hospitalColumns = [
  {
    title: "Nume",
    key: "name",
    renderFn: (value, item) => (
      <Link className="title-link" to={"/hospitals/" + item.id}>
        {item.name}
      </Link>
    ),
  },
  {
    title: "Oras",
    key: "city",
    renderFn: (value, i) => (
      <div key={i} className="city-span">
        {value}
      </div>
    ),
  },
  {
    title: "Persoana de contact",
    key: "administrators",
    renderFn: (value, i) => {
      const administrators = Array.isArray(value)
        ? value
        : Object.values(value);
      return administrators?.map((item, index) => (
        <div key={index}>{`${item?.firstName} ${item?.lastName}`}</div>
      ));
    },
  },
  {
    title: "Date de contact",
    key: "administrators",
    renderFn: (value) => {
      const administrators = Array.isArray(value)
        ? value
        : Object.values(value);
      return administrators?.map((item, index) => (
        <div key={index}>{`tel:${item?.phone}, email:${item?.email}`}</div>
      ));
    },
  },
  {
    title: "Actiuni",
    key: "actions",
    renderFn: (value, hospital) => (
      <ListActionsDropdown>
        <Link className="menu-item" to={"/hospitals/" + hospital.id}>
          Informatii Asociatie
        </Link>
        <EditHospitalLink hospital={hospital} />
        <RequestAccessLink hospital={hospital} />
      </ListActionsDropdown>
    ),
  },
];
