export const canAddUserAcces = ({loggedInUser, selectedState}) => {
  if (!loggedInUser) {
    return false;
  }

  if (loggedInUser?.roles.indexOf("ROLE_SUPER_ADMIN") !== -1) {
    return true;
  }

  if (
      null !== selectedState &&
      selectedState?.roles.indexOf("ROLE_HOSPITAL_ADMIN") !== -1
  ) {
    return true;
  }

  return false;
};

export const canAddUser = ({loggedInUser, selectedState}) => {
  if (loggedInUser?.roles.indexOf("ROLE_SUPER_ADMIN") !== -1) {
    return true;
  }

  if (
      null !== selectedState &&
      selectedState?.roles.indexOf("ROLE_HOSPITAL_ADMIN") !== -1
  ) {
    return true;
  }

  return false;
};

export const canAddMonitoring = ({ loggedInUser, selectedState }) => {
  if (loggedInUser?.roles.indexOf("ROLE_SUPER_ADMIN") !== -1) {
    return true;
  }

  if (
    null !== selectedState &&
    selectedState?.roles.indexOf("ROLE_HOSPITAL_ADMIN") !== -1
  ) {
    return true;
  }

  if (
    null !== selectedState &&
    selectedState?.roles.indexOf("ROLE_SUPPORT_MEDIC") !== -1
  ) {
    return true;
  }

  if (
    null !== selectedState &&
    (selectedState?.roles.indexOf("ROLE_SUPERVISOR") !== -1)
  ) {
    return true;
  }

  return false;
};

export const canAttachImages = ({ loggedInUser, selectedState }) => {
  if (!loggedInUser) {
    return false;
  }

  if (
    null !== selectedState &&
    selectedState?.roles.indexOf("ROLE_FAMILY_MEDIC") !== -1
  ) {
    return true;
  }

  return false;
};

export const canDownloadImages = ({ loggedInUser, selectedState }) => {
  if (!loggedInUser) {
    return false;
  }

  if (
    null !== selectedState &&
    selectedState?.roles.indexOf("ROLE_FAMILY_MEDIC") !== -1
  ) {
    return true;
  }
  if (
    null !== selectedState &&
    selectedState?.roles.indexOf("ROLE_SUPPORT_MEDIC") !== -1
  ) {
    return true;
  }

  return false;
};
export const canImpersonate = ({
                                 loggedInUser,
                                 selectedState,
                                 componentProps = {},
                               }) => {
  if (!loggedInUser) {
    return false;
  }

  if (loggedInUser.id === componentProps.user?.id) {
    return false;
  }

  if (loggedInUser?.roles.indexOf("ROLE_SUPER_ADMIN") !== -1) {
    return true;
  }

  if (
      null !== selectedState &&
      selectedState?.roles.indexOf("ROLE_HOSPITAL_ADMIN") !== -1
  ) {
    return true;
  }

  return false;
};

export const canViewShowMyPatients = ({loggedInUser, selectedState}) => {
  if (!loggedInUser) {
    return false;
  }

  if (
      null !== selectedState &&
      selectedState?.roles.indexOf("ROLE_HOSPITAL_ADMIN") !== -1
  ) {
    return true;
  }

  return false;
};

export const canViewUsersList = ({loggedInUser, selectedState}) => {
  if (!loggedInUser) {
    return false;
  }

  if (loggedInUser?.roles?.indexOf("ROLE_SUPER_ADMIN") !== -1) {
    return true;
  }

  if (
      null !== selectedState &&
      selectedState?.roles?.indexOf("ROLE_HOSPITAL_ADMIN") !== -1
  ) {
    return true;
  }

  if (
      null !== selectedState &&
      selectedState?.roles?.indexOf("ROLE_SUPERVISOR") !== -1
  ) {
    return true;
  }

  return false;
};

export const canEditUser = ({loggedInUser, selectedState, viewedUser}) => {
  if (!loggedInUser) {
    return false;
  }

  if (viewedUser?.id === loggedInUser.id) {
    return true;
  }

  if (loggedInUser?.roles?.indexOf("ROLE_SUPER_ADMIN") !== -1) {
    return true;
  }

  if (
      null !== selectedState &&
      selectedState?.roles?.indexOf("ROLE_HOSPITAL_ADMIN") !== -1
  ) {
    return true;
  }

  return false;
};

export const canAddPatient = ({loggedInUser, selectedState}) => {
  if (!loggedInUser) {
    return false;
  }

  if (loggedInUser?.roles?.indexOf("ROLE_SUPER_ADMIN") !== -1) {
    return false;
  }

  if (
    null !== selectedState &&
    selectedState?.roles.indexOf("ROLE_SUPPORT_MEDIC") !== -1
  ) {
    return false;
  }

  if (selectedState?.hospital.hasOwnProperty("@id")) {
    return true;
  }

  return false;
};

export const canExportPatient = ({loggedInUser, selectedState}) => {
  if (!loggedInUser) {
    return false;
  }

  if (
    null !== selectedState &&
    selectedState?.roles?.indexOf("ROLE_HOSPITAL_ADMIN") !== -1
  ) {
    return true;
  }

  return false;
};

export const canViewPatientList = ({loggedInUser, selectedState}) => {
  if (!loggedInUser) {
    return false;
  }

  if (loggedInUser?.roles?.indexOf("ROLE_SUPER_ADMIN") !== -1) {
    return true;
  }

  if (selectedState?.hospital.hasOwnProperty("@id")) {
    return true;
  }

  return false;
};

export const canViewRequestAccesLink = ({loggedInUser, selectedState}) => {
  if (!loggedInUser) {
    return false;
  }

  if (!selectedState?.hospital) {
    return true;
  }

  return false;
};

export const canAddInternment = ({ loggedInUser, selectedState }) => {
  if (!loggedInUser) {
    return false;
  }
  
  if (loggedInUser?.roles.indexOf("ROLE_SUPER_ADMIN") !== -1) {
    return true;
  }

  if (
    null !== selectedState &&
    (selectedState?.roles.indexOf("ROLE_HOSPITAL_ADMIN") !== -1 ||
      selectedState?.roles.indexOf("ROLE_FAMILY_MEDIC") !== -1 ||
      selectedState?.roles.indexOf("ROLE_RESIDENT") !== -1 ||
      selectedState?.roles.indexOf("ROLE_SUPERVISOR") !== -1)
  ) {
    return true;
  }

  return false;
};
