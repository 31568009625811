import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PatientsList from "./PatientsList";
import { setPatientList, resetPlist, getPatientSearchResultsFiltered } from "../../../slice/patientSlice";
import NavigationBar from "../../../components/common/navigation-bar";
import AddPatientButton from "../../../components/Buttons/add-patient-button";
import "../../../less/listing.less";
import "../../../less/tabs.less";
import SearchFieldPatients, { initFilters } from "../../../components/common/search-field-patients";
import Paginator from "react-hooks-paginator";

const PatientInactiveList = () => {
  const { patients } = useSelector((state) => state.patients);
  const dispatch = useDispatch();
  const {data, isLoading, totalCount } = useSelector(
    (state) => state.patients.patientSearchResultsFiltered
  );

  const perPage = initFilters.perPage;
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(() => initFilters);

  useEffect(() => {
    if (filters.page !== page) {
      setFilters((prev) => ({ ...prev, page }));
    } else {
      dispatch(getPatientSearchResultsFiltered({ ...filters, active: false }));
    }
    return () => {
      dispatch(resetPlist());
    };
  }, [filters, page]);

  return (
    <>
      <NavigationBar
        title={"Pacienti"}
        actionComponent={<AddPatientButton buttonClass="green-link" />}
      />
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 details-tabs text-center">
              <div>
                <Link to={"../active"}>Pacienti activi</Link>
              </div>
              <div className="active">Pacienti inactivi</div>
            </div>
          </div>
          <SearchFieldPatients filters={filters} setFilters={setFilters} setPage={setPage}/>
        </div>
        <PatientsList patients={data} filters={{ active: false }} />
        {data?.length === 0 && !isLoading && (
          <h4 className="text-center">Nu s-a gasit niciun pacient</h4>
        )}
        <div className="text-center">
          <Paginator
            totalRecords={totalCount ?? 1}
            pageLimit={perPage}
            pageNeighbours={1}
            setOffset={setOffset}
            currentPage={page}
            setCurrentPage={setPage}
          />
        </div>
      </div>
    </>
  );
};

export default PatientInactiveList;
