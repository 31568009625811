import React, { useEffect } from "react";
import { getHospitals, resetHslice } from "../../../slice/hospitalSlice";
import { useDispatch, useSelector } from "react-redux";
import NavigationBar from "../../../components/common/navigation-bar";
import AddHospitalButton from "../../../components/Buttons/add-hospital-button";
import "../../../less/listing.less";
import Table from "../../../components/table/Table";
import { hospitalColumns } from "../data/columns";

const HospitalsList = () => {
  const dispatch = useDispatch();
  const { hospitals } = useSelector((state) => state.hospitals);
  useEffect(() => {
    dispatch(getHospitals());
    return () => {
      dispatch(resetHslice());
    };
  }, []);

  return (
    <>
      <NavigationBar
        title={"Asociatii"}
        actionComponent={<AddHospitalButton buttonClass="green-link" />}
      />
      <div className="container-fluid">
        <div className="container lists-container">
          <Table
            columns={hospitalColumns}
            data={hospitals}
            clsName="hospital-table"
          />
        </div>
      </div>
    </>
  );
};

export default HospitalsList;
