import { ROLES } from "./permission-map";
import { useSelector } from "react-redux";

export const useGetRole = () => {
  const roles = useSelector((state) => state?.users?.loggedInUser?.roles);
  const {selectedState} = useSelector(state => state?.users)

  if (roles?.indexOf("ROLE_SUPER_ADMIN") !== -1) {
    return { role: ROLES.admin };
  }

  if (roles?.indexOf("ROLE_USER") !== -1 && selectedState?.roles?.indexOf("ROLE_SUPERVISOR") !== -1) {
    return { role: ROLES.fullEditorCaravana };
  }

  if (roles?.indexOf("ROLE_USER") !== -1 && selectedState?.roles?.indexOf("ROLE_RESIDENT") !== -1) {
    return { role: ROLES.editorCaravana };
  }

  if (roles?.indexOf("ROLE_USER") !== -1 && selectedState?.roles?.indexOf("ROLE_HOSPITAL_ADMIN") !== -1) {
    return { role: ROLES.adminCaravana };
  }

  return { role: ROLES.viewer };
};
