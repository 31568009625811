import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { fetchImage } from "../../../helpers/image-fetcher";

const UploadInput = (props) => {
  const [image, setImage] = useState("");

  useEffect(() => {
    setImage(fetchImage(props.resource, props.image));
  }, [props.resource, props.image]);

  const onChange = (value) => {
    let reader = new FileReader();

    reader.onload = function (e) {
      document
        .getElementById("image-preview")
        .setAttribute("src", e.target.result);
    };

    reader.readAsDataURL(value.target.files[0]);
  };

  return (
    <div className={props.containerClass.join(" ") + " avatar-container"}>
      <div className="avatar-default">
        <img id="image-preview" src={image} alt="Avatar" />
      </div>
      <label htmlFor="image" className="black-button add-avatar">
        Adauga Poza
      </label>
      <input
        name="image"
        id="image"
        className="hidden"
        onChange={onChange}
        type="file"
        disabled
      />
    </div>
  );
};

UploadInput.propTypes = {
  containerClass: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.string,
  resource: PropTypes.oneOf(["user", "hospital"]),
};

export default UploadInput;
