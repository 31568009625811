import initialState from "../constants/initialState";
import hospitalApiService from "../api/hospitalApiService";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getHospitals = createAsyncThunk(
  "hospitals/GET_ALL",
  async (_, thunkAPI) => {
    try {
      return await hospitalApiService.fetchingHospitals();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getOneHospital = createAsyncThunk(
  "hospitals/GET_ONE",
  async (id, thunkAPI) => {
    try {
      return await hospitalApiService.fetchOneHospital(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const requstHospitalAccess = createAsyncThunk(
  "hospitals/REQUEST_ACCESS",
  async (id, thunkAPI) => {
    try {
      return hospitalApiService.hospitalRequest(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createHospital = createAsyncThunk(
  "hospitals/CREATE_HOSPITAL",
  async (form, thunkAPI) => {
    try {
      return await hospitalApiService.addHospital(form);
    } catch (error) {
      const violations = error?.response?.data?.violations || [];
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({violations, message});
    }
  }
);

export const fetchHospitalsInput = createAsyncThunk(
  "hospitals/HOSPITALS_FETCH_INPUT",
  async (data, thunkAPI) => {
    try {
      return await hospitalApiService.fetchingHospitalsInput(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getHospitalsSearchResults = createAsyncThunk(
  "hospitals/GET_SEARCH_RESULTS",
  async (data, thunkAPI) => {
    try {
      return await hospitalApiService.fetchingHospitalsSearch(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateHospital = createAsyncThunk(
  "hospitals/UPDATE_HOSPITAL",
  async (form, thunkAPI) => {
    try {
      return await hospitalApiService.editHospital(form);
    } catch (error) {
      const violations = error?.response?.data?.violations || [];
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({violations, message});
    }
  }
);

export const hospitalSlice = createSlice({
  name: "hospitals",
  initialState: initialState.hospitals,
  reducers: {
    resetHslice: (state) => {
      state.isSucess = false;
      state.isError = false;
      state.isLoading = false;
      state.message = "";
    },
    clearHospitalsList: (state) => {
      state.hospitals = [];
    },
    clearHospitalsInput: (state) => {
      state.searchInputHospitals.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHospitals.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHospitals.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSucess = true;
        state.hospitals = action.payload;
      })
      .addCase(getHospitals.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getOneHospital.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOneHospital.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSucess = true;
        state.viewedHospital = action.payload;
      })
      .addCase(getOneHospital.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createHospital.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createHospital.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSucess = true;
        state.hospitals.push(action.payload);
      })
      .addCase(createHospital.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateHospital.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateHospital.fulfilled, (state, action) => {
        const findRec = state.hospitals.map((hospital) =>
          hospital.id === action.payload.id ? action.payload : hospital
        );
        state.isLoading = false;
        state.isSucess = true;
        state.hospitals = findRec;
        state.viewedHospital = action.payload;
      })
      .addCase(updateHospital.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getHospitalsSearchResults.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHospitalsSearchResults.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSucess = true;
        state.hospitalsSearchResult = action.payload;
      })
      .addCase(getHospitalsSearchResults.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchHospitalsInput.pending, (state) => {
        state.searchInputHospitals.isLoading = true;
      })
      .addCase(fetchHospitalsInput.fulfilled, (state, action) => {
        state.searchInputHospitals.isLoading = false;
        state.searchInputHospitals.isSucess = true;
        state.searchInputHospitals.data = action.payload;
      })
      .addCase(fetchHospitalsInput.rejected, (state, action) => {
        state.searchInputHospitals.isLoading = false;
        state.searchInputHospitals.isError = true;
        state.searchInputHospitals.message = action.payload;
      })
      .addCase(requstHospitalAccess.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(requstHospitalAccess.fulfilled, (state) => {
        state.isLoading = false;
        state.isSucess = true;
      })
      .addCase(requstHospitalAccess.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { resetHslice, clearHospitalsList, clearHospitalsInput } =
  hospitalSlice.actions;

export default hospitalSlice.reducer;
