import React from "react";
import { Field } from "react-final-form";
import Select from "react-select";
import DatePicker from "react-datepicker";

const UserFormWIthState = ({
  submitErrors,
  selectOptions,
  form,
  hospitalChange,
  ...props
}) => {
  const ReactSelectAdapter = ({ input, ...rest }) => (
    <Select {...input} {...rest} searchable />
  );

  const DatePickerAdapter = ({ input, ...rest }) => (
    <DatePicker
      {...input}
      {...rest}
      //dateFormat="dd-MM-yyyy"
      selected={input.value}
    />
  );

  const Error = ({ name }) => (
    <Field
      name={name}
      subscription={{ touched: true, error: true }}
      render={({ meta: { touched, error } }) =>
        touched && error ? <span className="text-danger">{error}</span> : null
      }
    />
  );

  return (
    <div className="col-sm-12 col-md-12 inner-form no-avatar-forms">
      <div className="fields-container">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label className="form-label">Prenume &#42;</label>
              <Field
                name="firstName"
                component="input"
                className="input-fields"
              />
              <Error name="firstName" />
              {submitErrors && (
                <div className="text-danger ml-1">
                  {submitErrors?.["firstName"]}
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label className="form-label">Nume &#42;</label>
              <Field
                name="lastName"
                component="input"
                className="input-fields"
              />
              <Error name="lastName" />
              {submitErrors && (
                <div className="text-danger ml-1">
                  {submitErrors?.["lastName"]}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label className="form-label">Email &#42;</label>
              <Field name="email" component="input" className="input-fields" />
              <Error name="email" />
              {submitErrors && (
                <div className="text-danger ml-1">
                  {submitErrors?.["email"]}
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label className="form-label">Phone &#42;</label>
              <Field name="phone" component="input" className="input-fields" />
              <Error name="phone" />
              {submitErrors && (
                <div className="text-danger ml-1">
                  {submitErrors?.["phone"]}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label className="form-label">Password &#42;</label>
              <Field
                type="password"
                name="plainPassword"
                component="input"
                className="input-fields"
              />
              <Error name="plainPassword" />
              {submitErrors && (
                <div className="text-danger ml-1">
                  {submitErrors?.["plainPassword"]}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label className="form-label">Start Date</label>
              <Field name="startDate" component={DatePickerAdapter} required />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label className="form-label">Data Final</label>
              <Field name="endDate" component={DatePickerAdapter} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label className="form-label">Asociatie &#42;</label>
              <Field
                title="Asociatie &#42;"
                name="hospital"
                options={selectOptions.hospitalOptions}
                render={(props) => {
                  return (
                    <ReactSelectAdapter
                      {...props}
                      onChange={(value) => {
                        hospitalChange(value, form.mutators.setValue);
                        props.input.onChange(value);
                      }}
                    />
                  );
                }}
              />
              <Error name="hospital" />
              {submitErrors && (
                <div className="text-danger ml-1">
                  {submitErrors?.["hospital"]}
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label className="form-label">Rol &#42;</label>
              <Field
                title="Rol &#42;"
                name="roles"
                component={ReactSelectAdapter}
                options={selectOptions.rolesList}
              />
              <Error name="roles" />
              {submitErrors && (
                <div className="text-danger ml-1">
                  {submitErrors?.["roles"]}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserFormWIthState;
