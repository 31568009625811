export const iriToLinkTransformer = (iri) => {
  return iri.replace("/api/v1", "");
};

export const idToIriTransformer = (resource, id) => {
  return "/api/v1/" + resource + "/" + id;
};

export const iriToIdTransformer = (iri) => {
  if(typeof iri !== 'string') return 
  return iri?.split('/')?.at(-1)
}
