import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../slice/userSlice";
import patientReducer from "../slice/patientSlice";
import notificationReducer from "../slice/notificationSlice";
import hospitalReducer from "../slice/hospitalSlice";
import formReducer from "../slice/formSlice";
import registriesReducer from "../slice/registriesSlice";

export const store = configureStore({
  reducer: {
    users: userReducer,
    patients: patientReducer,
    notifications: notificationReducer,
    hospitals: hospitalReducer,
    patientForms: formReducer,
    registries: registriesReducer,
  },
});
