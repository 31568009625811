export const caravanPermission = {
    'caravana': {
        "InternmentForm": {
            canEdit: ['ROLE_RESIDENT','ROLE_SUPERVISOR', 'ROLE_HOSPITAL_ADMIN'],
            canView: ['ROLE_RESIDENT','ROLE_SUPERVISOR', 'ROLE_HOSPITAL_ADMIN'],
            canAddMonitoring: ['ROLE_SUPERVISOR', 'ROLE_HOSPITAL_ADMIN']
        },
        "MonitoringForm": {
            canEdit: ['ROLE_SUPERVISOR', 'ROLE_HOSPITAL_ADMIN'],
            canView: ['ROLE_RESIDENT']
        }
    },
    'telemedicina': {
        "InternmentForm": {
            canEdit: ['ROLE_FAMILY_MEDIC', 'ROLE_HOSPITAL_ADMIN'],
            canView: ['ROLE_FAMILY_MEDIC', 'ROLE_SUPPORT_MEDIC', 'ROLE_HOSPITAL_ADMIN'],
        },
        "MonitoringForm": {
            canEdit: ['ROLE_SUPPORT_MEDIC', 'ROLE_HOSPITAL_ADMIN'],
            canView: ['ROLE_SUPPORT_MEDIC', 'ROLE_FAMILY_MEDIC']
        }
    }
}