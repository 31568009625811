import React, { useState } from "react";

export let initFilters = {
  perPage: 10,
  page: 1,
  paginated: true,
}

const SearchFieldPatients = (props) => {
  const [text, setText] = useState("");
  const [isDirty, setIsDirty] = useState(false)

  const persistOrderFilters = (filters) => {
     return Object.entries(filters || {}).reduce((acc, [key, value]) =>{
        if (typeof key === 'string' && key.startsWith("order"))
          return { ...acc, [key]: value };

        return acc
    }, {})
  }

  const handleFilterSearch = (search) => {
    let searchFilter = {
      firstName: "",
      lastName: "",
      cnp: "",
    };

    if (search !== "" && !isNaN(Number(search))) {
      searchFilter = { cnp: search };
    } else if (search.split(" ").length === 1) {
      searchFilter = { firstName: search?.split(" ")?.[0] };
    } else if (search.split(" ").length > 1) {
      const listOfNames = search.split(" ");
      const [firstName, ...lastName] = listOfNames;
      searchFilter = {
        firstName,
        lastName: lastName.join(" "),
      };
    }
    return searchFilter;
  }

  const handleSearch = () => {
    if(isDirty) {
      const filters = handleFilterSearch(text);
      props.setPage(1)
      props.setFilters({...filters, ...props.filters})
    }
  };

  const onChangeHandler = (e) => {
    const search = e.target.value;
    setText(search);

    const orderFilters = persistOrderFilters(props.filters)

    if(isDirty && search === '') {
      props.setPage(1)
      props.setFilters({...initFilters, ...orderFilters})
    } 
    setIsDirty(true)
  }

  return (
    <div className="">
      <div className={`${props.className} search-container search-container__patients header-bar`}>
        <input
          // className={"no-border-radius"}
          id="search"
          type="text"
          name="search"
          onChange={onChangeHandler}
          value={text}
          autoComplete="off"
          placeholder="Nume Prenume / CNP"
        />
        <button onClick={handleSearch} />
      </div>
    </div>
  );
};

export default SearchFieldPatients;
