import React from "react";
import "../../less/hospital-switcher.less";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectState } from "../../slice/userSlice";
import Popover from "./popover";

const HospitalSwitcher = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const states = useSelector((state) =>
    state.users.loggedInUser ? state.users.loggedInUser.states : []
  );
  const { selectedState } = useSelector((state) => state.users);

  const selectStateClick = (event) => {
    const stateIndex = parseInt(event.target.getAttribute("data-state-index"));
    dispatch(selectState(states[stateIndex], stateIndex));
    navigate("/");
  };

  const rotateCaret = () => {
    document
      .querySelector("#hospital-switcher .icon__caret")
      .classList.toggle("icon__caret--active");
  };

  if (states?.length < 2 || !selectedState) {
    return null;
  }

  return (
    <div className="hospital-switcher pull-left">
      <span id="hospital-switcher" className="selected-state">
        {selectedState?.hospital.name}
        <i className="icon__caret" />
      </span>
      <Popover
        onOpen={rotateCaret}
        onClose={rotateCaret}
        containerClass="app-popover--from-left app-popover--wide"
        openedByElements={["#hospital-switcher"]}
      >
        {states?.map((state, index) => (
          <div
            className="menu-item"
            onClick={selectStateClick}
            data-state-index={index}
            key={index}
          >
            {state.hospital.name}
          </div>
        ))}
      </Popover>
    </div>
  );
};

export default HospitalSwitcher;
