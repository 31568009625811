import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import PatientForm from "../modules/patients/forms/internment/PatientForm";
import {
  getMonitoringFormById,
  resetFormSlice,
  savedMonitoringForm,
} from "../slice/formSlice";
import Header from "../components/common/header";
import Loader from "../components/Loader/loader";
import useGetEditFormPermission from "../security/useGetEditFormPermission";


const MonitoringForm = () => {

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const {viewedForm, formValues, isLoading} = useSelector(
      (state) => state.patientForms
  );
  const {viewedPatient} = useSelector((state) => state.patients);
  const [canEdit] = useGetEditFormPermission(viewedForm?.['@type']);

  useEffect(() => {
    dispatch(getMonitoringFormById(params.id));
    return () => {
      dispatch(resetFormSlice());
    };
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(savedMonitoringForm({form: viewedForm, formValues}));
    //navigate(iriToLinkTransformer(viewedForm.patient["@id"]) + "/monitoring");
  };

  if (Object.keys(viewedForm).length === 0) {
    return <Loader loading={isLoading}/>;
  }
  return (
      <>
        <Header/>
        <Loader loading={isLoading}/>
        <PatientForm
            form={viewedForm}
            values={formValues}
            handleSubmit={(e) => handleSubmit(e)}
            backAction={`/patients-list/${viewedPatient?.id}/forms`}
            canEdit={canEdit ?? false}
        />
      </>
  );
};

export default MonitoringForm;
