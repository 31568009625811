import React, { useEffect } from "react";
import NavigationBar from "../../../components/common/navigation-bar";
import { Link, useParams } from "react-router-dom";
import { getOnePatient, resetPlist } from "../../../slice/patientSlice";
import { useDispatch, useSelector } from "react-redux";
import NewInternmentForm from "../../../components/forms/NewInternmentForm";
import format from "date-fns/format";
import "../../../less/tabs.less";
import "../../../less/patient-internment-tab.less";
import { savePatientFormCases } from "../../../slice/formSlice";
import Table from "../../../components/table/Table";
import { formColumns } from "../data/form-columns";
import { getHospitals } from "../../../slice/hospitalSlice";
import Loader from "../../../components/Loader/loader";
import { getPercentCompleted } from "../data/helpers";
import PermissionsGate from "../../../security/permisionGate";
import { canAddInternment } from "../../../security/customPermission";
import { useDetermineRouteByRole } from "../../../security/useDetermineRouteByRole";

const PatientFormsTab = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { viewedPatient } = useSelector((state) => state.patients);
  const redirectTo = useDetermineRouteByRole({
    role: "ROLE_SUPPORT_MEDIC",
    route: "/all-support-request",
    defaultRoute: "/patients-list/active",
  });

  const { data = [], isLoading: isLoadingPatientForms } = useSelector(
    (state) => state.patientForms.patientFormCases
  );
  const { hospitals, isLoading: isLoadingHospital } = useSelector(
    (state) => state.hospitals
  );
  const { states: loggedInUserStates } = useSelector(
    (state) => state?.users?.loggedInUser
  );
  const { selectedState } = useSelector(
    (state) => state?.users
  );
  const loggedInUserHospitalAccessNames = loggedInUserStates?.map(
    (state) => state?.hospital?.name
  );
  const loggedInUserHospitalAccessIds = loggedInUserStates?.map(
    (state) => state?.hospital?.id
  );
  const regId = localStorage.getItem("regId");
  let userHospitals = {};
  loggedInUserHospitalAccessNames.forEach(
    (item) => (userHospitals[item] = null)
  );

  const hospitalMap = new Map(
    hospitals?.map((hospital) => [hospital?.id, hospital?.name])
  );

  const findHospitalName = (hospitalId) => {
    return hospitalMap.get(hospitalId);
  };

  const groupedInternmentForms = data?.reduce((acc, curr) => {
    const hospitalName = findHospitalName(curr.hospitalId);

    if (!acc[hospitalName]) {
      acc[hospitalName] = [];
    }

    acc[hospitalName].push(curr);

    return acc;
  }, {});

  const arrangedHospitalsInternmentForms = Object.assign(
    userHospitals,
    groupedInternmentForms
  );

  useEffect(() => {
    let filters = {}

    if (
      null !== selectedState &&
      (selectedState?.roles.indexOf("ROLE_FAMILY_MEDIC") !== -1 || 
      selectedState?.roles.indexOf("ROLE_SUPPORT_MEDIC") !== -1
      )
    ) {
      filters["patientCase.registry.id"] = regId;
    }

    dispatch(getOnePatient(params.id));
    dispatch(savePatientFormCases({...filters, id: params.id}));
    dispatch(getHospitals());
    return () => {
      dispatch(resetPlist());
    };
  }, []);
  const getTitle = () => {
    return (
      (viewedPatient?.firstName || "") + " " + (viewedPatient?.lastName || "")
    );
  };

  const formToTableRow = (form) => {
    return (
      <tr key={form.id}>
        <td>
          <Link to={`/internment-forms/${form?.id}`} className="form-title">
            {form?.template?.name}
          </Link>
        </td>
        <td>
          {form?.completedBy
            ? form?.completedBy.firstName + " " + form?.completedBy.lastName
            : "-"}
        </td>
        <td>{getPercentCompleted(form)} %</td>
        <td>
          {form?.completedAt
            ? format(Date.parse(form.completedAt), "dd-MM-yyyy")
            : "-"}
        </td>
        <td>
          <Link
            className="complete-action"
            to={"/internment-forms/" + form?.id}
          >
            Completeaza
          </Link>
        </td>
      </tr>
    );
  };

  return (
    <div className="patient-internment-tab">
      <NavigationBar
        title={getTitle()}
        actionComponent={
          <PermissionsGate customPermissionFn={canAddInternment}>
            <NewInternmentForm
              viewedPatient={viewedPatient}
              buttonClass="green-link green-link__internment-form"
              patientId={viewedPatient.id}
              buttonText={"Adauga formular screening"}
            />
          </PermissionsGate>
        }
        backAction={redirectTo}
        backText="Lista"
      />

      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 details-tabs text-center">
            <div>
              <Link to={"/patients-list/" + viewedPatient.id}>Informatii</Link>
            </div>
            <div className="active">Formulare</div>
          </div>
        </div>
        <div className="row details-tab-contents forms-list">
          <div className="row">
            {(isLoadingHospital || isLoadingPatientForms) && (
              <Loader loading={isLoadingHospital || isLoadingPatientForms} />
            )}
            {Object.entries(arrangedHospitalsInternmentForms ?? {}).map(
              ([hospital, internmentForms], index) => {
                if (!internmentForms) return null;
                let internmentHospitalId = null;

                const monitoringForms = internmentForms
                  ?.map((internmentItem) => {
                    internmentHospitalId = internmentItem.hospitalId;
                    return internmentItem.monitoringForms;
                  })
                  ?.map((monitoringForm) =>
                    monitoringForm?.map((item) => ({
                      ...item,
                      hospitalId: internmentHospitalId,
                    }))
                  );

                return (
                  <div className="row" key={index}>
                    <h2 className="text-center">{hospital}</h2>
                    <Table
                      userHospitalsIds={loggedInUserHospitalAccessIds}
                      columns={formColumns}
                      data={arrangedHospitalsInternmentForms[hospital]}
                      expandedData={monitoringForms}
                    />
                  </div>
                );
              }
            )}

            {/* <table className='table'>
              <thead>
                <tr>
                  <th>Nume formular</th>
                  <th>Completat de</th>
                  <th>Status</th>
                  <th>Data ultimei completari</th>
                  <th>Actiuni</th>
                </tr>
              </thead>
              <tbody>

                {data?.map((form) => formToTableRow(form))}
              </tbody>
            </table> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientFormsTab;
