import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import SaveHospitalButton from "../../../components/Buttons/save-hospital-button";
import HospitalForm from "../forms/hospital-form";
import NavigationBar from "../../../components/common/navigation-bar";
import {Link, useNavigate, useParams} from "react-router-dom";
import {getOneHospital, updateHospital} from "../../../slice/hospitalSlice";
import {getFormErrors} from "../../../helpers/validate-form";
import {hospitalValidationSchema} from "../data/helpers";
import {toast} from "react-toastify";
import { mapResponseErrorObject } from "../../../helpers/response-map-functions";

const EditHospital = () => {
  const [formErrors, setFormErrors] = useState({});
  const {viewedHospital} = useSelector((state) => state.hospitals);
  const [hospitalFields, setHospitalFields] = useState({});
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getOneHospital(params.id));
  }, []);

  useEffect(() => {
    setHospitalFields({
      ...hospitalFields,
      name: viewedHospital?.name,
      country: viewedHospital?.country,
      county: viewedHospital?.county,
      city: viewedHospital?.city,
      id: viewedHospital?.id,
      street: viewedHospital?.street,
      streetNumber: viewedHospital?.streetNumber,
      zipCode: viewedHospital?.zipCode,
    });
  }, [viewedHospital]);

  const handleFocus = (value) => {
    setFormErrors((prevState) => {
      const copy = {...prevState};
      delete copy[value];
      return copy;
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let formPayload = {...hospitalFields};

    const inputsToValidate = {
      name: formPayload.name,
      street: formPayload.street,
      streetNumber: formPayload.streetNumber,
      zipCode: formPayload.zipCode,
      country: formPayload.country,
      county: formPayload.county,
      city: formPayload.city,
      id: formPayload.id,
    };
    const errors = getFormErrors(inputsToValidate, hospitalValidationSchema);
    setFormErrors(errors);
    const valid = Object.keys(errors).length === 0;
    try {
      if (valid) {
        const response = await dispatch(updateHospital(formPayload)).unwrap();
        if (response) {
          toast.success("Asociatia a fost editata cu succes!");
          navigate("/hospitals/list");
        }
      }
    } catch (err) {
      if (err?.violations?.length > 0) {
        const mapErrors = mapResponseErrorObject(err?.violations);
        setFormErrors((prev) => {
          return { ...prev, ...mapErrors };
        });
      } else {
        toast.error(err?.message);
      }
    }
  };
  const handleChanges = (e) => {
    setHospitalFields({
      ...hospitalFields,
      [e.target.name]: e.target.value,
    });
  };

  const actionContainer = (
      <>
        <Link to="/hospitals/list" className="back-link btn--spaced-right">
          Inapoi
        </Link>
        <input type="submit" className="green-button" value="Salveaza"/>
      </>
  );

  return (
      <div className="container-fluid">
        <NavigationBar
            title={viewedHospital.name}
            action="edit-hospital"
            actionComponent={
              <SaveHospitalButton
                  buttonClass="green-link"
                  userId={viewedHospital.id || ""}
              />
            }
            backText="Lista"
            backAction="/hospitals/list"
        />
        <div className="container forms-container">
          <form id="hospital-form" onSubmit={handleFormSubmit} autoComplete="off">
            <HospitalForm
                handleChanges={handleChanges}
                handleFormSubmit={handleFormSubmit}
                {...hospitalFields}
                actionContainer={actionContainer}
                handleFocus={handleFocus}
                errors={formErrors}
            />
          </form>
        </div>
      </div>
  );
};

export default EditHospital;
