/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../../less/upload-image.less";
import { request } from "../../../constants/axios-warpers";
import { toast } from "react-toastify";
import { iriToIdTransformer } from "../../../helpers/iri-transformer";
import Paginator from "react-hooks-paginator";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getOnePatient } from "../../../slice/patientSlice";
import { savePatientFormCases } from "../../../slice/formSlice";
import { getHospitals } from "../../../slice/hospitalSlice";
import { resetPlist } from "../../../slice/patientSlice";

const DownloadImage = ({ closeModal, internment }) => {
  const pageLimit = 4;
  const { selectedState } = useSelector((state) => state?.users);
  const dispatch = useDispatch();
  const params = useParams();
  const regId = localStorage.getItem("regId");
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState([]);

  useEffect(() => {
    setCurrentData(data?.slice(offset, offset + pageLimit));
  }, [offset, data]);

  useEffect(() => {
    setData(internment.files);
  }, [internment.files]);

  useEffect(() => {
    let filters = {};

    if (
      null !== selectedState &&
      (selectedState?.roles.indexOf("ROLE_FAMILY_MEDIC") !== -1 ||
        selectedState?.roles.indexOf("ROLE_SUPPORT_MEDIC") !== -1)
    ) {
      filters["patientCase.registry.id"] = regId;
    }

    dispatch(getOnePatient(params.id));
    dispatch(savePatientFormCases({ ...filters, id: params.id }));
    dispatch(getHospitals());
    return () => {
      dispatch(resetPlist());
    };
  }, []);

  const handleDownloadClick = async (file) => {
    try {
      const response = await request.get(
        "/download/" + iriToIdTransformer(file?.["@id"]),
        {
          responseType: "blob",
        }
      );

      const blob = response.data;

      const blobURL = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");
      downloadLink.href = blobURL;
      downloadLink.download = file?.["originalFilename"];
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      window.URL.revokeObjectURL(blobURL);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="popup responsive">
        <div className="upload-image">
          <h2 className="text-center">Descarca imagini</h2>
          <ul className="images-list">
            {currentData?.map((file) => {
              return (
                <li key={file?.["@id"]}>
                  <span>{file.originalFilename}</span>
                  <button
                    className="green-button"
                    onClick={() => handleDownloadClick(file)}
                  >
                    Descarca
                  </button>
                </li>
              );
            })}
          </ul>

          <div className="text-center">
            <Paginator
              totalRecords={internment.files?.length || 0}
              pageLimit={pageLimit}
              pageNeighbours={2}
              setOffset={setOffset}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>

          <div className="text-center">
            <button
              type="button"
              className="text-center green-button"
              onClick={closeModal}
            >
              Inchide
            </button>
          </div>
        </div>
      </div>
      <div className="popup-bg" onClick={closeModal || null} />
    </>
  );
};

export default DownloadImage;
