import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchSupervisorsList,
  fetchingSpecializationsList,
  fetchingTitlesList,
} from "../../../slice/userSlice";

import { getHospitals } from "../../../slice/hospitalSlice";

const useGetUserData = () => {
  const dispatch = useDispatch();
  const { viewedUser, selectedState, loggedInUser, isLoading: isLoadingUser } = useSelector(
    (state) => state.users
  );
  const { data: supervisorsList, isLoading: loadingSupervisorsList } =
    useSelector((state) => state.users.supervisorsList);
  const { data: specializationsList, isLoading: loadingSpecializationsList } =
    useSelector((state) => state.users.specializationsList);
  const { data: titleList, isLoading: loadingTitleList } = useSelector(
    (state) => state.users.titleList
  );
  const { hospitals, isLoading: loadingHospitals } = useSelector(
    (state) => state.hospitals
  );

  useEffect(() => {
    dispatch(fetchingSpecializationsList());
    dispatch(fetchingTitlesList());
    dispatch(fetchSupervisorsList());
    dispatch(getHospitals());
  }, []);

  const isLoading =
    isLoadingUser ||
    loadingSpecializationsList ||
    loadingSupervisorsList ||
    loadingTitleList ||
    loadingHospitals;

  return {
    viewedUser,
    selectedState,
    loggedInUser,
    supervisorsList,
    specializationsList,
    titleList,
    hospitals,
    isLoading
  };
};

export default useGetUserData;
