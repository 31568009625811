import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFormTypes,
  createMonitoringForm,
  getMonitoringTemplates,
  savePatientFormCases,
} from "../../slice/formSlice";
import "../../less/new-patient-form-popup.less";
import SelectInput from "./general/select-input";
import { idToIriTransformer, iriToIdTransformer } from "../../helpers/iri-transformer";
import { updatePatientCase } from "../../slice/patientSlice";

const NewMonitoringForm = (props) => {
  const [formState, setFormState] = useState({
    selectedFormTypes: [],
    selectedInternment: null,
    selectedMonitoring: null,
  });
  const dispatch = useDispatch();
  const { viewedPatient } = useSelector((state) => state.patients);
  const { data: formTypes } = useSelector(
    (state) => state.patientForms.patientFormCases
  );
  const { data: monitoringTemplates } = useSelector(
    (state) => state.patientForms.monitoringTemplates
  );
  const { selectedState } = useSelector((state) => state.users);
  const selectedRegistry = localStorage.getItem("regId");
  const regIdIri = idToIriTransformer(
    "registries",
    localStorage.getItem("regId")
  );
  const patientCase = viewedPatient?.patientCases?.filter(
    (item) => item?.registry === regIdIri
  );
  const patientCaseId = iriToIdTransformer(patientCase?.[0]?.["@id"]);


  useEffect(() => {
    dispatch(fetchFormTypes(selectedRegistry));
    dispatch(getMonitoringTemplates(selectedRegistry));
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formState.selectedMonitoring) {
      dispatch(
        createMonitoringForm({
          formType: formState.selectedMonitoring,
          patientCase: `${viewedPatient.patientCases[0]["@id"]}`,
          internmentForm: `${formState?.selectedInternment}`,
        })
      )
        .then(() => {
          const caseForm = {
            registry: regIdIri,
            supervisorState: selectedState?.["@id"],
            hospital: selectedState?.hospital?.["@id"],
            patient: viewedPatient["@id"],
            id: patientCaseId,
          };
          if (selectedState.roles?.includes("ROLE_SUPERVISOR")) {
            dispatch(updatePatientCase(caseForm));
          }
        })
        .then(() => {
          props.closeModal();
        })
        .then(() => {
          let filters = {};

          if (
            null !== selectedState &&
            (selectedState?.roles.indexOf("ROLE_FAMILY_MEDIC") !== -1 ||
              selectedState?.roles.indexOf("ROLE_SUPPORT_MEDIC") !== -1)
          ) {
            filters["patientCase.registry.id"] = selectedRegistry;
          }
          dispatch(savePatientFormCases({ id: viewedPatient.id, ...filters }));
        });
    }
  };

  const buildInternmentOptions = (patientFormTypes) => {
    return patientFormTypes
      ?.filter((e) => e["@type"] === "InternmentForm")
      .filter((e) => e?.["@id"] === props?.internment)
      .map((form) => {
        return {
          value: form["@id"],
          label: form.template.name,
        };
      });
  };

  const selectInternmentOption = (option) => {
    setFormState({
      ...formState,
      selectedInternment: option.value,
    });
  };

  const renderAvailableMonitoringTemplates = () => {
    const selectedInternmentForm = formTypes?.find(
      (e) => e["@id"] === formState.selectedInternment
    );

    if (!selectedInternmentForm) {
      return null;
    }

    return (
      <div>
        <SelectInput
          onChange={(e) => selectMonitoringOption(e)}
          name="monitoring"
          title="Formular de specialitate disponibile"
          value={formState.selectedMonitoring}
          options={buildMonitoringOptions(selectedInternmentForm)}
          multi={false}
        />
      </div>
    );
  };

  const selectMonitoringOption = (option) => {
    setFormState({
      ...formState,
      selectedMonitoring: option.value,
    });
  };

  const buildMonitoringOptions = (selectedInternmentForm) => {
    return monitoringTemplates?.map((template) => {
      return {
        label: template.name,
        value: template["@id"],
      };
    });
  };

  return (
    <div className="new-patient-form">
      <div className="popup">
        <h2>Adauga formular Specialitate</h2>
        <form onSubmit={handleSubmit}>
          <div id="patient-internments">
            <SelectInput
              onChange={(e) => selectInternmentOption(e)}
              name="internment"
              title="Pentru Screening-ul"
              value={formState.selectedInternment}
              options={buildInternmentOptions(formTypes)}
              multi={false}
            />
          </div>

          {renderAvailableMonitoringTemplates()}

          <div className="text-center">
            <button type="submit" className="btn btn-success">
              Salveaza
            </button>
          </div>
        </form>
      </div>
      <div className="popup-bg" onClick={props.closeModal || null} />
    </div>
  );
};

NewMonitoringForm.propTypes = {
  closePopupCallback: PropTypes.func,
  viewedPatient: PropTypes.object.isRequired,
};

export default NewMonitoringForm;
