import React from "react";
import PropTypes from "prop-types";
import { iriToLinkTransformer } from "../../helpers/iri-transformer";
import { readNotification } from "../../slice/notificationSlice";
import { fetchOne } from "../../slice/userSlice";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import format from "date-fns/format";

const Notification = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { notification } = props;

  const read = (event) => {
    const notificationElement = event.target;
    if (!notification?.isRead) {
      dispatch(
        readNotification(
          parseInt(notificationElement.getAttribute("data-notification-id"))
        )
      ).then(() => dispatch(fetchOne(params.id)));
    }
  };

  return (
    <span
      data-to={iriToLinkTransformer(notification?.resourceLink)}
      onClick={read}
      className="list-group-item"
      data-notification-id={notification.id}
    >
      {!notification.isRead ? <span className="un-read">!</span> : ""}
      {notification?.description}
      {notification?.createdAt ? (
        <span className="label label-info" style={{ marginLeft: "10px" }}>
          {format(Date.parse(notification?.createdAt), "dd-MM-yyyy")}
        </span>
      ) : null}
    </span>
  );
};

Notification.propTypes = {
  notification: PropTypes.object.isRequired,
};

export default Notification;
