import React, { useEffect } from "react";
import Header from "../../../components/common/header";
import NavigationBar from "../../../components/common/navigation-bar";
import Loader from "../../../components/Loader/loader";
import AddUserButton from "../../../components/Buttons/add-user-button";
import { useSelector, useDispatch } from "react-redux";
import { fetchUsers, reset } from "../../../slice/userSlice";
import { useNavigate } from "react-router-dom";

const UserDashboard = (props) => {
  const { isLoading } = useSelector((state) => state.users.users);
  const { loggedIn, selectedState, loggedInUser } = useSelector(
    (state) => state.users
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loggedIn) {
      navigate("/login");
    }
    let userFilters = {};
    if (
      null !== selectedState &&
      selectedState?.roles.indexOf("ROLE_SUPERVISOR") !== -1
    ) {
      userFilters["states.supervisor"] = loggedInUser["@id"];
      userFilters["states.roles"] = "ROLE_RESIDENT";
    }

    dispatch(fetchUsers(userFilters));

    return () => {
      dispatch(reset());
    };
  }, []);
  return (
    <>
      <Header />
      <NavigationBar
        title={"Medici"}
        actionComponent={<AddUserButton buttonClass="green-link" />}
      />
      <Loader loading={isLoading} />
      {props.children}
    </>
  );
};

export default UserDashboard;
