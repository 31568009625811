import React, {useState} from "react";
import {formatDate} from "../../modules/patients/data/helpers";
import {getPercentCompleted} from "../../modules/patients/data/helpers";
import InternmentPatientActions from "../../modules/patients/components/InternmentPatientActions";
import {Link} from "react-router-dom";

const TableBody = (props) => {
  const [isOpen, setIsOpen] = useState([]);
  const {columns, data, expandedData, hasExpandColumn} = props;

  const handleExpand = (rowIndex) => {
    setIsOpen((prevOpen) => {
      const newOpen = [...prevOpen];
      newOpen[rowIndex] = !newOpen[rowIndex];
      return newOpen;
    });
  };

  const buildRow = (row, index, isExpaned) => {
    return (
        <React.Fragment key={index}>
          <tr key={index}>
            {hasExpandColumn && (
                <td>
                  {!isExpaned && <button
                      onClick={() => {
                        handleExpand(index);
                      }}
                  >
                    {isOpen[index] ? "-" : "+"}
                  </button>}

                </td>
            )}
            {columns.map(({key, renderFn, date}, colIndex) => {
              if (key === 'expand') return null
              let value = row[key];
              if (date) {
                value = formatDate(value)
              }
              if (typeof renderFn === "function") {
                value = renderFn(value, row, props);
              }
              return <td key={colIndex}>{value}</td>;
            })}
          </tr>
          {isOpen[index] &&
              expandedData[index].map((item, index) => {
                // let isExpaned = true;
                // return buildRow(item, index, isExpaned)
                return (
                    <tr key={item.id}>
                      <td colSpan={1}></td>
                      <td>{item?.['@type'] === "MonitoringForm" ? "Formular Specialitate" : "--"}</td>
                      <td>{<Link to={`/monitoring-forms/${item?.id}`} className='form-title'>
                        {item?.template?.name}
                      </Link>}</td>
                      <td>{item?.completedBy?.firstName + " " + item?.completedBy?.lastName}</td>
                      <td>{getPercentCompleted(item) + " %"}</td>
                      <td>{formatDate(item?.completedAt)}</td>
                      <td>{formatDate(item?.completedAt)}</td>
                      <td>{<InternmentPatientActions form={item}/>}</td>
                    </tr>
                );
              })}
        </React.Fragment>
    );
  };
  return (
      <tbody>{data && data.map((value, index) => buildRow(value, index))}</tbody>
  );
};

export default TableBody;
