import { request } from "../constants/axios-warpers";

const fetchingUserRegistries = async (data) => {
  const response = await request.get(`registries?hospitals.id=${data}`);

  return response.data["hydra:member"];
};

const fetchingOneUserRegistries = async (data) => {
  const response = await request.get(`registries/${data}`);

  return response.data;
};

const registriesApiService = {
  fetchingUserRegistries,
  fetchingOneUserRegistries,
};

export default registriesApiService;
