import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ListActionsDropdown from "../../../components/common/list-actions-dropdown";
import NewMonitoringForm from "../../../components/forms/NewMonitoringForm";
import useGetEditFormPermission from "../../../security/useGetEditFormPermission";
import ModalWithPortal from "../../../components/common/modal-portal";
import PermissionsGate from "../../../security/permisionGate";
import {
  canAddMonitoring,
  canAttachImages,
  canDownloadImages,
} from "../../../security/customPermission";
import { request } from "../../../constants/axios-warpers";
import { toast } from "react-toastify";
import UploadImage from "./UploadImage";
import DownloadImage from "./DownloadImages";

const InternmentPatientActions = (props) => {
  const internmentForm = props.form;
  const { viewedPatient } = useSelector((state) => state.patients);
  const [showModal, setShowModal] = useState(false);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const isInternmentForm =
    internmentForm["@type"] === "InternmentForm" ? true : false;

  const routePath = isInternmentForm
    ? "/internment-forms"
    : "/monitoring-forms";

  const [canEdit] = useGetEditFormPermission(internmentForm["@type"]);

  let monitoringFormAdded = false;
  if (isInternmentForm && internmentForm?.["monitoringForms"]?.length > 0) {
    monitoringFormAdded = true;
  }

  const onButtonClick = () => {
    request
      .get(`/caravan/pdf/${internmentForm.id}`, { responseType: "blob" })
      .then((response) => {
        const fileURL = window.URL.createObjectURL(response.data);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Screening.pdf";
        alink.click();
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  return (
    <ListActionsDropdown>
      <Link
        className="complete-action menu-item"
        to={`${routePath}/${internmentForm?.id}`}
        state={{ canEdit: canEdit }}
      >
        {canEdit ? "Completeaza" : "Vizualizeaza"}
      </Link>
      {isInternmentForm && !monitoringFormAdded && (
        <PermissionsGate customPermissionFn={canAddMonitoring}>
          <ModalWithPortal
            buttonText={"Adauga formular Specialitate"}
            cssClasses="menu-item"
            modalState={showModal}
            handleShowModal={() => setShowModal((prev) => !prev)}
          >
            <NewMonitoringForm
              viewedPatient={viewedPatient}
              buttonClass="complete-action menu-item"
              patientId={viewedPatient.id}
              closeModal={() => setShowModal(false)}
              internment={internmentForm?.["@id"]}
            />
          </ModalWithPortal>
        </PermissionsGate>
      )}
      {isInternmentForm && (
        <PermissionsGate customPermissionFn={canAttachImages}>
          <ModalWithPortal
            buttonText={"Incarca imagine"}
            modalState={showUploadImage}
            cssClasses="menu-item col-xs-12"
            handleShowModal={() => setShowUploadImage((prev) => !prev)}
          >
            <UploadImage
              buttonClass="complete-action menu-item"
              closeModal={() => setShowUploadImage(false)}
              internment={internmentForm?.["id"]}
            />
          </ModalWithPortal>
        </PermissionsGate>
      )}
      {/* .length > 0 */}
      {isInternmentForm && internmentForm?.files?.length >= 0 && (
        <PermissionsGate customPermissionFn={canDownloadImages}>
          <ModalWithPortal
            buttonText={"Descarca imagini"}
            modalState={showDownloadModal}
            cssClasses="menu-item col-xs-12"
            handleShowModal={() => setShowDownloadModal((prev) => !prev)}
          >
            <DownloadImage
              buttonClass="complete-action menu-item"
              closeModal={() => setShowDownloadModal(false)}
              internment={internmentForm}
            />
          </ModalWithPortal>
        </PermissionsGate>
      )}
      {isInternmentForm && (
        <p className="complete-action menu-item" onClick={onButtonClick}>
          Print
        </p>
      )}
    </ListActionsDropdown>
  );
};

export default InternmentPatientActions;
