import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser, reset } from "./slice/userSlice";
import Loader from "./components/Loader/loader";
import { setUserRegistries } from "./slice/registriesSlice";
import MainRoutes from "./MainRoutes";

/*use effect require auth*/

function App() {
  const { loggedIn, loggedInUser } = useSelector((state) => state.users);
  const { selectedState } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loggedIn && localStorage.getItem("OncoUserToken")) {
      dispatch(setUser()).then(() => setLoaded(true));
    } else {
      setLoaded(true);
    }
    return () => {
      dispatch(reset());
    };
  }, [dispatch, loggedIn]);

  useEffect(() => {
    if (selectedState?.hospital?.id) {
      dispatch(setUserRegistries(selectedState?.hospital?.id));
    }
  }, [selectedState?.hospital?.id, dispatch, loggedInUser]);

  useEffect(() => {
    if (
      loggedInUser &&
      loggedInUser?.roles?.indexOf("ROLE_SUPER_ADMIN") !== -1
    ) {
      dispatch(setUserRegistries());
    }
  }, [loggedInUser, dispatch]);

  return loaded ? <MainRoutes /> : <Loader loading />;
}

export default App;
