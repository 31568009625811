import React, { useEffect } from "react";
import NavigationBar from "../../../components/common/navigation-bar";
import EditHospitalButton from "../../../components/Buttons/edit-hospital-button";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getOneHospital, resetHslice } from "../../../slice/hospitalSlice";
import "../../../less/hospital-info.less";

const HospitalInfo = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { viewedHospital } = useSelector((state) => state.hospitals);

  useEffect(() => {
    dispatch(getOneHospital(params.id));
    return () => {
      dispatch(resetHslice());
    };
  }, []);

  return (
    <div>
      <NavigationBar
        title={viewedHospital.name}
        actionComponent={
          <EditHospitalButton
            buttonClass="green-link"
            hospitalId={viewedHospital.id}
          />
        }
        backText="Lista"
        backAction="/hospitals/list"
      />
      <div className="container">
        <div className="row">
          <div className="container-fluid hospital-info-view">
            <div className="col-sm-3 col-md-3 map-container" />
            <div className="col-sm-9 col-md-9 info-container">
              <div className="col-sm-12 col-md-12 info-header">
                <span className="name">{viewedHospital.name}</span>
                <span className="address">
                  {viewedHospital.street}, {viewedHospital.city},{" "}
                  {viewedHospital.county}, {viewedHospital.country}
                </span>
              </div>
              <div className="col-sm-12 col-md-12 info-inner">
                <div className="col-sm-3 col-md-3 left-container">
                  <span className="bold-title">Persoana de contact:</span>
                  <span className="infos">Telefon:</span>
                  <span className="infos">Email:</span>
                </div>
                <div className="col-sm-9 col-md-9">
                  <span className="bold-title">
                    {viewedHospital.contactPerson}
                  </span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        viewedHospital?.administrators?.length > 0
                          ? viewedHospital.administrators
                              .map((admin) => {
                                return admin.firstName + " " + admin.lastName;
                              })
                              .join("<br>")
                          : "-",
                    }}
                    className="infos"
                  />
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        viewedHospital?.administrators?.length > 0
                          ? viewedHospital.administrators
                              .map((admin) => {
                                return admin.phone;
                              })
                              .join("<br>")
                          : "-",
                    }}
                    className="infos"
                  />
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        viewedHospital?.administrators?.length > 0
                          ? viewedHospital.administrators
                              .map((admin) => {
                                return admin.email;
                              })
                              .join("<br>")
                          : "-",
                    }}
                    className="infos"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HospitalInfo;
