import React from "react";
import PropTypes from "prop-types";

function withFormButtonAndPopup(PopupBody) {
  return class extends React.Component {
    constructor() {
      super();

      this.state = {
        isPopupOpen: false,
      };

      this.openAddFormPopup = this.openAddFormPopup.bind(this);
      this.closeAddFormPopup = this.closeAddFormPopup.bind(this);
    }

    openAddFormPopup() {
      this.setState({
        isPopupOpen: true,
      });
    }

    closeAddFormPopup() {
      this.setState({
        isPopupOpen: false,
      });
    }

    renderPopup() {
      if (!this.state.isPopupOpen) {
        return null;
      }

      return (
        <PopupBody
          closePopupCallback={this.closeAddFormPopup}
          {...this.props}
        />
      );
    }

    render() {
      return (
        <div>
          <div className="col-sm-3 col-md-3 button-container">
            <button
              onClick={this.openAddFormPopup}
              className={this.props.buttonClass}
            >
              {this?.props?.buttonText}
            </button>
          </div>
          {this.renderPopup()}
        </div>
      );
    }
  };
}

withFormButtonAndPopup.propTypes = {
  buttonClass: PropTypes.string.isRequired,
  viewedPatient: PropTypes.object.isRequired,
};

export default withFormButtonAndPopup;
