import React from "react";
import Patients from "../../images/patients.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentRegistry } from "../../slice/registriesSlice";
import { useDetermineRouteByRole } from "../../security/useDetermineRouteByRole";

const HomeElement = ({ item }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redirectTo = useDetermineRouteByRole({
    role: "ROLE_SUPPORT_MEDIC",
    route: "all-support-request",
    defaultRoute: "/patients-list/active",
  });

  return (
    <div className="col-md-4 col-sm-6 col-xs-12 column-margin">
      <div className="content colour-1 text-center">
        <img
          className="img-circle"
          src={Patients}
          alt="img-name"
          width={75}
          height={75}
        />
        <p className="title">{item?.name.toUpperCase()}</p>

        <p>
          <button
            className="btn"
            onClick={() => {
              navigate(redirectTo);
              dispatch(setCurrentRegistry(item));
            }}
          >
            Acceseaza Registrul
          </button>
        </p>
      </div>
    </div>
  );
};

export default HomeElement;
