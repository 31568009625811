import React, { useEffect } from "react";
import {
  fetchUsers,
  impersonate,
  setUser,
  clearUsersList,
  clearUsersInput,
} from "../../../slice/userSlice";
import { useDispatch } from "react-redux";
import "../../../less/listing.less";
import "../../../less/tabs.less";
import {
  clearPatientsInput,
  clearPatientsList,
} from "../../../slice/patientSlice";
import {
  clearHospitalsInput,
  clearHospitalsList,
} from "../../../slice/hospitalSlice";
import { useNavigate } from "react-router-dom";
import { userColumns } from "../data/columns";
import Table from "../../../components/table/Table";

const ListingUsers = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getListAction = props.getListAction || fetchUsers;
  useEffect(() => {
    document.addEventListener("click", (e) => {
      let element = e.target,
        visibleDropdown = document.getElementsByClassName("show")[0];

      if (!element.classList.contains("actions-button") && visibleDropdown) {
        visibleDropdown.classList.remove("show");
      }
    });
  }, []);

  const handleSort = (column, order) => {
    const parametersFilters =
      column === "fullName"
        ? {
            "order[firstName]": order,
            "order[lastName]": order,
          }
        : {
            ["order[" + column + "]"]: order,
          };
    dispatch(getListAction(parametersFilters));
  };

  const impersonateUser = (user) => {
    Promise.resolve(
      dispatch(impersonate(user)),
      dispatch(clearUsersList()),
      dispatch(clearPatientsList()),
      dispatch(clearHospitalsList())
    )
      .then(() => dispatch(setUser()))
      .then(() =>
        Promise.resolve(
          dispatch(clearUsersInput()),
          dispatch(clearPatientsInput()),
          dispatch(clearHospitalsInput())
        )
      )
      .then(navigate("/"));
  };

  return (
    <div className="container-fluid">
      <div className="container lists-container">
        <Table
          clsName="user-table"
          columns={userColumns}
          data={props.users}
          impersonate={impersonateUser}
          onSort={handleSort}
        />
      </div>
    </div>
  );
};

export default ListingUsers;
