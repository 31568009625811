import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

export const DatePickerAdapter = ({ input, ...rest }) => (
  <DatePicker
    {...input}
    {...rest}
    //dateFormat="dd-MM-yyyy"
    selected={input.value}
  />
);

export const ReactSelectAdapter = ({ input, ...rest }) => (
  <Select {...input} {...rest} searchable />
);
