import React from "react";
import PropTypes from "prop-types";

const SubGroupNav = (props) => {
  const renderPrevButton = () => {
    if (props.subGroups.length === 0) {
      return null;
    }

    return props.activeSubGroupTab !== 0 ? (
      <div className="col-sm-4 col-md-4 text-left">
        <span
          className="back-nav"
          onClick={props.changeSubGroupTab}
          data-index={props.activeSubGroupTab - 1}
        >
          {props.subGroups[props.activeSubGroupTab - 1].name}
        </span>
      </div>
    ) : (
      <div className="col-sm-4 col-md-4" />
    );
  };

  const renderNextButton = () => {
    if (props.subGroups.length === 0) {
      return null;
    }

    return props.activeSubGroupTab !== props.subGroups.length - 1 ? (
      <div className="col-sm-4 col-md-4 text-right">
        <span
          className="next-nav"
          data-index={props.activeSubGroupTab + 1}
          onClick={props.changeSubGroupTab}
        >
          {props.subGroups[props.activeSubGroupTab + 1].name}
        </span>
      </div>
    ) : null;
  };

  return (
    <div className="row tabs-navigation-actions">
      {renderPrevButton()}
      <div className="col-sm-4 col-md-4 text-center">
        <button className={props.canEdit ? `green-button` : 'hidden'} type="submit">
          Salveaza completarile
        </button>
      </div>
      {renderNextButton()}
    </div>
  );
};

SubGroupNav.propTypes = {
  subGroups: PropTypes.array.isRequired,
  activeSubGroupTab: PropTypes.number.isRequired,
  changeSubGroupTab: PropTypes.func.isRequired,
};

export default SubGroupNav;
