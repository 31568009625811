import * as yup from "yup";

export const hospitalValidationSchema = yup.object().shape({
  name: yup
    .string()
    .min(3, "Minum 3 caractere")
    .max(50, "Maxim 50 caractere")
    .required("Required"),
  street: yup
    .string()
    .min(3, "Minum 3 caractere")
    .max(50, "Maxim 50 caractere")
    .required("Required"),
  streetNumber: yup.number().required("Required"),
  zipCode: yup.string().required("Required"),
  country: yup.string().required("Required"),
  county: yup.string().required("Required"),
  city: yup.string().required("Required"),
});
