import { Field } from "react-final-form";

const ErrorField = ({ name }) => (
  <Field
    name={name}
    subscription={{ touched: true, error: true }}
    render={({ meta: { touched, error, submitError } }) => {
      return touched && (error || submitError) ? (
        <span className="text-danger ml-1">{error || submitError}</span>
      ) : null;
    }}
  />
);

export default ErrorField;