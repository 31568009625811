import axios from "axios";
import qs from "qs";
import { request, requestWithoutAuth } from "../constants/axios-warpers";
import { resetState } from "../slice/userSlice";
import { store } from "../store/store";

//set user
const getUser = async () => {
  const response = await request.get(process.env.REACT_APP_API_URL + "/me");

  return response.data;
};
//request access
const requestAccess = async (registerData) => {
  const response = await requestWithoutAuth.post(
    process.env.REACT_APP_API_URL + "/register_user",
    registerData
  );

  return response.data;
};

//register user
const register = async (userData) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "register",
    userData
  );

  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }

  return response.data;
};
//reset password
const resetPassword = async (user) => {
  const response = await requestWithoutAuth.post(
    "users/password/reset",
    qs.stringify({
      email: user.email,
    })
  );

  return response.data;
};

const resetPasswordWithToken = async (data) => {
  const response = await requestWithoutAuth.post(
    `users/password/reset/confirm/${data?.token}`,
    qs.stringify({
      confirm_password_reset: {
        plainPassword: { first: data.first, second: data.second },
      },
    })
  );

  return response.data;
};

//login user
const login = async (userData) => {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + "/login_check",
    qs.stringify({
      _email: userData.email,
      _password: userData.password,
    })
  );

  if (response.data) {
    localStorage.setItem("OncoUserToken", JSON.stringify(response.data.token));
  }

  return response.data;
};
//fetch users(medici)
const fetchingUsers = async (filters = {}, allHospitals = false) => {
  let searchInput = false;
  if (filters.search) {
    delete filters.search;
    searchInput = true;
  }
  const selectedHospital = localStorage.getItem("selected-hospital");

  if (selectedHospital && !allHospitals) {
    filters["states.hospital"] = selectedHospital;
  }

  const response = await request.get("/users?" + qs.stringify(filters));

  return response.data["hydra:member"];
};
const inactiveUsersRequest = async (hospitalId) => {
  const response = await request.get(
    `/users?hospitalsRequests.id=/api/v1/hospitals/${hospitalId}`
  );

  return response.data["hydra:member"];
};

// fetching user Input
const fetchingUsersInput = async (filters = {}, allHospitals = false) => {
  let searchInput = false;
  if (filters.search) {
    delete filters.search;
    searchInput = true;
  }
  const selectedHospital = localStorage.getItem("selected-hospital");

  if (selectedHospital && !allHospitals) {
    filters["states.hospital"] = selectedHospital;
  }

  const response = await request.get("/users?" + qs.stringify(filters));

  return response.data["hydra:member"];
};
//search results users
const fetchingResults = async (filters = {}, allHospitals = false) => {
  let searchInput = false;
  if (filters.search) {
    delete filters.search;
    searchInput = true;
  }
  const selectedHospital = localStorage.getItem("selected-hospital");

  if (selectedHospital && !allHospitals) {
    filters["states.hospital"] = selectedHospital;
  }

  const response = await request.get("/users?" + qs.stringify(filters));

  return response.data["hydra:member"];
};

//fetch single user

const fetchSingleUser = async (userId) => {
  const response = await request.get("users/" + userId);

  return response.data;
};

const fetchSupervisors = async (filters = {}) => {
  filters["states.roles"] = "ROLE_SUPERVISOR";
  const selectedHospital = localStorage.getItem("selected-hospital");

  if (selectedHospital) {
    filters["states.hospital"] = selectedHospital;
  }
  const response = await request.get("users?" + qs.stringify(filters));
  return response.data["hydra:member"];
};
//add user
const addUser = async (form) => {
  const response = await request.post("users", form);

  return response.data;
};

//edit user
const editUser = async (formPayload, id) => {
  const response = await request.post("users/" + id, formPayload);

  return response.data;
};

//fetching specialization
const fetchingSpecializations = async () => {
  const response = await request.get("specializations");

  return response.data["hydra:member"];
};
//fetching Titles
const fetchingTitles = async () => {
  const response = await request.get("titles");
  return response.data["hydra:member"];
};
//add user state
const addUserState = async (form) => {
  const response = await request.post("states", form);

  return response.data;
};
//user State
const fetchingUserState = async (stateId) => {
  const response = await request.get("states/" + stateId);

  return response.data;
};
//edit user State
const editUserState = async (form, id) => {
  const response = await request.put("states/" + id, form);

  return response.data;
};

//logout user
const logout = () => {
  store?.dispatch(resetState());
  localStorage.removeItem("OncoUserToken");
  localStorage.removeItem("selected-state");
  localStorage.removeItem("selected-hospital");
  localStorage.removeItem("switch-user-email");
  localStorage.removeItem("regId");
  localStorage.removeItem("regName");
  localStorage.clear();
};
const userApiService = {
  register,
  logout,
  login,
  getUser,
  addUser,
  editUser,
  fetchingUsers,
  fetchSingleUser,
  fetchSupervisors,
  fetchingSpecializations,
  fetchingTitles,
  fetchingResults,
  addUserState,
  fetchingUserState,
  editUserState,
  resetPassword,
  fetchingUsersInput,
  inactiveUsersRequest,
  requestAccess,
  resetPasswordWithToken,
};

export default userApiService;
