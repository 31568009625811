import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import PermissionsGate from "../../security/permisionGate";
import { canEditUser } from "../../security/customPermission";

const EditUserButton = (props) => {
  return (
    <PermissionsGate customPermissionFn={canEditUser}>
      <div className={props.containerClass}>
        <Link
          to={"/users/" + props.userId + "/edit"}
          className={props.buttonClass}
        >
          Editeaza Medicul
        </Link>
      </div>
    </PermissionsGate>
  );
};

EditUserButton.propTypes = {
  buttonClass: PropTypes.string.isRequired,
};

EditUserButton.defaultProps = {
  containerClass: "col-sm-3 col-md-3 button-container",
};

export default EditUserButton;
