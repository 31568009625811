import React from "react";
import Table from "../../../components/table/Table";
import { patientColumns } from "../data/columns";
import { patientFormsColumns } from "../data/columns";

const PatientsList = (props) => {
  const { setFilters } = props || {};

  const handleSort = (column, order) => {
    const filters = structuredClone(props.filters);

    Object.keys(filters || {}).forEach((filter) => {
      if (typeof filter === "string" && filter?.startsWith("order"))
        delete filters[filter];
    });

    const parametersFilters =
      column === "fullName"
        ? {
            "order[firstName]": order,
            "order[lastName]": order,
          }
        : {
            ["order[" + column + "]"]: order,
          };

    setFilters({ ...parametersFilters, ...filters });
  };

  return (
    <div className="container">
      <Table
        columns={props.forms ? patientFormsColumns : patientColumns}
        data={props?.patients}
        clsName="patient-table"
        onSort={handleSort}
        //expandedData={props?.patients}
      />
    </div>
  );
};

export default PatientsList;
