import React from "react";

const saveUserButton = () => {
  return (
    <div className="col-sm-3 col-md-3 col-xs-3 button-container show">
      <button form="user-form" className="green-button">
        Salveaza
      </button>
    </div>
  );
};

export default saveUserButton;
