import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getOnePatient } from "../../../slice/patientSlice";
import NavigationBar from "../../../components/common/navigation-bar";
import EditPatientButton from "../../../components/Buttons/edit-patient-button";
import ModalPopup from "../../../components/common/modal-popup";
import { useDetermineRouteByRole } from "../../../security/useDetermineRouteByRole";

const PatientInfo = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { viewedPatient } = useSelector((state) => state.patients);
  const { modalPopup } = useSelector((state) => state.users);

  const redirectTo = useDetermineRouteByRole({
    role: "ROLE_SUPPORT_MEDIC",
    route: "/all-support-request",
    defaultRoute: "/patients-list/active",
  });

  useEffect(() => {
    dispatch(getOnePatient(params.id));
  }, []);

  const getTitle = () => {
    return (
      (viewedPatient?.firstName || "") + " " + (viewedPatient?.lastName || "")
    );
  };

  const renderAddress = () => {
    if (
      !viewedPatient?.street &&
      !viewedPatient?.buildingNumber &&
      !viewedPatient?.streetNumber &&
      !viewedPatient?.apartmentNumber &&
      !viewedPatient?.city
    ) {
      return "-";
    }

    return (
      (viewedPatient.street ? "str. " + viewedPatient.street : "") +
      (viewedPatient.streetNumber
        ? " nr. " + viewedPatient.streetNumber
        : " ") +
      (viewedPatient.buildingNumber
        ? " bloc " + viewedPatient.buildingNumber
        : " ") +
      (viewedPatient.apartmentNumber
        ? " ap. " + viewedPatient.apartmentNumber
        : " ") +
      " " +
      (viewedPatient.city || "")
    );
  };

  return (
    <>
      <NavigationBar
        title={getTitle()}
        actionComponent={
          <EditPatientButton
            buttonClass="green-link"
            patientId={viewedPatient.id}
          />
        }
        backText="Lista"
        backAction={redirectTo}
      />
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 details-tabs text-center">
            <div className="active">Informatii</div>
            <div>
              <Link to={`/patients-list/${viewedPatient.id}/forms`}>
                Formulare
              </Link>
            </div>
            {/* <div>
              <Link to={`/patients-list/${viewedPatient.id}/monitoring`}>
                Monitorizare
              </Link>
            </div> */}
          </div>
        </div>
        <div className="row details-tab-contents">
          <div className="user-info-view container-fluid active">
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <dl className="row user-info-list">
                  <dt className="col-sm-3 col-md-3">Varsta</dt>
                  <dd className="col-sm-9 col-md-9">
                    {viewedPatient?.age ?? "-"}
                  </dd>

                  <dt className="col-sm-3 col-md-3">Judet</dt>
                  <dd className="col-sm-9 col-md-9">
                    {viewedPatient?.county ?? "-"}
                  </dd>

                  {/* <dt className="col-sm-3 col-md-3">Telefon</dt>
                  <dd className="col-sm-9 col-md-9">
                    {viewedPatient?.phone ?? "&nbsp;"}
                  </dd> */}

                  <dt className="col-sm-3 col-md-3">Adresa</dt>
                  <dd className="col-sm-9 col-md-9">{renderAddress()}</dd>

                  <dt className="col-sm-3 col-md-3">Medic curant</dt>
                  <dd className="col-sm-9 col-md-9">
                    {viewedPatient?.patientCases?.[0]?.supervisor
                      ? viewedPatient?.patientCases?.[0]?.supervisor
                          ?.firstName +
                        " " +
                        viewedPatient?.patientCases?.[0]?.supervisor?.lastName
                      : "-"}
                  </dd>

                  <dt className="col-sm-3 col-md-3">Medic rezident</dt>
                  <dd className="col-sm-9 col-md-9">
                    {viewedPatient?.patientCases?.[0]?.resident
                      ? viewedPatient?.patientCases?.[0]?.resident?.firstName +
                        " " +
                        viewedPatient?.patientCases?.[0]?.resident?.lastName
                      : "-"}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalPopup?.show && <ModalPopup content={modalPopup?.content} />}
    </>
  );
};
export default PatientInfo;
