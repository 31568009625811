import React from "react";
import ListActionsDropdown from "../../../components/common/list-actions-dropdown";
import { Link } from "react-router-dom";
import {
  AddAccessLink,
  EditUserLink,
  ImpersonateLink,
} from "../../../components/common/list-items";
import { extractUserFunction } from "./helpers";

export const userColumns = [
  {
    title: "Nume",
    sortKey: "fullName",
    key: "fullName",
    sortable: true,
    renderFn: (value, item) => (
      <Link
        className="title-link"
        to={"/users/" + item?.id}
      >{`${item.firstName} ${item.lastName}`}</Link>
    ),
  },
  {
    title: "Asociatie",
    key: "states",
    sortKey: "states.hospital.name",
    sortable: true,
    renderFn: (value, user) =>
      value?.map((item, i) => (
        <div key={"state_" + i + "_id_" + user.id}>
          {item.hospital ? item.hospital.name : ""}
        </div>
      )),
  },
  {
    title: "Oras",
    key: "states",
    sortKey: "states.hospital.city",
    sortable: true,
    renderFn: (value) =>
      value?.map((item, i) => <div key={i}>{item?.hospital?.city}</div>),
  },
  {
    title: "Functie",
    sortKey: "states.title.name",
    key: "states",
    sortable: true,
    renderFn: (states, user) =>
      states.map((state, i) => {
        return (
          <div key={"state_" + i + "_id_" + user.id}>{extractUserFunction(state)}</div>
        );
      }),
  },
  {
    title: "Actiuni",
    key: "actions",
    sortable: false,
    renderFn: (value, user, props) => {
      return (
        <ListActionsDropdown>
          <Link className="menu-item" to={"/users/" + user.id}>
            Informatii medic
          </Link>
          <AddAccessLink user={user} />
          <EditUserLink user={user} />
          <ImpersonateLink impersonate={props.impersonate} user={user} />
        </ListActionsDropdown>
      );
    },
  },
];
