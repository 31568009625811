export const mapResponse = (response) => {
  return response?.map((item) => {
    return {
      value: item["@id"],
      label: item.name,
    };
  });
};

export const selectedHospitalUsers = (selectedHospial, list) => {
  const newList = [];

  if (list) {
    list.forEach((listItem) => {
      const states = listItem.states.filter((state) => {
        return state.hospital["@id"] == selectedHospial;
      });

      if (states.length > 0) {
        newList.push(listItem);
      }
    });
  }

  return newList;
};

export const mapResponseCustom = (response) => {
  return response?.map((item) => {
    return {
      value: item["@id"],
      label: item.lastName + " " + item.firstName,
    };
  });
};

export const mapResponseCustomStates = (response, selectedHospital) => {
  return response
    ?.reduce((acc, curr, index) => {
      const filteredStates = curr.states.filter(
        (state) => state.hospital?.["@id"] === selectedHospital?.value
      );
      return [...acc, { ...curr, states: filteredStates }];
    }, [])
    ?.map((item) => {
      return {
        value: item?.states?.[0]?.["@id"],
        label: item.lastName + " " + item.firstName,
      };
    });
};

export const mapResponseObject = (response) => {
  return {
    value: response["@id"],
    label: response?.name,
  };
};
export const mapResponseSecondObject = (user, userState) => {
  return {
    value: userState?.["@id"],
    label: user?.lastName + " " + user.firstName,
  };
};

export const mapResponseObjectCustom = (response) => {
  return {
    value: response["@id"],
    label: response.lastName + " " + response.firstName,
  };
};

export const mapResponseErrorObject = (errResponse) => {
  let mapError = {};
  errResponse?.forEach((err) => {
    const { propertyPath, message } = err ?? {};
    if (mapError[propertyPath]) {
      mapError[propertyPath] = mapError[propertyPath] + " " + message;
    } else {
      mapError[propertyPath] = message;
    }
  });

  return mapError;
};

export const usersDefaultValues = ({ isInitialValuesEmpty, selectedState, role, userOptions }) => {
  let defaultValue;
  
  if (isInitialValuesEmpty && selectedState?.roles.includes(role)) {
    defaultValue = userOptions?.find((item) => item?.value === selectedState?.["@id"]);
  }
  return defaultValue;
}
