"use strict";

const genderOptions = [
  { value: "0", label: "M" },
  { value: "1", label: "F" },
];

const environmentOptions = [
  { value: "Rural", label: "Rural" },
  { value: "Urban", label: "Urban" },
];

const patientStatuses = [
  { value: true, label: "Activ" },
  { value: false, label: "Inactiv" },
];

const patientStudies = [
  { value: "1", label: "Studii Primare 1-4" },
  { value: "2", label: "Studii Gimnaziale 5-8" },
  { value: "3", label: "Studii Liceale 9-12" },
  { value: "4", label: "Studii PostLiceale 13-14" },
  { value: "5", label: "Studii Superioare - Facultate" },
];

const patientEthnicGroup = [
  { value: "1", label: "Roman/Romana" },
  { value: "2", label: "Maghiari" },
  { value: "3", label: "Romi" },
  { value: "4", label: "Ucraineni" },
  { value: "5", label: "Germani" },
  { value: "6", label: "Rusi-Lipoveni" },
  { value: "7", label: "Turci" },
  { value: "8", label: "Tatari" },
  { value: "9", label: "Sarbi" },
  { value: "10", label: "Slovaci" },
  { value: "11", label: "Bulgari" },
  { value: "12", label: "Croati" },
  { value: "13", label: "Greci" },
  { value: "14", label: "Evrei" },
  { value: "15", label: "Cehi" },
  { value: "16", label: "Polonezi" },
  { value: "17", label: "Italieni" },
  { value: "18", label: "Chinezi" },
  { value: "19", label: "Armeni" },
  { value: "20", label: "Ceangai" },
  { value: "21", label: "Macedoneni" },
  { value: "22", label: "Alta Etnie" },
];

export {
  genderOptions,
  environmentOptions,
  patientStatuses,
  patientStudies,
  patientEthnicGroup,
};
