import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import userApiService from "../api/userApiService";
import initialState from "../constants/initialState";
import { mapResponse } from "../helpers/response-map-functions";

export const selectState = createAction(
  "auth/SELECT_STATE",
  (state, stateIndex) => {
    return {
      payload: {
        state: state,
        stateIndex: stateIndex,
      },
    };
  }
);

export const impersonate = createAction("auth/IMPERSONATE", (user) => {
  return {
    payload: user.email,
  };
});

export const login = createAsyncThunk("auth/LOGIN", async (user, thunkAPI) => {
  try {
    return await userApiService.login(user);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const requestAplicationAccess = createAsyncThunk(
  "auth/REQUEST_ACCESS",
  async (data, thunkAPI) => {
    try {
      return await userApiService.requestAccess(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const resetPWD = createAsyncThunk(
  "auth/PWD_RESET",
  async (data, thunkAPI) => {
    try {
      return await userApiService.resetPassword(data);
    } catch (error) {
      const violations = error?.response?.data?.violations || [];
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message, violations });
    }
  }
);

export const resetPWDwithToken = createAsyncThunk(
  "auth/PWD_RESET_TOKEN",
  async (data, thunkAPI) => {
    try {
      return await userApiService.resetPasswordWithToken(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const setUser = createAsyncThunk(
  "auth/SET_USER",
  async (_, thunkAPI) => {
    try {
      return await userApiService.getUser();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchUserInput = createAsyncThunk(
  "auth/FETCH_USER_INPUT",
  async (data, thunkAPI) => {
    try {
      return await userApiService.fetchingUsersInput(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchUsers = createAsyncThunk(
  "auth/FETCH_USERS",
  async (data, thunkAPI) => {
    try {
      return await userApiService.fetchingUsers(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchOne = createAsyncThunk(
  "auth/FETCH_ONE",
  async (data, thunkAPI) => {
    try {
      return await userApiService.fetchSingleUser(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchSupervisorsList = createAsyncThunk(
  "auth/FETCHING_SUPERVISORS",
  async (_, thunkAPI) => {
    try {
      return await userApiService.fetchSupervisors();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const inactiveUsersList = createAsyncThunk(
  "auth/INACTIVE_REQUEST",
  async (data, thunkAPI) => {
    try {
      return await userApiService.inactiveUsersRequest(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//create user
export const createUser = createAsyncThunk(
  "auth/CREATE_USER",
  async (form, thunkAPI) => {
    try {
      return await userApiService.addUser(form);
    } catch (error) {
      const violations = error?.response?.data?.violations || [];
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({ message, violations });
    }
  }
);
//get fetch results
export const getUserResults = createAsyncThunk(
  "auth/GET_SEARCH_RESULTS",
  async (data, thunkAPI) => {
    try {
      return await userApiService.fetchingResults(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//add user state
export const createUserState = createAsyncThunk(
  "auth/CREATE_USER_STATE",
  async (form, thunkAPI) => {
    try {
      return await userApiService.addUserState(form);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateUser = createAsyncThunk(
  "auth/EDITED_USER",
  async (form, thunkAPI) => {
    try {
      const { first, second } = form;
      return await userApiService.editUser(first, second);
    } catch (error) {
      const violations = error?.response?.data?.violations || [];
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({ message, violations });
    }
  }
);
//fetching SpecializationList
export const fetchingSpecializationsList = createAsyncThunk(
  "auth/SPECIALIZATION_LIST",
  async (_, thunkAPI) => {
    try {
      return await userApiService.fetchingSpecializations();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//fetching titles
export const fetchingTitlesList = createAsyncThunk(
  "auth/FETCHING_TITLES",
  async (_, thunkAPI) => {
    try {
      return await userApiService.fetchingTitles();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
//user State

export const getUserState = createAsyncThunk(
  "auth/GET_USER_STATE",
  async (id, thunkAPI) => {
    try {
      return await userApiService.fetchingUserState(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
//update userState
export const updateUserState = createAsyncThunk(
  "auth/UPDATE_USER_STATE",
  async (data, thunkAPI) => {
    try {
      const { form, id } = data;
      return await userApiService.editUserState(form, id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//logout
export const logout = createAsyncThunk("auth/LOGOUT", async () => {
  return await userApiService.logout();
});

export const userSlice = createSlice({
  name: "auth",
  initialState: initialState.users,
  reducers: {
    resetState: () => initialState,
    reset: (state) => {
      state.isSuccess = false;
      state.isError = false;
      state.isLoading = false;
      state.message = "";
    },
    exitImpersonation: (state) => {
      localStorage.removeItem("switch-user-email");
      localStorage.removeItem("selected-state");
      localStorage.removeItem("selected-hospital");
      state.users.data = [];
      state.switchUserEmail = null;
    },
    clearUsersList: (state) => {
      state.users.data = [];
    },
    clearUsersInput: (state) => {
      state.searchInputUsers.data = null;
    },
    setPopup: (state, action) => {
      state.modalPopup = action.payload;
    },
    resetPopup: (state) => {
      state.modalPopup = initialState.users.modalPopup;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestAplicationAccess.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(requestAplicationAccess.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(requestAplicationAccess.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.loggedIn = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.loggedIn = false;
      })
      .addCase(setUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setUser.fulfilled, (state, action) => {
        const stateIndex = parseInt(
          localStorage.getItem("selected-state") || 0
        );

        if (action.payload.states.length > 0) {
          localStorage.setItem("selected-state", stateIndex);
          localStorage.setItem(
            "selected-hospital",
            action.payload.states[stateIndex]?.hospital["@id"] || null
          );
        }
        state.isLoading = false;
        state.isSucess = true;
        state.loggedIn = true;
        state.loggedInUser = action.payload;
        state.selectedState = action.payload.states[stateIndex] || null;
      })
      .addCase(setUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.loggedIn = false;
      })
      .addCase(logout.fulfilled, (state) => {
        state.loggedIn = false;
        state.isSucess = false;
        state.loggedInUser = [];
      })
      .addCase(fetchUsers.pending, (state) => {
        state.users.isLoading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.users.isLoading = false;
        state.users.isSucess = true;
        state.users.data = action.payload;
        state.viewedUser = null;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.users.isLoading = false;
        state.users.isError = true;
        state.users.message = action.payload;
      })
      .addCase(fetchOne.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchOne.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSucess = true;
        state.viewedUser = action.payload;
      })
      .addCase(fetchOne.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchSupervisorsList.pending, (state) => {
        state.supervisorsList.isLoading = true;
      })
      .addCase(fetchSupervisorsList.fulfilled, (state, action) => {
        state.supervisorsList.isLoading = false;
        state.supervisorsList.isSucess = true;
        state.supervisorsList.data = action.payload;
      })
      .addCase(fetchSupervisorsList.rejected, (state, action) => {
        state.supervisorsList.isLoading = false;
        state.supervisorsList.isError = true;
        state.supervisorsList.message = action.payload;
      })
      .addCase(createUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createUser.fulfilled, (state) => {
        state.isLoading = false;
        state.isSucess = true;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.violations = action.payload.violations;
      })
      .addCase(updateUser.pending, (state) => {
        state.users.isLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const findRec = state.users?.data?.map((user) =>
          user.id === action.payload.id ? action.payload : user
        );
        state.users.isLoading = false;
        state.users.isSucess = true;
        state.users.data = findRec;
        state.viewedUser = action.payload;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.users.isLoading = false;
        state.users.isError = true;
        state.users.message = action.payload;
      })
      .addCase(fetchingSpecializationsList.pending, (state) => {
        state.specializationsList.isLoading = true;
      })
      .addCase(fetchingSpecializationsList.fulfilled, (state, action) => {
        let specializations = action.payload;
        specializations = mapResponse(specializations);
        state.specializationsList.isLoading = false;
        state.specializationsList.isSucess = true;
        state.specializationsList.data = specializations;
      })
      .addCase(fetchingSpecializationsList.rejected, (state, action) => {
        state.specializationsList.isLoading = false;
        state.specializationsList.isError = true;
        state.specializationsList.message = action.payload;
      })
      .addCase(fetchingTitlesList.pending, (state) => {
        state.titleList.isLoading = true;
      })
      .addCase(fetchingTitlesList.fulfilled, (state, action) => {
        state.titleList.isLoading = false;
        state.titleList.isSucess = true;
        state.titleList.data = action.payload;
      })
      .addCase(fetchingTitlesList.rejected, (state, action) => {
        state.titleList.isLoading = false;
        state.titleList.isError = true;
        state.titleList.message = action.payload;
      })
      .addCase(createUserState.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createUserState.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSucess = true;
      })
      .addCase(createUserState.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUserResults.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserResults.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSucess = true;
        state.searchResultsUsers = action.payload;
      })
      .addCase(getUserResults.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUserState.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserState.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSucess = true;
        state.viewedState = action.payload;
      })
      .addCase(getUserState.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateUserState.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserState.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSucess = true;
        state.viewedState = action.payload;
      })
      .addCase(updateUserState.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(selectState, (state, action) => {
        localStorage.setItem("selected-state", action.payload.stateIndex);
        localStorage.setItem(
          "selected-hospital",
          action.payload.state.hospital["@id"]
        );
        state.selectedState = action.payload.state;
        state.users.data = [];
      })
      .addCase(impersonate, (state, action) => {
        localStorage.setItem("switch-user-email", action.payload);
        state.switchUserEmail = action.payload;
      })
      .addCase(resetPWD.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPWD.fulfilled, (state) => {
        state.isLoading = false;
        state.isSucess = true;
      })
      .addCase(resetPWD.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchUserInput.pending, (state) => {
        state.searchInputUsers.isLoading = true;
      })
      .addCase(fetchUserInput.fulfilled, (state, action) => {
        state.searchInputUsers.isLoading = false;
        state.searchInputUsers.isSucess = true;
        state.searchInputUsers.data = action.payload;
      })
      .addCase(fetchUserInput.rejected, (state, action) => {
        state.searchInputUsers.isLoading = false;
        state.searchInputUsers.isError = true;
        state.searchInputUsers.message = action.payload;
      })
      .addCase(inactiveUsersList.pending, (state) => {
        state.hospitalRequestUsers.isLoading = true;
      })
      .addCase(inactiveUsersList.fulfilled, (state, action) => {
        state.hospitalRequestUsers.isLoading = false;
        state.hospitalRequestUsers.isSucess = true;
        state.hospitalRequestUsers.data = action.payload;
      })
      .addCase(inactiveUsersList.rejected, (state, action) => {
        state.hospitalRequestUsers.isLoading = false;
        state.hospitalRequestUsers.isError = true;
        state.hospitalRequestUsers.message = action.payload;
      })
      .addCase(resetPWDwithToken.pending, (state) => {
        state.resetPWDwithToken.isLoading = true;
      })
      .addCase(resetPWDwithToken.fulfilled, (state, action) => {
        state.resetPWDwithToken.isLoading = false;
        state.resetPWDwithToken.isSucess = true;
      })
      .addCase(resetPWDwithToken.rejected, (state, action) => {
        state.resetPWDwithToken.isLoading = false;
        state.resetPWDwithToken.isError = true;
        state.resetPWDwithToken.message = action.payload;
      });
  },
});

export const {
  reset,
  exitImpersonation,
  clearUsersList,
  clearUsersInput,
  setPopup,
  resetPopup,
  resetState,
} = userSlice.actions;

export default userSlice.reducer;
