import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { SCOPES } from "../../security/permission-map";
import PermissionsGate from "../../security/permisionGate";

const EditHospitalButton = (props) => {
  return (
    <PermissionsGate scopes={[SCOPES.canCreate]}>
      <div className={props.containerClass}>
        <Link
          to={"/hospitals/" + props.hospitalId + "/edit"}
          className={props.buttonClass}
        >
          Editeaza Asociatie
        </Link>
      </div>
    </PermissionsGate>
  );
};

EditHospitalButton.propTypes = {
  buttonClass: PropTypes.string.isRequired,
};

EditHospitalButton.defaultProps = {
  containerClass: "col-sm-3 col-md-3 button-container",
};

export default EditHospitalButton;
