import axios from "axios";
import { logout } from "../slice/userSlice";
import { store } from "../store/store";
import { toast } from "react-toastify";
//import { requestStarted, requestStopped } from "../actions/userActions";
//import { redirects } from "../helpers/redirects";

export const request = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

request.interceptors.request.use(function (config) {
  const email = store.getState().users.switchUserEmail;
  if (email) {
    config.headers["X-Switch-User"] = email;
  }
  config.headers["Authorization"] = `Bearer ${JSON.parse(
    localStorage.getItem("OncoUserToken")
  )}`;

  //store.dispatch(requestStarted());
  return config;
});

request.interceptors.response.use(
  function (config) {
    //store.dispatch(requestStopped());

    return config;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      store.dispatch(logout());
      toast.error("Sesiunea dumneavostra a expirat!");
    }
    return Promise.reject(error);
  }
);

export const requestWithoutAuth = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

requestWithoutAuth.interceptors.request.use(function (config) {
  //store.dispatch(requestStarted());
  return config;
});

requestWithoutAuth.interceptors.response.use(
  function (config) {
    //store.dispatch(requestStopped());
    return config;
  },
  function (error) {
    //store.dispatch(requestStopped());

    return Promise.reject(error);
  }
);
