import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  resetPlist,
  getPatientSearchResultsFiltered,
} from "../../../slice/patientSlice";
import { Link } from "react-router-dom";
import PatientsList from "./PatientsList";
import NavigationBar from "../../../components/common/navigation-bar";
import AddPatientButton from "../../../components/Buttons/add-patient-button";
import PermissionsGate from "../../../security/permisionGate";
import { canExportPatient, canViewShowMyPatients } from "../../../security/customPermission";
import "../../../less/listing.less";
import "../../../less/tabs.less";
import SearchFieldPatients, {
  initFilters,
} from "../../../components/common/search-field-patients";
import Loader from "../../../components/Loader/loader";
import Paginator from "react-hooks-paginator";
import ExportButton from "../../../components/Buttons/ExportButton";

const PatientActiveList = () => {
  const { loggedInUser, selectedState } = useSelector((state) => state.users);
  const { data, isLoading, totalCount } = useSelector(
    (state) => state.patients.patientSearchResultsFiltered
  );
  const dispatch = useDispatch();
  const [showMyPatients, setShowMyPatients] = useState(false);

  const perPage = initFilters.perPage;
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(() => initFilters);

  useEffect(() => {
    let patientFilters = {
      ...filters,
    };

    if (loggedInUser?.roles?.indexOf("ROLE_USER") !== -1) {
      if (
        null !== selectedState &&
        selectedState?.roles?.indexOf("ROLE_FAMILY_MEDIC") !== -1
      ) {
        patientFilters["patientCases.residentState"] =
          selectedState["@id"] || 0;
      }
    }

    if (filters.page !== page) {
      setFilters((prev) => ({ ...prev, page }));
    } else {
      dispatch(
        getPatientSearchResultsFiltered({ ...patientFilters, active: true })
      );
    }

    return () => {
      dispatch(resetPlist());
    };
  }, [page, filters]);

  const onShowMyPatients = (e) => {
    const isChecked = e.target.checked;
    setShowMyPatients(isChecked);
  };

  return (
    <>
      <NavigationBar
        title={"Pacienti"}
        actionComponent={[
          <AddPatientButton
            buttonClass="green-link"
            containerClass={"button-container show"}
          />,
          <PermissionsGate customPermissionFn={canExportPatient}>
            <ExportButton containerClass="row mr-10" />,
          </PermissionsGate>,
        ]}
      />

      <div className="container-fluid">
        <div className="container">
          <Loader loading={isLoading} />
          <div className="row">
            <div className="col-sm-12 col-md-12 details-tabs text-center">
              <div className="active">Pacienti activi</div>
              <div>
                <Link to={"../inactive"}>Pacienti inactivi</Link>
              </div>
            </div>
          </div>
          <SearchFieldPatients
            filters={filters}
            setFilters={setFilters}
            setPage={setPage}
          />
          <MyPatientsButton showMyPatients={onShowMyPatients} />
        </div>
        <PatientsList
          patients={data}
          setFilters={setFilters}
          filters={filters}
        />
        {data?.length === 0 && !isLoading && (
          <h4 className="text-center">Nu s-a gasit niciun pacient</h4>
        )}
        <div className="text-center">
          <Paginator
            totalRecords={totalCount ?? 1}
            pageLimit={perPage}
            pageNeighbours={1}
            setOffset={setOffset}
            currentPage={page}
            setCurrentPage={setPage}
          />
        </div>
      </div>
    </>
  );
};

export default PatientActiveList;

const MyPatientsButton = (props) => {
  return (
    <PermissionsGate customPermissionFn={canViewShowMyPatients}>
      <div className="row">
        <div className="col-sm-12 col-md-12">
          <input
            type="checkbox"
            id="myPatients"
            name="myPatients"
            onChange={props.showMyPatients}
          />
          <label htmlFor="myPatients">Arata doar pacientii mei</label>
        </div>
      </div>
    </PermissionsGate>
  );
};
