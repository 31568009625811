import React from "react";
import { Link } from "react-router-dom";
import ListingUsers from "./UsersList";
import { useSelector } from "react-redux";

const UsersActiveList = () => {
  const { data: users } = useSelector((state) => state.users.users);
  let activeUsers = users?.filter((user) => user.states.length > 0);
  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 details-tabs text-center">
            <div className="active">Medici activi</div>
            <div>
              <Link to={"/users-list/inactive"}>Medici inactivi</Link>
            </div>
          </div>
        </div>
      </div>

      <ListingUsers users={activeUsers} />
    </div>
  );
};

export default UsersActiveList;
