"use strict";

import React from "react";
import PatientForm from "../forms/Patient";
import {createPatient, postPatientCase} from "../../../slice/patientSlice";
import Header from "../../../components/common/header";
import {useLocation} from "react-router-dom";

const AddPatient = () => {
  const location = useLocation()
  return (
      <>
        <Header/>
        <PatientForm cnp={location?.state?.patientCnp} title={"Adauga Pacient"} submitAction={createPatient}
                     submitSecondAction={postPatientCase}/>
      </>
  );
};

AddPatient.propTypes = {};

export default AddPatient;
