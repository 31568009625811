import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TopUser from "../../../components/common/user-dropdown";
import Loader from "../../../components/Loader/loader";
import { Logo } from "../../../components/Login/common";
import SearchField from "../../../components/common/search-field";
import HospitalSwitcher from "../../../components/common/hospital-switcher";
import HomeElement from "../../../components/home/homeElement";
import Paginator from "react-hooks-paginator";
import "../../../less/home.less";
import HeaderNotifications from "../../../components/common/header-notifications";

const Home = () => {
  const { isLoading } = useSelector((state) => state.users);
  const { data: RegData, isLoading: isLoadingReg } = useSelector(
    (state) => state?.registries?.userRegistries
  );

  const pageLimit = 5;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState([]);

  useEffect(() => {
    setData(RegData);
  }, [RegData]);

  useEffect(() => {
    setCurrentData(data?.slice(offset, offset + pageLimit));
  }, [offset, data]);

  return (
    <div className="home">
      <Loader loading={isLoading || isLoadingReg} />
      <div className="top">
        <div className="top-bar">
          <Logo />
          <HospitalSwitcher />
          <TopUser />
          <HeaderNotifications />
        </div>
        <div className="top-bar-responsive">
          <div className="first-section">
            <Logo />
            <HeaderNotifications />
          </div>
          <HospitalSwitcher />
          <TopUser />
        </div>

        <div className="col-md-12 col-sm-12">
          <h1>
            Welcome to <span> BeLiverApp</span>
          </h1>
        </div>
      </div>
      <div className="container" style={{ minHeight: "50vh" }}>
        <div className="row row-flex">
          {RegData?.length <= 0 && <div> No registries</div>}
          {currentData?.map((item) => (
            <HomeElement key={item.id} item={item} />
          ))}
        </div>
      </div>
      <Paginator
        totalRecords={data?.length || 0}
        pageLimit={pageLimit}
        pageNeighbours={2}
        setOffset={setOffset}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default Home;
