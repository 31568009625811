import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { fetchImage } from "../../../helpers/image-fetcher";
import NavigationBar from "../../../components/common/navigation-bar";
import EditUserButton from "../../../components/Buttons/edit-user-button";
import { useParams } from "react-router-dom";
import { fetchOne, reset } from "../../../slice/userSlice";
import Header from "../../../components/common/header";
import Loader from "../../../components/Loader/loader";
import "../../../less/user-info.less";
import "../../../less/tabs.less";
import { extractUserFunction } from "../data/helpers";

const UserInfo = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { viewedUser, isLoading } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(fetchOne(params.id));
    return () => {
      dispatch(reset());
    };
  }, []);

  const getAvatar = () => fetchImage("user", viewedUser?.avatarFilePath);

  const getTitle = () =>
    `${viewedUser?.firstName || ""} ${viewedUser?.lastName || ""}`;
  return (
    <div>
      <Header />
      <NavigationBar
        title={getTitle()}
        action="edit-user"
        actionComponent={
          <EditUserButton buttonClass="green-link" userId={viewedUser?.id} />
        }
        backAction="/users-list/active"
        backText="Lista"
      />
      <Loader loading={isLoading} />
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 details-tabs text-center">
            <div className="active">Informatii</div>
            <div>
              <Link to={"/users/" + viewedUser?.id + "/access-history"}>
                Istoric acces
              </Link>
            </div>
            <div>
              <Link to={"/users/" + viewedUser?.id + "/notifications"}>
                Notificari
              </Link>
            </div>
          </div>
        </div>
        <div className="row details-tab-contents">
          <div className="user-info-view container-fluid active">
            <div className="row">
              <div className="col-sm-3 col-md-3 avatar-container">
                <img src={getAvatar()} alt="avatar" />
              </div>
              <div className="col-sm-9 col-md-9">
                <dl className="row user-info-list">
                  <dt className="col-sm-12 col-md-12 user-info-header">
                    Date de contact
                  </dt>

                  <dt className="col-sm-3 col-md-3">Telefon</dt>
                  <dd className="col-sm-9 col-md-9">
                    {viewedUser?.phone || "-"}
                  </dd>

                  <dt className="col-sm-3 col-md-3">Email</dt>
                  <dd className="col-sm-9 col-md-9">
                    {viewedUser?.email || "&nbsp;"}
                  </dd>

                  <dt className="col-sm-12 col-md-12 user-info-header">
                    Informatii generale
                  </dt>

                  <dt className="col-sm-3 col-md-3">Supervizor</dt>
                  <dd
                    dangerouslySetInnerHTML={{
                      __html:
                        viewedUser?.states?.length > 0
                          ? viewedUser.states
                              .map((state) => {
                                return state.supervisor
                                  ? state.supervisor.firstName +
                                      state.supervisor.lastName
                                  : "-";
                              })
                              .join("<br>")
                          : "-",
                    }}
                    className="col-sm-9 col-md-9"
                  />

                  <div className="user-info-role col-sm-6">
                    <div className="user-info-row">
                      <div className="user-info">
                        <dt className="">Asociatie</dt>
                      </div>
                      <div className="user-info">
                        <dt className="">Functie</dt>
                      </div>
                    </div>
                    {viewedUser?.states?.map((state, index) => {
                      return (
                        <div key={index} className="user-info-row">
                          <div className="user-info">
                            <span>{state?.hospital?.name}</span>
                          </div>
                          <div className="user-info">
                            <span>{extractUserFunction(state)}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
