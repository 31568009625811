import { request } from "../constants/axios-warpers";

const readNotificationRequest = async (notificationId) => {
  const respone = await request.put("notifications/" + notificationId, {
    isRead: true,
  });

  return respone.data;
};

const notificationApiService = {
  readNotificationRequest,
};

export default notificationApiService;
