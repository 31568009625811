import React, { useEffect, useState } from "react";
import { Logo } from "../../../components/Login/common";
import { FooterLinks } from "../../../components/Login/common";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/loader";
import { toast } from "react-toastify";
import { reset, resetPWD } from "../../../slice/userSlice";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, isSucess, isError, message } = useSelector(
    (state) => state.users
  );

  const [value, setValue] = useState({
    email: "",
  });

  const handleChange = (e) => {
    let value = e.target.value,
      name = e.target.name;

    setValue({
      ...value.email,
      [name]: value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(resetPWD(value));
  };

  useEffect(() => {
    if (isSucess) {
      toast.success("Email-ul a fost trimis");
      navigate("/login");
    }
    if (isError && message?.violations?.length > 0) {
      message?.violations?.forEach((err) => toast.error(err?.message));
    }

    if (isError && message?.violations?.length < 0) {
      toast.error(message?.message);
    }
    return () => {
      dispatch(reset());
    };
  }, [isSucess, isError, message, dispatch]);

  return (
    <div>
      <div className="container login-container">
        <Loader loading={isLoading} />
        <div className="row">
          <Logo />
          <div className="col-sm-12 col-md-12">
            <p>Reset your password:</p>
          </div>
          <div className="col-sm-12 col-md-12 login-form">
            <form>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  value={value.email}
                  className="form-control"
                />
              </div>
              <button className="btn btn-default" onClick={onSubmit}>
                Send reset email
              </button>
            </form>
          </div>
        </div>
        <FooterLinks />
      </div>
    </div>
  );
};

export default ForgotPassword;
