/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import ModalWithPortal from "../common/modal-portal";
import { useDispatch, useSelector } from "react-redux";
import { fetchFormTypes, getMonitoringTemplates } from "../../slice/formSlice";
import { downloadPatientData } from "../../slice/formSlice";
import { Form, Field } from "react-final-form";
import { ReactSelectAdapter } from "../forms/Adapter/adapter";
import Loader from "../Loader/loader";
import { toast } from "react-toastify";
import ErrorField from "../forms/ErrorField";
import { validateExportFields } from "../../modules/patients/data/helpers";

const ExportButton = (props) => {
  const { formTypes } = useSelector((state) => state?.patientForms);
  const { data: monitoringTemplates, isLoading } = useSelector(
    (state) => state.patientForms.monitoringTemplates
  );

  const buildInternmentOptions = (patientFormTypes) => {
    return patientFormTypes?.map((form) => {
      return {
        value: form?.id,
        label: form?.name,
      };
    });
  };

  const dispatch = useDispatch();
  const downloadLinkRef = useRef();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const regId = localStorage.getItem("regId");
    if (!open) return;
    dispatch(getMonitoringTemplates(regId));
    dispatch(fetchFormTypes(regId));
  }, [open]);

  const downloadCSV = (csvString) => {
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    downloadLinkRef.current.href = url;
    downloadLinkRef.current.download = "lista.csv";
    downloadLinkRef.current.click();
    URL.revokeObjectURL(url);
  };

  const onSubmit = async (data) => {
    try {
      const result = await dispatch(downloadPatientData(data)).unwrap();
      downloadCSV(result);
      setOpen(false);
      toast.success("Exportul s-a efectuat cu success");
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <ModalWithPortal
      buttonText="Exporta pacienti"
      modalState={open}
      handleShowModal={setOpen}
      cssClasses={"green-link mt-10 mr-10"}
    >
      <div className={props.containerClass}>
        <div className="popup responsive">
          {isLoading && <Loader loading={isLoading}/>}
          <p>Continua pentru a exporta toti pacientii.</p>
          <div className="container-fluid">
            <div className="form-container">
              <Form
                onSubmit={onSubmit}
                validate={validateExportFields}
                mutators={{
                  setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value);
                  },
                }}
                render={({ handleSubmit, form }) => (
                  <form autoComplete="off" onSubmit={handleSubmit}>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label className="form-label">
                          Template screening &#42;
                        </label>
                        <Field
                          name="screening"
                          options={buildInternmentOptions(formTypes)}
                          render={(props) => {
                            return (
                              <ReactSelectAdapter
                                {...props}
                                onChange={(value) => {
                                  props.input.onChange(value);
                                }}
                              />
                            );
                          }}
                        />
                        <ErrorField name="screening" />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label className="form-label">
                          Template specialitate &#42;
                        </label>
                        <Field
                          name="specialitate"
                          component={ReactSelectAdapter}
                          options={buildInternmentOptions(monitoringTemplates)}
                          render={(props) => {
                            return (
                              <ReactSelectAdapter
                                {...props}
                                onChange={(value) => {
                                  props.input.onChange(value);
                                }}
                              />
                            );
                          }}
                        />
                        <ErrorField name="specialitate" />
                      </div>
                    </div>
                    <div
                      className="actions-container"
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <button
                        className="green-link"
                        onClick={() => setOpen(false)}
                      >
                        Inapoi
                      </button>
                      <button className="green-link" type="submit">
                        Continua
                      </button>
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="popup-bg" onClick={() => setOpen(false)}></div>
      <a href="#" ref={downloadLinkRef} style={{ display: "none" }}>
        {" "}
      </a>
    </ModalWithPortal>
  );
};

export default ExportButton;

ExportButton.propTypes = {
  containerClass: PropTypes.string,
};

ExportButton.defaultProps = {
  containerClass: " button-container ",
  //col-sm-3 col-md-3 col-xs-3 show
};
