import React, { useState } from "react";
import Header from "../../../components/common/header";
import NavigationBar from "../../../components/common/navigation-bar";
import CnpButton from "../../../components/Buttons/cnp-button";
import { Link, useNavigate } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { validateStep1 } from "../data/helpers";
import { verifyPatientCnp } from "../../../slice/patientSlice";
import Loader from "../../../components/Loader/loader";
import { useDispatch, useSelector } from "react-redux";
import { FORM_ERROR } from "final-form";
import { setPopup } from "../../../slice/userSlice";
import ErrorField from "../../../components/forms/ErrorField";
import { generateRandomCNP } from "../data/helpers";

const AddPatientStep1 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.patients.saved_cnp);
  const { id } = useSelector(
    (state) => state.users?.selectedState?.hospital ?? {}
  );
  const [withoutCnp, setWithoutCnp] = useState(null);
  const [randomCnp, setRandomCnp] = useState(null);

  const handleWithoutCnp = () => {
    setRandomCnp(generateRandomCNP());
    setWithoutCnp(true);
  };

  const onSubmit = async (values) => {
    const pathData = {
      cnp: values?.cnp,
      registry: localStorage.getItem("regId"),
      hospital: id,
    };
    try {
      const response = await dispatch(verifyPatientCnp(pathData)).unwrap();
      if (response) {
        if (!response?.patient && !response?.case) {
          dispatch(
            setPopup({
              show: true,
              content: "Pacientul nu exista in baza de date.",
            })
          );
          navigate(`/add-patient/step2`, {
            state: { patientCnp: values?.cnp },
          });
        } else if (response.patient && response.case) {
          dispatch(
            setPopup({
              show: true,
              content:
                "Pacientul are deja caz un deschis. Ati fost redirectionat catre Pacient.",
            })
          );
          navigate(`/patients-list/${response?.patient?.id}`);
        } else {
          dispatch(
            setPopup({
              show: true,
              content:
                "Pacientul exista deja in baza de date, datele au fost completate",
            })
          );
          const { patientCases, ...patientInfo } = response?.patient;
          navigate(`/patients-list/${response?.patient?.id}/edit`, {
            state: { patient: patientInfo },
          });
        }
      }
    } catch (error) {
      return { [FORM_ERROR]: `${error}` };
    }
  };
  return (
    <>
      <Header />
      <NavigationBar
        title="Verifica CNP"
        actionComponent={
          <CnpButton
            buttonClass="green-link"
            //patientId={props.initialValues?.id || ""}
          />
        }
        action="save"
        backAction={"/patients-list/active"}
      />
      <Loader loading={isLoading} />
      <div className="container-fluid">
        <div className="container forms-container">
          <Form
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
              clear: ([cnp], state, { changeValue }) => {
                changeValue(state, "cnp", () => undefined);
              },
            }}
            onSubmit={onSubmit}
            validate={withoutCnp ? () => {} : validateStep1}
            //initialValues={props.initialValues}

            keepDirtyOnReinitialize
            render={({ handleSubmit, form, submitError }) => (
              <form id="cnp-form" autoComplete="off" onSubmit={handleSubmit}>
                <div className="col-sm-12 col-md-12 inner-form no-avatar-forms">
                  <div className="fields-container">
                    <div
                      className="row"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div className="col-md-6 col-sm-12 ">
                        <div className="form-group">
                          <label className="form-label">
                            Verifica CNP &#42;
                          </label>
                          <Field
                            name="cnp"
                            component="input"
                            className="input-fields"
                            initialValue={randomCnp}
                            type="number"
                          />
                          <ErrorField name="cnp" />
                          {submitError && (
                            <div className="text-danger ml-1">
                              {submitError}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="bottom-actions-container centered-actions"
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <Link to="/patients-list/active" className="back-link">
                    Inapoi
                  </Link>
                  <input
                    type="submit"
                    className="green-button"
                    value="Verifica CNP"
                    onClick={() => setWithoutCnp(false)}
                  />
                  <input
                    type="submit"
                    className="green-button"
                    value="Fara CNP"
                    onClick={() => {
                      form.mutators.clear();
                      handleWithoutCnp();
                    }}
                  />
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default AddPatientStep1;
