import React from "react";

const SaveHospitalButton = (props) => {
  return (
    <div className="col-sm-3 col-md-3 button-container show">
      <button form="hospital-form" className="green-button">
        Salveaza
      </button>
    </div>
  );
};

export default SaveHospitalButton;
