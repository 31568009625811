import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import {createUser, reset,
    createUserState,
    fetchOne,} from "../../../slice/userSlice";
import {useNavigate, Link}  from "react-router-dom";
import {getFormErrors} from "../../../helpers/validate-form";
import NavigationBar from "../../../components/common/navigation-bar";
import Loader from "../../../components/Loader/loader";
import SaveUserButton from "../../../components/Buttons/save-user-button";
import {toast} from "react-toastify";
import "../../../less/forms.less";
import Header from "../../../components/common/header";
import {getUserSchema} from "../data/helpers";
import { mapResponse, mapResponseErrorObject} from "../../../helpers/response-map-functions";

import format from "date-fns/format";
import { resetHslice} from "../../../slice/hospitalSlice";
import {Form} from "react-final-form";
import "../../../less/datepicker.less";
import "react-datepicker/dist/react-datepicker.css";
import "../../../less/forms.less";
import { rolesListCaravana, rolesListTelemedicina } from "../../../modules/users/data/helpers";
import useGetUserData from "../hooks/useGetUserData";
import UserFormWithState from "../forms/UserFormWIthState";


const AddUserWithState = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    selectedState,
    loggedInUser,
    supervisorsList,
    hospitals,
    isLoading,
  } = useGetUserData();

const [hospitalsState, setHospitalsStateNew] = useState();
const [hospitalType, setHospitalType] = useState()
const hospitalOptions = mapResponse(hospitalsState);

useEffect(() => {
if (loggedInUser?.roles.indexOf("ROLE_SUPER_ADMIN") !== -1) {
  setHospitalsStateNew([...hospitals]);
} else {
  setHospitalsStateNew([selectedState?.hospital]);
}
return () => {
    dispatch(reset());
    dispatch(resetHslice());
  };

}, [supervisorsList, hospitals]);

let rolesList = []
if (hospitalType?.toLocaleLowerCase() === "telemedicina") {
  rolesList = rolesListTelemedicina;
} else if (hospitalType?.toLocaleLowerCase() === "caravana") {
  rolesList = rolesListCaravana;
} else {
  rolesList = []
}

const validate = async values => {
    const newValues = {
      ...values,
      hospital: values.hospital?.value,
      roles: values.roles?.value,   
    };
    return getFormErrors(newValues, getUserSchema(true, true))
  };

  const onSubmit = async (values) => {
    const formData = new FormData();

    formData.append('firstName', values.firstName);
    formData.append('lastName', values.lastName);
    formData.append('email', values.email);
    formData.append('plainPassword', values.plainPassword);
    formData.append('phone', values.phone);
    formData.append('roles[]', 'ROLE_USER');

    let userStateData = {
        startDate: values?.endDate
            ? format(values?.startDate, "dd-MM-yyyy")
            : format(new Date(), "dd-MM-yyyy"),
        endDate: values?.endDate
            ? format(values?.endDate, "dd-MM-yyyy")
            : undefined,
        hospital: values?.hospital?.value,
        roles: [values?.roles?.value],
        specializations: values?.specializations?.value
            ? [values?.specializations?.value]
            : undefined,
        supervisor: values?.supervisor?.value,
        title: values?.title?.value,
    }
    try {
        const userResponse = await dispatch(
          createUser(formData)
        ).unwrap();
        const stateResponse = await dispatch(createUserState({...userStateData, user: userResponse?.['@id']})).unwrap()
        if(userResponse && stateResponse) {
            toast.success("Medicul a fost adaugat cu succes!")
            navigate("/users-list/active");
            dispatch(reset())
            dispatch(fetchOne(userResponse.id))
        }
    } catch (err) {
        if (err.violations?.length > 0) {
            return mapResponseErrorObject(err.violations);
          } else {
            toast.error(err.message);
          }
    }
  };

  const handleHospitalChange = (selectedValue, setFormValue) => {
    setFormValue('roles', "")
    setHospitalType(selectedValue.label)
  };

  return (
    <>
      <Header />
      <NavigationBar
        title="Adauga medic"
        actionComponent={<SaveUserButton />}
        action="save"
        backAction={"/users-list/active"}
      />
      <Loader loading={isLoading} />

      <div className="container forms-container">
        <Form
          mutators={{
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
          }}
          onSubmit={onSubmit}
          initialValues={{ startDate: new Date() }}
          keepDirtyOnReinitialize
          validate={validate}
          render={({ handleSubmit, form, submitErrors }) => (
            <form
              id="user-state-form2"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {/*Forma User State*/}
              <UserFormWithState
                submitErrors={submitErrors}
                form={form}
                selectOptions={{ hospitalOptions, rolesList }}
                hospitalChange={handleHospitalChange}
              />

              <div className="bottom-actions-container centered-actions">
                <Link to={"/users-list/active"} className="back-link">
                  Inapoi
                </Link>
                <input
                  type="submit"
                  className="green-button"
                  value="Salveaza"
                />
              </div>
            </form>
          )}
        />
      </div>
    </>
  );
};

export default AddUserWithState;
