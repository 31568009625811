import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { resetPlist, getPatientCase } from "../../../slice/patientSlice";
import PatientsList from "./PatientsList";
import NavigationBar from "../../../components/common/navigation-bar";
import "../../../less/listing.less";
import "../../../less/tabs.less";
import  {
  initFilters,
} from "../../../components/common/search-field-patients";
import Loader from "../../../components/Loader/loader";
import Paginator from "react-hooks-paginator";
import {
  resetFormSlice,
  resetPatientFormCases,
  savePatientFormCases,
} from "../../../slice/formSlice";
import { iriToIdTransformer } from "../../../helpers/iri-transformer";
import { tabs } from "../data/supportRequestsTab";
import Header from "../../../components/common/header";

const AllSuportRequests = () => {
  const dispatch = useDispatch();
  const selectedRegistry = localStorage.getItem("regId");
  const { selectedState, loggedInUser } = useSelector((state) => state.users);
  const { data, isLoading, totalCount } = useSelector(
    (state) => state.patientForms.patientFormCases ?? {}
  );
  const {
    patientCases: { data: patientCases },
    isLoading: isLoadingCases,
  } = useSelector((state) => state.patients ?? { data: [] });

  const mapFilters = {
    hasMonitoringForm: false,
    "patientCase.supervisorState.user.id": loggedInUser.id,
  };

  const perPage = initFilters.perPage;
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(() => initFilters);
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (index, filter) => {
    setActiveTab(index);
    setPage(1);
    if (filter) {
      setFilters({ ...initFilters, [filter.name]: mapFilters[filter.name] });
    }
  };

  useEffect(() => {
    const formFilters = selectedState?.roles.includes("ROLE_SUPPORT_MEDIC")
      ? { "patientCase.registry.id": selectedRegistry }
      : {};

    if (filters.page !== page) {
      setFilters((prev) => ({ ...prev, page }));
    } else {
      dispatch(
        savePatientFormCases({
          ...filters,
          ...formFilters,
        })
      );
    }

    return () => {
      dispatch(resetPlist());
    };
  }, [page, filters, selectedRegistry, dispatch]);

  useEffect(() => {
    if (!data) return;

    for (const item of data) {
      dispatch(getPatientCase(iriToIdTransformer(item.patientCase)));
    }
    
  }, [data, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetPatientFormCases());
      dispatch(resetFormSlice());
    };
  }, [dispatch]);

  const TabButton = ({ tab, index }) => {
    const isActive = index === activeTab;
    return (
      <div key={tab.name} className={isActive ? 'active' : ''}>
        {isActive ? tab.name : (
          <button onClick={() => handleTabChange(index, tab?.filter)}>
            {tab.name}
          </button>
        )}
      </div>
    );
  };

  const internmentWithPatientCases = useMemo(() => {
    if (!data?.length || !patientCases.length) return;
    return data.map((intForm) => {
      const matchedPatientCase = patientCases.find((pc) =>
        pc.patientCaseForms?.some((form) => form["@id"] === intForm["@id"])
      );
      return matchedPatientCase
        ? { ...intForm, patientInfo: matchedPatientCase }
        : intForm;
    });
  }, [data, patientCases]);
  
  return (
    <>
    <Header />
      <NavigationBar title={"Cereri suport"} />
      <div className="container-fluid">
        <div className="container">
          <Loader loading={isLoading || isLoadingCases} />
          <div className="row">
            <div className="col-sm-12 col-md-12 details-tabs text-center">
              {tabs.map((tab, index) => (
                <TabButton key={tab.name} tab={tab} index={index} />
              ))}
            </div>
          </div>
        </div>
        <PatientsList
          forms={true}
          patients={internmentWithPatientCases}
          filters={filters}
        />
        {data?.length === 0 && !isLoading && (
          <h4 className="text-center">Nu s-a gasit niciun rezultat</h4>
        )}
        <div className="text-center">
          <Paginator
            totalRecords={totalCount ?? 1}
            pageLimit={perPage}
            pageNeighbours={1}
            setOffset={setOffset}
            currentPage={page}
            setCurrentPage={setPage}
          />
        </div>
      </div>
    </>
  );
};

export default AllSuportRequests;