import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getPatientAppointments,
  resetPlist,
} from "../../../slice/patientSlice";
import NavigationBar from "../../../components/common/navigation-bar";
import Loader from "../../../components/Loader/loader";
import Header from "../../../components/common/header";
import { toast } from "react-toastify";

import "../../../less/listing.less";
import AppointmentFilters from "./AppointmentFilters";
import Table from "../../../components/table/Table";
import { appointmentsColumns } from "../data/columns";

const Appointments = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState();

  const {
    patientAppointments: { data } = {},
    isLoading,
    message: errorMessage,
  } = useSelector((state) => state.patients);

  const handleFilters = (filters = {}) => {
    let newFilters = {};
    if (filters.start?.value) {
      newFilters[filters.start.name] = filters.start.value;
    }

    if (filters.end?.value) {
      newFilters[filters.end.name] = filters.end.value;
    }

    setFilters(newFilters);
  };

  useEffect(() => {
    dispatch(getPatientAppointments(filters));

    return () => dispatch(resetPlist());
  }, [filters, dispatch]);

  useEffect(() => {
    if (!errorMessage) return;

    toast.error(errorMessage);
  }, [errorMessage]);
  
  return (
    <>
      <Header />
      <NavigationBar title={"Programari"} />
      <div className="container-fluid appointments-container">
        <Loader loading={isLoading} />
        <div className="container">
          <AppointmentFilters filters={filters} handleFilters={handleFilters} />
          <Table
            columns={appointmentsColumns}
            data={data}
            clsName="patient-table"
          />

          {data?.length === 0 && !isLoading && (
            <h4 className="text-center">Nu s-a gasit niciun rezultat</h4>
          )}
        </div>
      </div>
    </>
  );
};

export default Appointments;
