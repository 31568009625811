import React, {useState} from "react";
import Loader from "../../../components/Loader/loader";
import {Logo} from "../../../components/Login/common";
import {FooterLinks} from "../../../components/Login/common";
import {useSelector, useDispatch} from "react-redux";
import {resetPWDwithToken} from "../../../slice/userSlice";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";

const PasswordReset = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [formData, setFormData] = useState({
    first: "",
    second: "",
    token: params.token,
  });

  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!formData?.first || !formData?.second) {
      toast.error("Completati ambele campuri!");
      return;
    }
    if (formData?.first !== formData?.second) {
      toast.error("Cele două parole nu sunt identice!");
      return;
    }
    try {
      const response = await dispatch(resetPWDwithToken(formData)).unwrap();
      if (response) {
        toast.success(response?.message)
        navigate("/login")
      }
    } catch (error) {
      toast.error(error);
    }
  };
  return (
      <div>
        <div className="container login-container">
          {/* <Loader loading={isLoading} /> */}
          <div className="row">
            <Logo/>
            <div className="col-sm-12 col-md-12">
              <h3 className="text-center">Reseteaza parola</h3>
              <p>
                <small>Introduceti o noua parola</small>
              </p>
            </div>
            <div className="col-sm-12 col-md-12 login-form">
              <form>
                <div className="form-group">
                  <input
                      type="password"
                      name="first"
                      placeholder="Parola noua"
                      onChange={onChange}
                      value={formData.first}
                      className="form-control"
                  />
                </div>
                <div className="form-group">
                  <input
                      type="password"
                      name="second"
                      placeholder="Confirma parola noua"
                      onChange={onChange}
                      value={formData.second}
                      className="form-control"
                  />
                </div>
                <button className="btn btn-default" onClick={onSubmit}>
                  Salveaza
                </button>
              </form>
            </div>
          </div>
          <FooterLinks/>
        </div>
      </div>
  );
};

export default PasswordReset;
