import qs from "qs";
import { request } from "../constants/axios-warpers";

//get patients
const fetchingPatientsList = async (filters = {}) => {
  let searchInput = false;
  if (filters.search) {
    delete filters.search;
    searchInput = true;
  }

  const selectedHospital = localStorage.getItem("selected-hospital");
  const selectedRegistry = localStorage.getItem("regId");
  if (selectedHospital) {
    // filters["hospital"] = selectedHospital;
    // filters["patientCases.supervisorState.hospital.id"] = selectedHospital;
  }
  //patientCases.supervisorState.hospital.id;
  if (selectedRegistry) {
    filters["patientCases.registry.id"] = selectedRegistry;
  }

  const response = await request.get("/patients?" + qs.stringify(filters));

  return response.data["hydra:member"];
};
//hospital input
const checkPatientCNP = async (data) => {
  const response = await request.get(
    `/patient/${data?.cnp}/${data?.registry}/${data?.hospital}/case-info`
  );

  return response.data;
};

const fetchHospitalInput = async (filters = {}) => {
  const response = await request.get("hospitals?" + qs.stringify(filters));

  return response.data["hydra:member"];
};

// fetch Serch request
const fetchPatientSerch = async (filters = {}) => {
  let searchInput = false;
  if (filters.search) {
    delete filters.search;
    searchInput = true;
  }
  const selectedHospital = localStorage.getItem("selected-hospital");

  if (selectedHospital) {
    filters["hospital"] = selectedHospital;
  }

  const response = await request.get("/patients?" + qs.stringify(filters));

  return response.data["hydra:member"];
};

const fetchPatientSearchFilter = async (filters = {}) => {
  const selectedHospital = localStorage.getItem("selected-hospital");
  const selectedRegistry = localStorage.getItem("regId");
  if (selectedRegistry) {
    filters["patientCases.registry.id"] = selectedRegistry;
  }
  const response = await request.get("/patients?" + qs.stringify(filters));

  return {
    data: response.data["hydra:member"],
    totalCount: response.data["hydra:totalItems"],
  };
};

const fetchingPatientsSupervisors = async (filters = {}) => {
  //to do this roles check
  filters["states.roles"] = ["ROLE_SUPERVISOR", "ROLE_HOSPITAL_ADMIN", "ROLE_SUPPORT_MEDIC"];
  const response = await request.get("users?" + qs.stringify(filters));

  return response.data["hydra:member"];
};

const fetchingPatientsResidents = async (filters = {}) => {
  const regName = localStorage.getItem('regName')?.toLocaleLowerCase()
  if (regName === "telemedicina") {
    filters["states.roles"] = "ROLE_FAMILY_MEDIC";
  }
  // filters["states.roles"] = "ROLE_RESIDENT";
  const response = await request.get("users?" + qs.stringify(filters));

  return response.data["hydra:member"];
};

const fetchingPatient = async (patientId) => {
  const response = await request.get("patients/" + patientId);

  return response.data;
};

const fetchingPatientsInput = async (filters = {}) => {
  let searchInput = false;
  if (filters.search) {
    delete filters.search;
    searchInput = true;
  }
  const selectedHospital = localStorage.getItem("selected-hospital");
  if (selectedHospital) {
    filters["hospital"] = selectedHospital;
  }
  const response = await request.get("/patients?" + qs.stringify(filters));

  return response.data["hydra:member"];
};

export const addPatient = async (form) => {
  const response = await request.post("patients", form);

  return response.data;
};

export const addPatientCase = async (form) => {
  const response = await request.post("patient_cases", form);

  return response.data;
};

export const getPatientCase = async (id) => {
  const response = await request.get("patient_cases/" + id);

  return response.data;
};

const getPatientAppointments = async (filters = {}) => {
  const response = await request.get("appointments?" + qs.stringify(filters));

  return response.data
};

export const editPatient = async (formPayload, id) => {
  const response = await request.put("patients/" + id, formPayload);

  return response.data;
};
export const updatePatientCase = async (formPayload, id) => {
  const response = await request.put("patient_cases/" + id, formPayload);

  return response.data;
};

const patientApiService = {
  fetchingPatientsList,
  fetchHospitalInput,
  fetchingPatientsSupervisors,
  fetchingPatientsResidents,
  fetchingPatient,
  fetchPatientSerch,
  fetchPatientSearchFilter,
  addPatient,
  editPatient,
  fetchingPatientsInput,
  checkPatientCNP,
  addPatientCase,
  updatePatientCase,
  getPatientCase,
  getPatientAppointments
};

export default patientApiService;
