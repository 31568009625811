import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const NavigationBar = (props) => {
  const cssClasses = props.search
    ? " col-sm-8 col-md-8"
    : " col-sm-6 col-md-6 col-xs-6";
  const actions = props.search ? "" : props.actionComponent;

  return (
    <div className="container-fluid navigation-page-top">
      <div className="container">
        <div className="col-sm-3 col-md-3 col-xs-3">
          <Link to={props.backAction} className="back-link">
            {props.backText}
          </Link>
        </div>
        <div
          className={
            actions?.length > 1
              ? `title-container col-sm-4 col-md-4 col-xs-4`
              : `title-container ${cssClasses}`
          }
        >
          <span className="nav-title">{props.title}</span>
        </div>
        {Array.isArray(actions)
          ? actions.map((item, index) => <div key={index}>{item}</div>)
          : actions}
      </div>
    </div>
  );
};

NavigationBar.propTypes = {
  //actionComponent: PropTypes.object,
  backAction: PropTypes.string,
  backText: PropTypes.string,
};

NavigationBar.defaultProps = {
  backText: "Inapoi",
  backAction: "/",
};

export default NavigationBar;
