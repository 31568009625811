import {createSlice, createAsyncThunk, createAction} from "@reduxjs/toolkit";
import patientApiService from "../api/patientApiService";
import initialState from "../constants/initialState";

export const setSupervisorsInput = createAction(
    "patient/SET_SUPERVISORS_INPUT",
    (payload) => {
      return {
        payload,
      };
    }
);

export const setResidentsInput = createAction(
    "patient/SET_RESIDENTS_INPUT",
    (payload) => {
      return {
        payload,
      };
    }
);

export const setPatientList = createAsyncThunk(
    "patient/GET_LIST",
    async (data, searchInput = false, thunkAPI) => {
      let type = searchInput
          ? "SET_SEARCH_INPUT_PATIENTS_LIST"
          : "SET_PATIENTS_LIST";
      try {
        return await patientApiService.fetchingPatientsList(data);
      } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const verifyPatientCnp = createAsyncThunk(
    "patient/CHECK_CNP",
    async (data, thunkAPI) => {
      try {
        return await patientApiService.checkPatientCNP(data);
      } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const setHospitalInput = createAsyncThunk(
    "patient/SET_HOSPITAL_INPUT",
    async (filters, thunkAPI) => {
      try {
        return await patientApiService.fetchHospitalInput(filters);
      } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const setPatientSupervisorInput = createAsyncThunk(
    "patient/SET_PATIENT_SUPERVISOR_INPUT",
    async (filters, thunkAPI) => {
      try {
        return await patientApiService.fetchingPatientsSupervisors(filters);
      } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const setPatientResidentInput = createAsyncThunk(
    "patient/SET_PATIENT_RESIDENT_INPUT",
    async (filters, thunkAPI) => {
      try {
        return await patientApiService.fetchingPatientsResidents(filters);
      } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const getOnePatient = createAsyncThunk(
    "patient/GET_ONE",
    async (patientId, thunkAPI) => {
      try {
        return await patientApiService.fetchingPatient(patientId);
      } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const createPatient = createAsyncThunk(
    "patient/CREATE_PATIENT",
    async (form, thunkAPI) => {
      try {
        return await patientApiService.addPatient(form);
      } catch (error) {
        const violations = error?.response?.data?.violations || [];
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue({message, violations});
      }
    }
);
export const postPatientCase = createAsyncThunk(
    "patient/CREATE_PATIENT_CASE",
    async (form, thunkAPI) => {
      try {
        return await patientApiService.addPatientCase(form);
      } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const getPatientCase = createAsyncThunk(
  "patient/GET_PATIENT_CASE",
  async (data, thunkAPI) => {
    try {
      return await patientApiService.getPatientCase(data)
    } catch (error) {
      const message =
          (error.response &&
              error.response.data &&
              error.response.data.message) ||
          error.message ||
          error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPatientSearchResults = createAsyncThunk(
    "patient/GET_SEARCH_RESULTS",
    async (data, thunkAPI) => {
      try {
        return await patientApiService.fetchPatientSerch(data);
      } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const getPatientSearchResultsFiltered = createAsyncThunk(
  "patient/GET_SEARCH_RESULTS_FILTERED",
  async (data, thunkAPI) => {
    try {
      return await patientApiService.fetchPatientSearchFilter(data);
    } catch (error) {
      const message =
          (error.response &&
              error.response.data &&
              error.response.data.message) ||
          error.message ||
          error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updatePatient = createAsyncThunk(
    "patient/UPDATE_PATIENT",
    async (data, thunkAPI) => {
      try {
        const {id, ...formPayload} = data;

        return patientApiService.editPatient(formPayload, id);
      } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);
export const updatePatientCase = createAsyncThunk(
    "patient/UPDATE_PATIENT_CASE",
    async (data, thunkAPI) => {
      try {
        const {id, ...formPayload} = data;

        return patientApiService.updatePatientCase(formPayload, id);
      } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const fetchPatientsInput = createAsyncThunk(
    "patients/FETCH_INPUT_PATIENTS",
    async (data, thunkAPI) => {
      try {
        return await patientApiService.fetchingPatientsInput(data);
      } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);

export const getPatientAppointments = createAsyncThunk(
  "patients/FETCH_PATIENT_APPOINTMENTS",
  async (data, thunkAPI) => {
    try {
      return await patientApiService.getPatientAppointments(data);
    } catch (error) {
      const message =
          (error.response &&
              error.response.data &&
              error.response.data.message) ||
          error.message ||
          error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const patientSlice = createSlice({
  name: "patient",
  initialState: initialState.patients,
  reducers: {
    resetPlist: (state) => {
      state.isSucess = false;
      state.isError = false;
      state.isLoading = false;
      state.message = "";
    },
    clearPatientsList: (state) => {
      state.patients = [];
      state.patientSearchResultsFiltered.data = []
      state.patientSearchResultsFiltered.totalCount = 0
    },
    clearPatientsInput: (state) => {
      state.searchInputPatients.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(setPatientList.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(setPatientList.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSucess = true;
          state.patients = action.payload;
        })
        .addCase(setPatientList.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
        })
        .addCase(setHospitalInput.pending, (state) => {
          state.hospitalsInput.isLoading = true;
        })
        .addCase(setHospitalInput.fulfilled, (state, action) => {
          let hospitalsList = action.payload.map((item) => {
            return {
              value: item["@id"],
              label: item.name,
            };
          });
          state.hospitalsInput.isLoading = false;
          state.hospitalsInput.actionisSucess = true;
          state.hospitalsInput.data = hospitalsList;
        })
        .addCase(setHospitalInput.rejected, (state, action) => {
          state.hospitalsInput.isLoading = false;
          state.hospitalsInput.isError = true;
          state.hospitalsInput.message = action.payload;
        })
        .addCase(setPatientSupervisorInput.pending, (state) => {
          state.supervisorsInput.isLoading = true;
        })
        .addCase(setPatientSupervisorInput.fulfilled, (state, action) => {
          state.supervisorsInput.isLoading = false;
          state.supervisorsInput.isSucess = true;
          state.supervisorsInput.data = action.payload;
        })
        .addCase(setPatientSupervisorInput.rejected, (state, action) => {
          state.supervisorsInput.isLoading = false;
          state.supervisorsInput.isError = true;
          state.supervisorsInput.message = action.payload;
        })
        .addCase(setPatientResidentInput.pending, (state) => {
          state.residentsInput = {isLoading: true};
        })
        .addCase(setPatientResidentInput.fulfilled, (state, action) => {
          state.residentsInput.isLoading = false;
          state.residentsInput.isSucess = true;
          state.residentsInput.data = action.payload;
        })
        .addCase(setPatientResidentInput.rejected, (state, action) => {
          state.residentsInput.isLoading = false;
          state.residentsInput.isError = true;
          state.residentsInput.message = action.payload;
        })
        .addCase(getOnePatient.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getOnePatient.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSucess = true;
          state.viewedPatient = action.payload;
        })
        .addCase(getOnePatient.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
        })
        .addCase(createPatient.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(createPatient.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSucess = true;
          state.patients.push(action.payload);
        })
        .addCase(createPatient.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
        })
        .addCase(getPatientSearchResults.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getPatientSearchResults.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSucess = true;
          state.patientSearchResults = action.payload;
        })
        .addCase(getPatientSearchResults.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
        })
        .addCase(updatePatient.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(updatePatient.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSucess = true;
          state.viewedPatient = action.payload;
        })
        .addCase(updatePatient.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
        })
        .addCase(fetchPatientsInput.pending, (state) => {
          state.searchInputPatients.isLoading = true;
        })
        .addCase(fetchPatientsInput.fulfilled, (state, action) => {
          state.searchInputPatients.isLoading = false;
          state.searchInputPatients.isSucess = true;
          state.searchInputPatients.data = action.payload;
        })
        .addCase(fetchPatientsInput.rejected, (state, action) => {
          state.searchInputPatients.isLoading = false;
          state.searchInputPatients.isError = true;
          state.searchInputPatients.message = action.payload;
        })
        .addCase(setSupervisorsInput, (state, action) => {
          state.supervisorsInput.data = action.payload;
        })
        .addCase(setResidentsInput, (state, action) => {
          state.residentsInput.data = action.payload;
        })
        .addCase(verifyPatientCnp.pending, (state) => {
          state.saved_cnp.isLoading = true;
        })
        .addCase(verifyPatientCnp.fulfilled, (state, action) => {
          state.saved_cnp.isLoading = false;
          state.saved_cnp.isSucess = true;
          state.saved_cnp.data = action.payload;
        })
        .addCase(verifyPatientCnp.rejected, (state, action) => {
          state.saved_cnp.isLoading = false;
          state.saved_cnp.isError = true;
          state.saved_cnp.message = action.payload;
        })
        .addCase(postPatientCase.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
        })
        .addCase(postPatientCase.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSucess = false;
        })
        .addCase(postPatientCase.pending, (state, action) => {
          state.isLoading = true;
        })
        .addCase(updatePatientCase.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
        })
        .addCase(updatePatientCase.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSucess = false;
        })
        .addCase(updatePatientCase.pending, (state, action) => {
          state.isLoading = true;
        })
        .addCase(getPatientCase.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
        })
        .addCase(getPatientCase.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSucess = false;
          const exist = state.patientCases?.data.some(
            (form) => form.id === action.payload.id
          );
          if (!exist) state.patientCases.data.push(action.payload);
        })
        .addCase(getPatientCase.pending, (state, action) => {
          state.isLoading = true;
        })
        .addCase(getPatientAppointments.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
          state.patientAppointments.data = null
        })
        .addCase(getPatientAppointments.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSucess = true;
          const appointments = Object.values(action.payload ?? {})
          state.patientAppointments.data = appointments
        })
        .addCase(getPatientAppointments.pending, (state, action) => {
          state.isLoading = true;
        })
        .addCase(getPatientSearchResultsFiltered.pending, (state) => {
          state.patientSearchResultsFiltered.isLoading = true;
        })
        .addCase(getPatientSearchResultsFiltered.fulfilled, (state, action) => {
          state.patientSearchResultsFiltered.isLoading = false;
          state.patientSearchResultsFiltered.isSucess = true;
          state.patientSearchResultsFiltered.data = action.payload.data;
          state.patientSearchResultsFiltered.totalCount = action.payload.totalCount;
        })
        .addCase(getPatientSearchResultsFiltered.rejected, (state, action) => {
          state.patientSearchResultsFiltered.isLoading = false;
          state.patientSearchResultsFiltered.isError = true;
          state.patientSearchResultsFiltered.message = action.payload;
        });
  },
});

export const {resetPlist, clearPatientsList, clearPatientsInput, setPatientWithDifferentRegistry} =
    patientSlice.actions;
export default patientSlice.reducer;
