import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import NavigationBar from "../../../components/common/navigation-bar";
import {fetchOne, reset, updateUser} from "../../../slice/userSlice";
import {getFormErrors} from "../../../helpers/validate-form";
import {Link} from "react-router-dom";
import UserForm from "../forms/User-Form";
import SaveUserButton from "../../../components/Buttons/save-user-button";
import Header from "../../../components/common/header";
import Loader from "../../../components/Loader/loader";
import "../../../less/forms.less";
import {getUserSchema} from "../data/helpers";
import {toast} from "react-toastify";
import { mapResponseErrorObject } from "../../../helpers/response-map-functions";

const EditUser = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState({});
  const {viewedUser, isLoading} = useSelector((state) => state.users);
  const {isLoading: isLoadingData} = useSelector(
      (state) => state.users.users
  );
  const [userFields, setUserFileds] = useState({});

  const setValues = (values) => {
    setUserFileds({...values});
  };

  const handleFocus = (value) => {
    setFormErrors((prevState) => {
      const copy = {...prevState};
      delete copy[value];
      return copy;
    });
  };

  const getFullName = () =>
      (viewedUser?.firstName || viewedUser?.lastName) &&
      viewedUser?.firstName + " " + viewedUser?.lastName;

  useEffect(() => {
    dispatch(fetchOne(params.id));

    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    setUserFileds({
      ...viewedUser,
    });
  }, [viewedUser]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    let firstNameInput = userFields?.firstName,
        lastNameInput = userFields?.lastName,
        phoneInput = userFields?.phone,
        emailInput = userFields?.email,
        plainPassword = userFields?.plainPassword,
        formData = new FormData(document.getElementById("user-form"));
    formData.append("_method", "PUT");
    const inputsToValidate = {
      firstName: firstNameInput,
      lastName: lastNameInput,
      phone: phoneInput,
      email: emailInput,
    };
    const userSchema = getUserSchema(false);
    const errors = getFormErrors(inputsToValidate, userSchema);
    setFormErrors(errors);
    const valid = Object.keys(errors).length === 0;
    try {
      if (valid) {
        const respone = await dispatch(
          updateUser({
            first: formData,
            second: viewedUser.id,
          })
        ).unwrap();
        if (respone) {
          toast.success("Informatiile au fost editate cu succes!");
        }
      }
    } catch (error) {
      if (error?.violations?.length > 0) {
        const mapErrors = mapResponseErrorObject(error?.violations);
        setFormErrors((prev) => {
          return { ...prev, ...mapErrors };
        });
      } else {
        toast.error(error?.message);
      }
    }
  };

  const actionsContainer = (
      <>
        <Link to="/users-list/active" className="back-link btn--spaced-right">
          Inapoi
        </Link>
        <input type="submit" className="green-button" value="Salveaza"/>
      </>
  );

  return (
      <>
        <Header/>
        <Loader loading={isLoading || isLoadingData}/>
        <div className="container-fluid">
          <NavigationBar
              title={getFullName()}
              action="edit-user"
              actionComponent={
                <SaveUserButton
                    buttonClass="green-link"
                    userId={viewedUser?.id || ""}
                />
              }
              backAction="/users-list/active"
              backText="Lista"
          />
          <div className="container forms-container">
            <form
                id="user-form"
                onSubmit={(e) => handleFormSubmit(e)}
                autoComplete="off"
            >
              <UserForm
                  {...userFields}
                  sendValues={setValues}
                  errors={formErrors}
                  handleFocus={handleFocus}
                  actionsContainer={actionsContainer}
              />
            </form>
          </div>
        </div>
      </>
  );
};

export default EditUser;
