import React from "react";
import {Route, Routes} from "react-router-dom";
import HomeLayout from "../../home/components/HomeLayout";
import PatientInfo from "../components/PatientInfo";
import EditPatient from "../components/EditPatient";
import PatientActiveList from "../components/PatientActiveList";
import PatientInactiveList from "../components/PatientInactiveList";
import PatientFormsTab from "../components/PatientFormsTab";
import RequireAuth from "../../../components/requireauth/requireauth";
import {canViewPatientList} from "../../../security/customPagePermission";
import AddPatientStep1 from "../components/AddPatientStep1";
import AddPatient from "../components/AddPatient";
import { canAddPatient } from "../../../security/customPermission";

const PatientRoutes = () => (
  <Routes>
    <Route path="patients-list" element={<HomeLayout />}>
      <Route element={<RequireAuth pagePermission={canViewPatientList} />}>
        <Route path="active" element={<PatientActiveList />} />
        <Route path="inactive" element={<PatientInactiveList />} />
        {/* <Route path="inactive" element={<PatientInactiveList/>}/> */}

        <Route path=":id" element={<PatientInfo />} />
        <Route element={<RequireAuth pagePermission={canAddPatient} />}>
          <Route path=":id/edit" element={<EditPatient />} />
        </Route>
        {/* <Route path=":id/internment" element={<PatientInternmentTab />} />
        <Route path=":id/monitoring" element={<PatientMonitoringTab />} /> */}
        <Route path=":id/forms" element={<PatientFormsTab />} />
      </Route>
    </Route>
    <Route element={<RequireAuth pagePermission={canAddPatient} />}>
      <Route path="/add-patient">
        <Route index element={<AddPatientStep1 />} />
        <Route path="step2" element={<AddPatient />} />
      </Route>
    </Route>
  </Routes>
);

export default PatientRoutes;
