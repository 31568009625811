import React, { useState } from "react";

const TableHeader = (props) => {
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");
  const { columns, onSort } = props;
  const handleSortingChange = (column) => {
    const sortOrder = column === sortField && order === "asc" ? "desc" : "asc";
    setSortField(column);
    setOrder(sortOrder);
    onSort(column, sortOrder);
  };

  return (
    <thead className="thead-dark" key="header-1">
      <tr key="header-0">
        {columns &&
          columns.map(({ title, sortKey, sortable }, index) => {
            const cl = sortable
              ? sortField === sortKey && order === "asc"
                ? "glyphicon glyphicon-sort-by-alphabet"
                : sortField === sortKey && order === "desc"
                ? "glyphicon glyphicon-sort-by-alphabet-alt"
                : "glyphicon glyphicon-sort"
              : " ";
            return (
              <th
                key={index}
                onClick={sortable ? () => handleSortingChange(sortKey) : null}
                className={sortable ? "sortable" : null}
              >
                <div>
                  {title}
                  <span className={cl}></span>
                </div>
              </th>
            );
          })}
      </tr>
    </thead>
  );
};

export default TableHeader;
