import React, {useEffect, useState} from "react";
import SaveHospitalButton from "../../../components/Buttons/save-hospital-button";
import NavigationBar from "../../../components/common/navigation-bar";
import {Link, useNavigate} from "react-router-dom";
import HospitalForm from "../forms/hospital-form";
import {useDispatch, useSelector} from "react-redux";
import {createHospital, resetHslice} from "../../../slice/hospitalSlice";
import {toast} from "react-toastify";
import {hospitalValidationSchema} from "../data/helpers";
import {getFormErrors} from "../../../helpers/validate-form";
import { mapResponseErrorObject } from "../../../helpers/response-map-functions";

const AddHospital = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {isSucess, isError, message} = useSelector(
      (state) => state.hospitals
  );
  const [formErrors, setFormErrors] = useState({});
  const [newHospital, setNewHospital] = useState({
    name: "",
    street: "",
    streetNumber: "",
    zipCode: "",
    country: "",
    county: "",
    city: "",
  });
  const [redirectTarget, setRedirectTarget] = useState(false);

  useEffect(() => {
    if (isSucess) {
      dispatch(resetHslice());
      setNewHospital({});
    }
  }, [isSucess, dispatch, navigate]);

  const handleChanges = (e) => {
    setNewHospital({
      ...newHospital,
      [e.target.name]: e.target.value,
    });
  };

  const handleFocus = (value) => {
    setFormErrors((prevState) => {
      const copy = {...prevState};
      delete copy[value];
      return copy;
    });
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    let formPayload = newHospital;

    const inputsToValidate = {
      name: formPayload.name,
      street: formPayload.street,
      streetNumber: formPayload.streetNumber,
      zipCode: formPayload.zipCode,
      country: formPayload.country,
      county: formPayload.county,
      city: formPayload.city,
    };

    const errors = getFormErrors(inputsToValidate, hospitalValidationSchema);
    setFormErrors(errors);
    const valid = Object.keys(errors).length === 0;
    if (valid) {
      try {
        const response = await dispatch(createHospital(inputsToValidate)).unwrap()
        if (response) {
          toast.success('Asociatie adaugata cu succes!')
          if (redirectTarget) {
            navigate("/add-user");
          } else {
            navigate("/hospitals/list");
          }
        }
      } catch (err) {
        if (err?.violations?.length > 0) {
          const mapErrors = mapResponseErrorObject(err?.violations);
          setFormErrors((prev) => {
            return { ...prev, ...mapErrors };
          });
        } else {
          toast.error(err?.message);
        }
      }
    }
  };
  const actionContainer = (
      <>
        <Link to="/hospitals/list" className="back-link btn--spaced-right">
          Inapoi
        </Link>
        <button
            type="submit"
            className="next-btn btn--spaced-right btn--style-clean"
            onClick={() => setRedirectTarget(true)}
        >
          Adauga administrator
        </button>
        <input
            type="submit"
            className="green-button"
            onClick={() => setRedirectTarget(false)}
            value="Adauga"
        />
      </>
  );
  return (
      <>
        <NavigationBar
            title={"Adaugare Asociatie"}
            actionComponent={<SaveHospitalButton buttonClass="green-link"/>}
            backAction={"/hospitals/list"}
        />
        <div className="container-fluid">
          <div className="container forms-container">
            <form
                id="hospital-form"
                onSubmit={handleFormSubmit}
                autoComplete="off"
            >
              <HospitalForm
                  handleFormSubmit={handleFormSubmit}
                  handleChanges={handleChanges}
                  {...newHospital}
                  actionContainer={actionContainer}
                  errors={formErrors}
                  handleFocus={handleFocus}
              />
            </form>
          </div>
        </div>
      </>
  );
};

export default AddHospital;
