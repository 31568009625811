const initialState = {
  users: {
    viewedUser: null,
    loggedIn: !!localStorage.getItem("OncoUserToken"),
    loggedInUser: null,
    makeRedirect: false,
    titleList: { data: null },
    request: false,
    users: { data: null },
    searchInputUsers: { data: null },
    hospitalRequestUsers: { data: null },
    selectedState: null,
    switchUserEmail: localStorage.getItem("switch-user-email"),
    isSuccess: false,
    isError: false,
    isLoading: false,
    message: "",
    searchResultsUsers: [],
    supervisorsList: { data: null },
    specializationsList: { data: null },
    modalPopup: { show: false, content: "" },
    resetPWDwithToken: {},
  },
  notifications: {
    read: [],
    unRead: [],
    totalNoOfNotifications: 0,
  },
  hospitals: {
    viewedHospital: {},
    hospitals: [],
    hospitalsList: [],
    searchInputHospitals: { data: null },
    isSuccess: false,
    isError: false,
    isLoading: false,
    message: "",
    hospitalsSearchResult: [],
  },
  patients: {
    hospitalsInput: { data: null },
    supervisorsInput: { data: null },
    residentsInput: { data: null },
    viewedPatient: {
      patientForms: [],
    },
    patients: [],
    searchInputPatients: { data: null },
    isSuccess: false,
    isError: false,
    isLoading: false,
    message: "",
    saved_cnp: {
      data: null,
    },
    patientCases: { data: [] },
    patientSearchResults: [],
    patientSearchResultsFiltered: {data: []},
    patientAppointments : {data: null}
  },
  patientForms: {
    formTypes: [],
    viewedForm: {},
    formValues: {},
    isSuccess: false,
    isError: false,
    isLoading: false,
    message: "",
    patientFormCases: { data: null, totalCount: null },
    monitoringTemplates: { data: null },
  },
  registries: {
    userRegistries: { data: null },
    viewedRegistries: { data: null },
  },
};

export default initialState;
