import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ListingUsers from "./UsersList";
import { inactiveUsersList } from "../../../slice/userSlice";

const UsersInactiveList = () => {
  const dispatch = useDispatch();

  const { data: users } = useSelector((state) => state.users.users);
  const inactiveUsers = users?.filter((user) => user.states.length === 0);
  const [totalInactiveUsers, setTotalInactiveUsers] = useState(inactiveUsers);

  const { selectedState } = useSelector((state) => state.users);
  const { data: inactiveRequest } = useSelector(
    (state) => state.users.hospitalRequestUsers
  );

  useEffect(() => {
    if (
      null !== selectedState &&
      (selectedState?.roles.indexOf("ROLE_SUPERVISOR") !== -1 ||
        selectedState?.roles.indexOf("ROLE_HOSPITAL_ADMIN") !== -1) &&
      inactiveRequest === null
    ) {
      dispatch(inactiveUsersList(selectedState?.hospital?.id));
    }
  }, []);

  useEffect(() => {
    const union = Array.from(
      new Set([...(inactiveUsers || []), ...(inactiveRequest || [])])
    );
    setTotalInactiveUsers(union);
  }, [inactiveRequest?.length, inactiveUsers?.length]);

  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 details-tabs text-center">
            <div>
              <Link to={"/users-list/active"}>Medici activi</Link>
            </div>
            <div className="active">Medici inactivi</div>
          </div>
        </div>
      </div>
      <ListingUsers users={totalInactiveUsers} />
    </div>
  );
};

export default UsersInactiveList;
