import qs from "qs";
import { request, requestWithoutAuth } from "../constants/axios-warpers";
import { store } from "../store/store";

const getInternmentTemplates = async (regId) => {
  let filters = {};

  if (regId) {
    filters["registries.id"] = regId;
  }
  const response = await request.get(
    `internment-templates?` + qs.stringify(filters)
  );

  return response.data["hydra:member"];
};

const getMonitoringTemplates = async (regId) => {
  let filters = {};

  if (regId) {
    filters["registries.id"] = regId;
  }
  const response = await request.get(
    `monitoring-templates?` + qs.stringify(filters)
  );

  return response.data["hydra:member"];
};

const getInternmentFormsByPatientCases = async (data) => {
  let { id, ...filters } = data;

  filters["patientCase.patient.id"] = id

  const response = await request.get(
    `internment-forms?` + qs.stringify(filters)
  );

  return {
    data: response.data["hydra:member"],
    totalCount: response.data["hydra:totalItems"],
  };
};

const exportPatientData = async (id, monId) => {
  const response = await request.get(`caravan/export/${id}/${monId}`);

  return response.data;
};

const addInternmentTemplate = async (formType, patient, patientCases) => {
  const response = await request.post("internment-forms", {
    patient: patient,
    template: formType,
    patientCase: patientCases,
  });

  return response.data;
};

const addMonitoringTemplate = async (formType, patientCase, internmentForm) => {
  const response = await request.post("monitoring-forms", {
    patientCase,
    template: formType,
    internmentForm,
  });
  return response.data;
};

const getFormByID = async (formId) => {
  const response = await request.get("internment-forms/" + formId);

  return response.data;
};

const getMonitoringForm = async (formId) => {
  const response = await request.get("monitoring-forms/" + formId);

  return response.data;
};

const saveInternmentForm = async (form, formValues) => {
  const state = store.getState();
  const response = await request.put("internment-forms/" + form.id, {
    fieldValues: convertValuesToField(formValues, form),
    completedAt: new Date().toISOString(),
    completedBy: state.users.loggedInUser["@id"],
  });
  return response.data;
};

const saveMonitoringForm = async (form, formValues) => {
  const state = store.getState();
  const response = await request.put("monitoring-forms/" + form.id, {
    fieldValues: convertValuesToField(formValues, form),
    completedAt: new Date().toISOString(),
    completedBy: state.users.loggedInUser["@id"],
  });
};

const convertValuesToField = (formValues, form) => {
  return Object.entries(formValues).map(([fieldId, fieldValue]) => {
    let persistedObject = {};

    persistedObject[
      Array.isArray(fieldValue.value) ? "selectedValues" : "value"
    ] = fieldValue.value;
    persistedObject["field"] = fieldValue.fieldIri;
    //persistedObject["form"] = form["@id"];
    if (fieldValue.hasOwnProperty("valueIri")) {
      persistedObject["id"] = fieldValue.valueIri;
    }

    return persistedObject;
  });
};

const formApiService = {
  getInternmentTemplates,
  addInternmentTemplate,
  getFormByID,
  saveInternmentForm,
  addMonitoringTemplate,
  getMonitoringForm,
  saveMonitoringForm,
  getInternmentFormsByPatientCases,
  getMonitoringTemplates,
  exportPatientData,
};

export default formApiService;
