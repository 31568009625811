import initialState from "../constants/initialState";
import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import formApiService from "../api/formApiService";

export const setFormValues = createAction(
  "forms/SET_FORM_VALUES",
  (field, value) => {
    return {
      payload: {
        id: field.id,
        fieldIri: field["@id"],
        value: value,
      },
    };
  }
);

export const downloadPatientData = createAsyncThunk(
  "forms/DOWNLOAD_DATA",
  async (data, thunkAPI) => {
    try {
      const {
        screening: { value },
        specialitate: { value: specValue },
      } = data;
      return await formApiService.exportPatientData(value, specValue);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchFormTypes = createAsyncThunk(
  "forms/FETCH_FORM_TYPES",
  async (data, thunkAPI) => {
    try {
      return await formApiService.getInternmentTemplates(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createFormFromFormTypeRequest = createAsyncThunk(
  "forms/CREATE_FORM",
  async (data, thunkAPI) => {
    try {
      const { formTypes, id, patientCases } = data;

      return await formApiService.addInternmentTemplate(
        formTypes,
        id,
        patientCases
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createMonitoringForm = createAsyncThunk(
  "forms/CREATE_MONITORING",
  async (data, thunkAPI) => {
    try {
      const { formType, patientCase, internmentForm } = data;
      return await formApiService.addMonitoringTemplate(
        formType,
        patientCase,
        internmentForm
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getMonitoringTemplates = createAsyncThunk(
  "forms/GET_MONITORING_TEMPLATES",
  async (data, thunkAPI) => {
    try {
      return await formApiService.getMonitoringTemplates(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getFormByID = createAsyncThunk(
  "forms/GET_BY_ID",
  async (data, thunkAPI) => {
    try {
      return await formApiService.getFormByID(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getMonitoringFormById = createAsyncThunk(
  "forms/GET_MONITORING_ID",
  async (data, thunkAPI) => {
    try {
      return await formApiService.getMonitoringForm(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const savedForm = createAsyncThunk(
  "forms/SAVEDFORM",
  async (data, thunkAPI) => {
    try {
      const { form, formValues } = data;
      return formApiService.saveInternmentForm(form, formValues);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const savedMonitoringForm = createAsyncThunk(
  "forms/SAVE_MONITORING_FORM",
  async (data, thunkAPI) => {
    try {
      const { form, formValues } = data;
      return formApiService.saveMonitoringForm(form, formValues);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const savePatientFormCases = createAsyncThunk(
  "forms/SAVE_PATIENT_FORM_CASES",
  async (data, thunkAPI) => {
    try {
      // const { form, formValues } = data;
      return formApiService.getInternmentFormsByPatientCases(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const formSlice = createSlice({
  name: "forms",
  initialState: initialState.patientForms,
  reducers: {
    resetFormSlice: (state) => {
      state.isSucess = false;
      state.isError = false;
      state.isLoading = false;
      state.message = "";
    },
    resetPatientFormCases: (state) => {
      state.patientFormCases.data = null
      state.patientFormCases.totalCount = 0
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFormTypes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFormTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSucess = true;
        state.formTypes = action.payload;
      })
      .addCase(fetchFormTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createFormFromFormTypeRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createFormFromFormTypeRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSucess = true;
      })
      .addCase(createFormFromFormTypeRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getFormByID.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFormByID.fulfilled, (state, action) => {
        let form = action.payload;

        state.isLoading = false;
        state.isSucess = true;
        state.viewedForm = form;
        state.formValues = Object.assign(
          {},
          ...form.fieldValues.map(
            ({ field, value, selectedValues, ["@id"]: iri }) => ({
              [field.id]: {
                value: value || selectedValues,
                valueIri: iri,
                fieldIri: field["@id"],
              },
            })
          )
        );
      })
      .addCase(getFormByID.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(savedForm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(savedForm.fulfilled, (state) => {
        state.isLoading = false;
        state.isSucess = true;
      })
      .addCase(savedForm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(setFormValues, (state, action) => {
        let formValues = state.formValues;
        const payload = action.payload;

        if (formValues.hasOwnProperty(payload.id)) {
          formValues[payload.id]["value"] = payload.value;
        } else {
          formValues[payload.id] = {
            value: payload.value,
            fieldIri: payload.fieldIri,
          };
        }
        state.formValues = formValues;
      })
      .addCase(createMonitoringForm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createMonitoringForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSucess = true;
      })
      .addCase(createMonitoringForm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getMonitoringFormById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMonitoringFormById.fulfilled, (state, action) => {
        let form = action.payload;
        state.isLoading = false;
        state.isSucess = true;
        state.viewedForm = form;
        state.formValues = Object.assign(
          {},
          ...form.fieldValues.map(
            ({ field, value, selectedValues, ["@id"]: iri }) => ({
              [field.id]: {
                value: value || selectedValues,
                valueIri: iri,
                fieldIri: field["@id"],
              },
            })
          )
        );
      })
      .addCase(getMonitoringFormById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(savedMonitoringForm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(savedMonitoringForm.fulfilled, (state) => {
        state.isLoading = false;
        state.isSucess = true;
      })
      .addCase(savedMonitoringForm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(savePatientFormCases.pending, (state) => {
        state.patientFormCases.isLoading = true;
      })
      .addCase(savePatientFormCases.fulfilled, (state, action) => {
        state.patientFormCases.isLoading = false;
        state.patientFormCases.isSucess = true;
        state.patientFormCases.data = action.payload.data;
        state.patientFormCases.totalCount = action.payload.totalCount;
      })
      .addCase(savePatientFormCases.rejected, (state, action) => {
        state.patientFormCases.isSucess = false;
        state.patientFormCases.isError = true;
        state.patientFormCases.message = action.payload;
      })
      .addCase(getMonitoringTemplates.pending, (state) => {
        state.monitoringTemplates.isLoading = true;
      })
      .addCase(getMonitoringTemplates.fulfilled, (state, action) => {
        state.monitoringTemplates.isLoading = false;
        state.monitoringTemplates.isSucess = true;
        state.monitoringTemplates.data = action.payload;
      })
      .addCase(getMonitoringTemplates.rejected, (state, action) => {
        state.monitoringTemplates.isSucess = false;
        state.monitoringTemplates.isError = true;
        state.monitoringTemplates.message = action.payload;
      })
      .addCase(downloadPatientData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(downloadPatientData.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(downloadPatientData.rejected, (state) => {
        state.isSuccess = false;
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const { resetFormSlice, resetPatientFormCases } = formSlice.actions;

export default formSlice.reducer;
