import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Notification from "../notification/notification";
import "../../less/header-notifications.less";

const HeaderNotifications = (props) => {
  const dispatch = useDispatch();
  const { unRead } = useSelector((state) => state.notifications);
  const [notifications, setNotifications] = useState("");
  const [openNotificationPopup, setOpenNotificationPopup] = useState(false);

  const addBadge = () =>
    unRead?.length > 0 ? <span className="badge">{unRead.length}</span> : null;

  const addPopup = () => {
    if (!openNotificationPopup || unRead.length === 0) {
      return null;
    }

    return [
      <div id="popup" key={1}>
        <h2 className="text-center">Notificari necitite</h2>

        <div className="list-group list--scrollable">{addListElements()}</div>
      </div>,
      <div id="popup-bg" onClick={closePopup} key={2}>
        &nbsp;
      </div>,
    ];
  };

  const openPopup = () => {
    setOpenNotificationPopup(true);
  };

  const closePopup = () => {
    setOpenNotificationPopup(false);
  };

  const addListElements = () => {
    return unRead?.map((notification) => {
      return <Notification key={notification.id} notification={notification} />;
    });
  };

  return (
    <div className="notifications">
      <div className="icon" onClick={openPopup}>
        <span className="bell" />
        {addBadge()}
      </div>
      {addPopup()}
    </div>
  );
};

export default HeaderNotifications;
