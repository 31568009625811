import React, { useRef, useState, useEffect } from "react";

const ListActionsDropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const button = useRef(null);
  useEffect(() =>
    window.addEventListener("click", (ev) => {
      if (button.current && button.current.contains(ev.target)) {
        setIsOpen(!isOpen);
      } else {
        setIsOpen(false);
      }
    })
  );

  return (
    <div className="actions-container">
      <span className="actions-button" ref={button} />
      <div className={isOpen ? "menu show" : "menu"}>{props.children}</div>
    </div>
  );
};

export default ListActionsDropdown;
