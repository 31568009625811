import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getFormByID, savedForm, resetFormSlice } from "../slice/formSlice";
import PatientForm from "../modules/patients/forms/internment/PatientForm";
import Header from "../components/common/header";
import Loader from "../components/Loader/loader";
import useGetEditFormPermission from "../security/useGetEditFormPermission";

const InternmentForm = () => {
  const { viewedForm } = useSelector((state) => state.patientForms);
  const { viewedPatient } = useSelector((state) => state.patients);
  const { formValues, isLoading } = useSelector((state) => state.patientForms);
  const dispatch = useDispatch();
  const [canEdit] = useGetEditFormPermission(viewedForm?.["@type"]);

  const params = useParams();

  useEffect(() => {
    dispatch(getFormByID(params.id));
    return () => {
      dispatch(resetFormSlice());
    };
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(savedForm({ form: viewedForm, formValues }));
  };

  if (Object.keys(viewedForm).length === 0) {
    return <Loader loading={isLoading} />;
  }
  return (
    <>
      <Header />
      <Loader loading={isLoading} />
      <PatientForm
        form={viewedForm}
        values={formValues}
        handleSubmit={(e) => handleSubmit(e)}
        backAction={`/patients-list/${viewedPatient?.id}/forms`}
        canEdit={canEdit ?? false}
      />
    </>
  );
};

export default InternmentForm;
