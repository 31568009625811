import defaultAvatar from "../images/oncoapp_user_lightgrey.svg";

export const fetchImage = (resourceName, imageName) => {
  if (!imageName) {
    return defaultAvatar;
  }

  switch (resourceName) {
    case "user":
      return process.env.REACT_APP_API_URL + "/uploads/user/" + imageName;
    case "hospital":
      return process.env.REACT_APP_API_URL + "/uploads/hospital/" + imageName;
    default:
      return defaultAvatar;
  }
};
