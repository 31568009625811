import React from "react";
import { Outlet } from "react-router-dom";
import HospitalsDashboard from "./HospitalsDashboard";

const HospitalsLayout = (props) => {
  return (
    <>
      <HospitalsDashboard>
        <Outlet />
      </HospitalsDashboard>
    </>
  );
};

export default HospitalsLayout;
