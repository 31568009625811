import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { SCOPES } from "../../security/permission-map";
import PermissionsGate from "../../security/permisionGate";

const AddHospitalButton = (props) => {
  return (
    <PermissionsGate scopes={[SCOPES.canCreate]}>
      <div className={props.containerClass}>
        <Link to="/hospitals/add-hospital" className={props.buttonClass}>
          Adauga Asociatie
        </Link>
      </div>
    </PermissionsGate>
  );
};

AddHospitalButton.propTypes = {
  containerClass: PropTypes.string,
  buttonClass: PropTypes.string.isRequired,
};

AddHospitalButton.defaultProps = {
  containerClass: "col-sm-3 col-md-3 button-container show",
};

export default AddHospitalButton;
