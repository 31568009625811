"use strict";

import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useParams} from "react-router-dom";

import PatientForm from "../forms/Patient";
import {
  mapResponseSecondObject,
  mapResponseObject,
  mapResponseObjectCustom,
} from "../../../helpers/response-map-functions";
import {getOnePatient, updatePatient, updatePatientCase, postPatientCase} from "../../../slice/patientSlice";
import { patientStudies, patientEthnicGroup } from "../data/selectOptions";

const EditPatient = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  let {viewedPatient} = useSelector((state) => state.patients);
  const {state} = location;
  const {patient: patientToAddCase} = state ?? {};

  if (patientToAddCase) {
    viewedPatient = patientToAddCase
  }

  useEffect(() => {
    dispatch(getOnePatient(params.id));
  }, []);

  const patient = {
    id: viewedPatient?.id,
    firstName: viewedPatient?.firstName,
    lastName: viewedPatient?.lastName,
    gender:
        viewedPatient?.gender === 0
            ? {value: "0", label: "M"}
            : {value: "1", label: "F"} || null,
    phone: viewedPatient?.phone,
    ethnicGroup: viewedPatient?.ethnicGroup ? patientEthnicGroup.find(el => el?.value === viewedPatient?.ethnicGroup) : null,
    cnp: viewedPatient?.cnp,
    commune: viewedPatient?.commune,
    supervisor: viewedPatient?.patientCases?.[0]?.supervisor
        ? mapResponseSecondObject(viewedPatient?.patientCases?.[0]?.supervisor, viewedPatient?.patientCases?.[0]?.supervisorState)
        : undefined,
    resident: viewedPatient?.patientCases?.[0]?.resident
        ? mapResponseSecondObject(viewedPatient?.patientCases?.[0]?.resident, viewedPatient?.patientCases?.[0]?.residentState)
        : undefined,
    county: viewedPatient?.county,
    active:
        viewedPatient?.active === false
            ? {value: false, label: "Inactiv"}
            : {value: true, label: "Activ"} || null,
    age: viewedPatient?.age,
    educationLevel: viewedPatient?.educationLevel ? {value: +viewedPatient.educationLevel, label: viewedPatient.educationLevelLabel} : undefined,
    street: viewedPatient?.street,
    streetNumber: viewedPatient?.streetNumber,
    buildingNumber: viewedPatient?.buildingNumber,
    staircase: viewedPatient?.staircase,
    apartmentNumber: viewedPatient?.apartmentNumber,
    village: viewedPatient?.village,
    city: viewedPatient?.city,
  };
  if (patientToAddCase) {
    return (
        <PatientForm
            title={viewedPatient?.firstName + " " + viewedPatient?.lastName}
            initialValues={patient}
            // submitAction={createPatient}
            submitSecondAction={postPatientCase}
            patientToAddCase={patientToAddCase}
        />
    );
  }

  return (
      <PatientForm
          title={viewedPatient?.firstName + " " + viewedPatient?.lastName}
          initialValues={patient}
          submitAction={updatePatient}
          submitSecondAction={updatePatientCase}
      />
  );
};

export default EditPatient;
