import React from "react";
import { Outlet } from "react-router-dom";
import UserDashboard from "./UserDashboard";

const UserLayout = (props) => {
  return (
    <>
      <UserDashboard>
        <Outlet />
      </UserDashboard>
    </>
  );
};

export default UserLayout;
