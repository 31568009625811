import React from "react";
import PropTypes from "prop-types";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const RequireAuth = ({ allowedRoles, pagePermission = null }) => {
  const { loggedIn, loggedInUser, selectedState } = useSelector(
    (state) => state.users
  );
  const roles = loggedInUser?.roles ?? [];
  const location = useLocation();

  if (!loggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (
    allowedRoles &&
    allowedRoles.filter((role) => roles.includes(role)).length < 1
  ) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (
    typeof pagePermission === "function" &&
    !pagePermission({ loggedInUser, selectedState })
  ) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

RequireAuth.propTypes = {
  loggedIn: PropTypes.bool,
};
export default RequireAuth;
