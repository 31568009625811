import React from 'react';
import PropTypes from 'prop-types';
import '../../less/popover.less';

class Popover extends React.Component {
    constructor() {
        super();

        this.state = {
            isOpen: false
        };

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.close);

        this.props.openedByElements.forEach((element) => {
            document.addEventListener('click', this.close);
            const domElement = document.querySelector(element);

            if (null === domElement) {
                return null;
            }

            domElement.addEventListener('click', this.open);
        });
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.close);
    }

    open(event) {
        if (this.state.isOpen) {
            return null;
        }

        this.setState({
            isOpen: true
        });

        if (this.props.hasOwnProperty('onOpen')) {
            this.props.onOpen();
        }

        event.stopPropagation();
    }

    close() {
        if (!this.state.isOpen) {
            return null;
        }

        this.setState({
            isOpen: false
        });

        if (this.props.hasOwnProperty('onClose')) {
            this.props.onClose();
        }
    }

    render() {
        return (
            <div
                className={[
                    'app-popover',
                    'arrow-box--top',
                    this.props.containerClass,
                    !this.state.isOpen && 'hidden'
                ].filter(e => e).join(' ')}>
                {this.props.children}
            </div>
        );
    }
}

Popover.propTypes = {
    openedByElements: PropTypes.arrayOf(PropTypes.string).isRequired,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    containerClass: PropTypes.string
};

export default Popover;
