import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";

const ModalWithPortal = ({
  cssClasses,
  buttonText,
  modalState = false,
  handleShowModal,
  children,
  modal,
}) => {
  const modalPortal = createPortal(children, document.body);

  return (
    <>
      {buttonText && (
        <button className={`${cssClasses}`} onClick={handleShowModal}>
          {buttonText}
        </button>
      )}
      {modalState ? modalPortal : null}
    </>
  );
};

export default ModalWithPortal;
