import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import PermissionsGate from "../../security/permisionGate";
import { canAddUser } from "../../security/customPermission";

const AddUserButton = (props) => {
  return (
    <PermissionsGate customPermissionFn={canAddUser}>
      <div className={props.containerClass}>
        <Link to="/add-user" className={props.buttonClass}>
          Adauga medic
        </Link>
      </div>
    </PermissionsGate>
  );
};

AddUserButton.propTypes = {
  containerClass: PropTypes.string,
  buttonClass: PropTypes.string.isRequired,
};

AddUserButton.defaultProps = {
  containerClass: "col-sm-3 col-md-3 button-container show",
};

export default AddUserButton;
