"use strict";

import { format } from "date-fns";
import { validateCnp } from "./cnpValidator";

export const prepareFormValues = (values = {}) => {
  const data = {
    id: values.id,
    firstName: values.firstName,
    lastName: values.lastName,
    cnp: values.cnp,
    gender: Number(values.gender?.value),
    active: values?.active?.value,
    // hospital: values.hospital?.value,
    // supervisor: values.supervisor?.value,
    // resident: values.resident?.value,
    street: values.street,
    county: values.county,
    village: values.village,
    city: values.city,
    ethnicGroup: values.ethnicGroup.value,
    educationLevel: Number(values.educationLevel?.value),
    commune: values.commune,
    age: Number(values.age),
  };

  return data;
};

const validateLettersAndPunctuation = (input) => {
  const pattern = /^[a-zA-Z,. -]+$/;
  return pattern.test(input);
};

export const validateFormValues = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = "Required";
  }

  if (!validateLettersAndPunctuation(values.firstName)) {
    errors.firstName =
      "Sunt acceptate doar litere si urmatoarele caractere: '.', ',', '-' .";
  }

  if (!validateLettersAndPunctuation(values.lastName)) {
    errors.lastName =
      "Sunt acceptate doar litere si urmatoarele caractere: '.', ',', '-' .";
  }
  if (!values.lastName) {
    errors.lastName = "Required";
  }
  // if (!validateCnp(values.cnp)) {
  //   errors.cnp = "Introduceti un CNP valid!";
  // }
  if (!values.cnp) {
    errors.cnp = "Required";
  }
  if (!values.ethnicGroup) {
    errors.ethnicGroup = "Required";
  }
  if (values.cnp && values?.cnp?.length !== 13) {
    errors.cnp = "CNP trebuie sa aiba 13 cifre";
  }
  if (!values.resident) {
    errors.resident = "Required";
  }
  return errors;
};

export const validateStep1 = (values) => {
  const errors = {};

  if (!values.cnp) {
    errors.cnp = "Required";
  }
  if (values.cnp && values?.cnp?.length !== 13) {
    errors.cnp = "CNP trebuie sa aiba 13 cifre";
  }

  return errors;
};

export const validateExportFields = (values) => {
  const errors = {};

  if (!values.screening) {
    errors.screening = "Required";
  }
  if (!values.specialitate) {
    errors.specialitate = "Required";
  }

  return errors;
};

export const getPercentCompleted = (form) => {
  const numberOfValues = form.fieldValues.reduce(function (carry, fieldValue) {
    let validValue = true;

    if (!fieldValue.value && !fieldValue.selectedValues) {
      validValue = false;
    }

    if (!fieldValue.value || fieldValue.value === "") {
      if (
        !fieldValue.selectedValues ||
        fieldValue.selectedValues.length === 0
      ) {
        validValue = false;
      }
    }

    return validValue ? ++carry : carry;
  }, 0.0);

  if (form.fieldValues.length === 0) return 0;

  return ((numberOfValues / form.numberOfFields) * 100).toFixed(2);
};

export const formatDate = (timestamp) => {
  let date = new Date(timestamp);

  let formatter = new Intl.DateTimeFormat("ro-RO", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return formatter.format(date);
};

export const generateRandomCNP = () => {
  const min = 1000000000000;
  const max = 9999999999999;

  return `${Math.floor(Math.random() * (max - min + 1)) + min}`;
};
