import React, { useEffect } from "react";
import AddUserAccessButton from "../../../components/Buttons/add-user-access-button";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchOne,
  fetchSupervisorsList,
  reset,
} from "../../../slice/userSlice";
import format from "date-fns/format";
import Loader from "../../../components/Loader/loader";
import ListActionsDropdown from "../../../components/common/list-actions-dropdown";
import NavigationBar from "../../../components/common/navigation-bar";
import Header from "../../../components/common/header";
import PermissionsGate from "../../../security/permisionGate";
import { canAddUserAcces } from "../../../security/customPermission";
import "../../../less/user-access-history.less";
import "../../../less/tabs.less";

const UsersAccessHistory = (props) => {
  const { viewedUser, isLoading } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(fetchSupervisorsList());
    dispatch(fetchOne(params.id));
    return () => {
      dispatch(reset());
    };
  }, []);

  const getTitle = () => {
    return (viewedUser?.firstName || "") + " " + (viewedUser?.lastName || "");
  };

  const stateObjectToTableRow = (state, index) => {
    return (
      <tr key={index}>
        <td>
          <i
            className={
              state["@type"] === "State"
                ? "glyphicon glyphicon-ok-circle"
                : "glyphicon glyphicon-ban-circle"
            }
          />
        </td>
        <td>{format(Date.parse(state.startDate), "dd-MM-yyyy")}</td>
        <td>
          {state.endDate
            ? format(Date.parse(state.endDate), "dd-MM-yyyy")
            : null}
        </td>
        <td>{state.hospital ? state.hospital.name : null}</td>
        <td>{state.hospital ? state.hospital.city : null}</td>
        <td>{state.title?.name}</td>
        <td>
          {state.supervisor ? state.supervisor.firstName + " " : null}
          {state.supervisor ? state.supervisor.lastName : null}
        </td>
        <td>
          {state["@type"] === "State" ? <EditAccessLink state={state} /> : ""}
        </td>
      </tr>
    );
  };

  if (!viewedUser) {
    return null;
  }

  return (
    <div>
      <Header />
      <NavigationBar
        title={getTitle()}
        action="edit-user"
        actionComponent={
          <AddUserAccessButton
            buttonClass="green-link"
            userId={viewedUser.id}
          />
        }
        backAction="/users-list/active"
        backText="Lista"
      />

      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 details-tabs text-center">
            <div>
              <Link to={"/users/" + viewedUser.id}>Informatii</Link>
            </div>
            <div className="active">Istoric acces</div>
            <div>
              <Link to={"/users/" + viewedUser.id + "/notifications"}>
                Notificari
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Loader loading={isLoading} />
      <div className="container user-access-history details-tab-contents">
        <div className="row">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Data start</th>
                <th>Data final</th>
                <th>Asociatie</th>
                <th>Oras</th>
                <th>Functie</th>
                <th>Supervizor</th>
                <th className="actions-header">Actiuni</th>
              </tr>
            </thead>
            <tbody>
              {viewedUser?.states
                ? viewedUser.states.map(stateObjectToTableRow)
                : null}
              {viewedUser?.stateHistories
                ? viewedUser.stateHistories.map(stateObjectToTableRow)
                : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UsersAccessHistory;

export const EditAccessLink = (props) => {
  return (
    <PermissionsGate customPermissionFn={canAddUserAcces}>
      <ListActionsDropdown>
        <Link className="menu-item" to={"/states/" + props.state.id}>
          Editeaza accesul
        </Link>
      </ListActionsDropdown>
    </PermissionsGate>
  );
};
