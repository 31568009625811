import initialState from "../constants/initialState";
import notificationApiService from "../api/notificationApiService";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setUser } from "./userSlice";

export const readNotification = createAsyncThunk(
  "noti/READ_NOTIFICATION",
  async (notificationId, thunkAPI) => {
    try {
      return await notificationApiService.readNotificationRequest(
        notificationId
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const notificationSlice = createSlice({
  name: "notification",
  initialState: initialState.notifications,
  reducers: {
    state: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(readNotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(readNotification.fulfilled, (state, action) => {
        const readNotification = state.unRead.find(
          (noty) => noty.id === action.payload.id
        );

        state.isLoading = false;
        state.isSucess = true;
        state.unRead = state.unRead.filter(
          (noty) => noty?.id !== readNotification.id
        );
        state.read.push(readNotification);
      })
      .addCase(readNotification.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(setUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setUser.fulfilled, (state, action) => {
        state.unRead = action.payload.notifications?.filter(
          (notification) => !notification.isRead
        );
        state.read = action.payload.notifications?.filter(
          (notification) => notification.isRead
        );
        state.totalNoOfNotifications = action.payload.notifications?.length;
      })
      .addCase(setUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = notificationSlice.actions;
export default notificationSlice.reducer;
