import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useDetermineRouteByRole = ({ role, route, defaultRoute }) => {
  const { selectedState } = useSelector((state) => state?.users);
  const [redirectTo, setRedirectTo] = useState(defaultRoute)

  useEffect(() => {
    if (selectedState?.roles?.includes(role)) {
      setRedirectTo(route);
    }
  }, [route, role, selectedState?.roles]);

  return redirectTo
};
