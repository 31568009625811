import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import registriesApiService from "../api/registriesApiService";
import initialState from "../constants/initialState";

export const setUserRegistries = createAsyncThunk(
  "registries/USER_REGISTRIES",
  async (data, thunkAPI) => {
    try {
      return await registriesApiService.fetchingUserRegistries(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const setOneUserRegistries = createAsyncThunk(
  "registries/CURRENT_REGISTRIES",
  async (data, thunkAPI) => {
    try {
      return await registriesApiService.fetchingOneUserRegistries(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const registriesSlice = createSlice({
  name: "registries",
  initialState: initialState.registries,
  reducers: {
    resetR: (state) => {
      state.viewedRegistries.isSuccess = false;
      state.viewedRegistries.isError = false;
      state.viewedRegistries.isLoading = false;
      state.viewedRegistries.message = "";
    },
    setCurrentRegistry: (state, action) => {
      localStorage.setItem("regId", action.payload.id);
      localStorage.setItem("regName", action.payload?.internalName ?? action.payload?.name);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setUserRegistries.pending, (state) => {
        state.userRegistries.isLoading = true;
      })
      .addCase(setUserRegistries.fulfilled, (state, action) => {
        state.userRegistries.isLoading = false;
        state.userRegistries.isSuccess = true;
        state.userRegistries.data = action.payload;
      })
      .addCase(setUserRegistries.rejected, (state, action) => {
        state.userRegistries.isLoading = false;
        state.userRegistries.isError = true;
        state.userRegistries.message = action.payload;
      })
      .addCase(setOneUserRegistries.pending, (state) => {
        state.viewedRegistries.isLoading = true;
      })
      .addCase(setOneUserRegistries.fulfilled, (state, action) => {
        state.viewedRegistries.isLoading = false;
        state.viewedRegistries.isSuccess = true;
        state.viewedRegistries.data = action.payload;
      })
      .addCase(setOneUserRegistries.rejected, (state, action) => {
        state.viewedRegistries.isLoading = false;
        state.viewedRegistries.isError = true;
        state.viewedRegistries.message = action.payload;
      });
  },
});

export const { resetR, setCurrentRegistry } = registriesSlice.actions;
export default registriesSlice.reducer;
