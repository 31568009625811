import { Link } from "react-router-dom";
import InternmentPatientActions from "../components/InternmentPatientActions";
import { getPercentCompleted } from "./helpers";

export const formColumns = [
  {
    title: "Expand",
    key: "expand",
  },
  {
    title: "Tip Formular",
    key: "@type",
    renderFn: (value) => (
      <span>{value === "InternmentForm" ? "Formular Screening" : "--"}</span>
    ),
  },
  {
    title: "Nume Formular",
    key: "template",
    renderFn: (value, row) => (
      <Link to={`/internment-forms/${row?.id}`} className="form-title">
        {value?.name}
      </Link>
    ),
  },
  {
    title: "Completat de",
    key: "completedBy",
    renderFn: (value) =>
      value ? value?.firstName + " " + value?.lastName : "-",
  },
  {
    title: "Status",
    key: "fieldValues",
    renderFn: (value, form) => `${getPercentCompleted(form)} %`,
  },
  {
    title: "Data adaugarii",
    key: "completedAt",
    date: true,
    renderFn: (value) => <span>{value}</span>,
  },
  {
    title: "Data ultimei completari",
    key: "completedAt",
    date: true,
    renderFn: (value) => <span>{value}</span>,
  },
  {
    title: "Actiuni",
    key: "actions",
    sortable: false,
    renderFn: (value, form, props) => {
      return <InternmentPatientActions form={form} />;
    },
  },
];
