import { Link } from "react-router-dom";
import ListActionsDropdown from "../../../components/common/list-actions-dropdown";
import PermissionsGate from "../../../security/permisionGate";
import { canAddPatient } from "../../../security/customPermission";
import { iriToIdTransformer } from "../../../helpers/iri-transformer";
import { formatDate } from "./helpers";

export const patientColumns = [
  {
    title: "Nume",
    key: "fullName",
    sortKey: "fullName",
    sortable: true,
    renderFn: (value, item) => (
      <Link
        className="title-link"
        to={"/patients-list/" + item.id}
      >{`${item.firstName} ${item.lastName}`}</Link>
    ),
  },
  {
    title: "Judet",
    key: "county",
    sortKey: "county",
    sortable: true,
    renderFn: (value) => <span>{value}</span>,
  },
  {
    title: "Medic Specialist",
    key: "patientCases",
    sortKey: "supervisor.firstName",
    sortable: true,
    renderFn: (value) => {
      return (
        <span>
          {value[0].supervisor
            ? value[0]?.supervisor?.firstName +
              " " +
              value[0]?.supervisor?.lastName
            : ""}
        </span>
      );
    },
  },
  {
    title: "Medic Teren",
    key: "patientCases",
    sortKey: "resident.firstName",
    sortable: true,
    renderFn: (value) => (
      <span>
        {value
          ? value[0]?.resident?.firstName + " " + value[0]?.resident?.lastName
          : ""}
      </span>
    ),
  },
  {
    title: "Data creare",
    key: "createdAt",
    sortKey: "createdAt",
    sortable: true,
    renderFn: (value) => {
      return (
        <span>
          {value
            ? new Date(value).toLocaleDateString("ro-RO", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
            : "--"}
        </span>
      );
    },
  },
  {
    title: "Actiuni",
    key: "actions",
    sortable: false,
    renderFn: (value, patient) => (
      <ListActionsDropdown>
        <Link className="menu-item" to={"/patients-list/" + patient.id}>
          Informatii pacient
        </Link>
        <PermissionsGate customPermissionFn={canAddPatient}>
          <Link
            className="menu-item"
            to={"/patients-list/" + patient.id + "/edit"}
          >
            Editeaza pacientul
          </Link>
        </PermissionsGate>
      </ListActionsDropdown>
    ),
  },
];

export const patientFormsColumns = [
  {
    title: "Nume Pacient",
    key: "patientInfo",
    sortKey: "fullName",
    sortable: false,
    renderFn: (value, item) => (
      <Link
        className="title-link"
        to={`/patients-list/${iriToIdTransformer(
          value?.["patient"]?.["@id"]
        )}/forms`}
      >{`${value?.["patient"]?.firstName ?? "--"} ${
        value?.["patient"]?.lastName ?? "--"
      }`}</Link>
    ),
  },
  {
    title: "Medic Familie",
    key: "patientInfo",
    sortKey: "resident",
    sortable: false,
    renderFn: (value) => (
      <span>
        {value?.["resident"]?.["firstName"]} {value?.["resident"]?.["lastname"]}
      </span>
    ),
  },
  {
    title: "Raspuns",
    key: "monitoringForms",
    sortable: false,
    renderFn: (value) => {
      return <span>{value && value.length > 0 ? "Da" : "Nu"}</span>;
    },
  },
  {
    title: "Actiuni",
    key: "patientInfo",
    sortable: false,
    renderFn: (value, form) => (
      <ListActionsDropdown>
        <Link
          className="menu-item"
          to={`/patients-list/${iriToIdTransformer(
            value?.["patient"]?.["@id"]
          )}/forms`}
        >
          Vizualizeaza formular
        </Link>
        <Link
          className="menu-item"
          to={
            "/patients-list/" + iriToIdTransformer(value?.["patient"]?.["@id"])
          }
        >
          Informatii pacient
        </Link>
      </ListActionsDropdown>
    ),
  },
];

export const appointmentsColumns = [
  {
    title: "Nume Pacient",
    key: "name",
    sortable: false,
    renderFn: (value, item) => <span>{value ?? "--"}</span>,
  },
  {
    title: "Data programarii",
    key: "date",
    sortKey: "date",
    sortable: false,
    renderFn: (value) => <span>{value ?? "--"}</span>,
  },
  {
    title: "Ora programarii",
    key: "hour",
    sortable: false,
    renderFn: (value) => {
      return <span>{value ?? "--"}</span>;
    },
  },
  {
    title: "IRGH",
    key: "irgh",
    sortable: false,
    renderFn: (value, form) => <span>{value ? "Da" : "Nu"}</span>,
  },
];
