import * as yup from "yup";
import "yup-phone-lite";

export const getUserSchema = (isPasswordRequired, userAndState) => {
  const passwordSchemaObject = yup.string().required("Required");
  const generalSchema = yup.string();
  const userSchema = yup.object().shape({
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    plainPassword: generalSchema.concat(
      isPasswordRequired ? passwordSchemaObject : null
    ),
    phone: yup
      .string()
      .phone("RO", "Introduceti un numar de telefon valid")
      .required("Required"),
    email: yup.string().email().required("Required"),
   hospital: yup.string().when([], {
      is: () => userAndState, 
      then: yup.string().required("Required"),
      otherwise: yup.string()
    }),
    roles: yup.string().when([], {
      is: () => userAndState,
      then: yup.string().required("Required"),
      otherwise: yup.string()
    })
  });
  return userSchema;
};

export const defaultRolesList = [
  {value: "ROLE_RESIDENT", label: "Medic rezident"},
  {value: "ROLE_SUPERVISOR", label: "Medic Specialist"},
  {value: "ROLE_HOSPITAL_ADMIN", label: "Administrator asociatie"},
];

export const rolesListCaravana = [
  {value: "ROLE_RESIDENT", label: "Medic teren"},
  {value: "ROLE_SUPERVISOR", label: "Medic Specialist"},
  {value: "ROLE_HOSPITAL_ADMIN", label: "Administrator asociatie"},
];

export const rolesListTelemedicina= [
  {value: "ROLE_FAMILY_MEDIC", label: "Medic familie"},
  {value: "ROLE_SUPPORT_MEDIC", label: "Medic suport ecografie"},
  {value: "ROLE_HOSPITAL_ADMIN", label: "Administrator asociatie"},
];

export const extractUserFunction = (state) => {
  let name = "--";
  const hospitalName = state.hospital?.name?.toLowerCase();
  const role = state.roles?.[0];

  let rolesList;
  if (hospitalName === "caravana") {
    rolesList = rolesListCaravana;
  } else if (hospitalName === "telemedicina") {
    rolesList = rolesListTelemedicina;
  }

  if (rolesList) {
    const roleObject = rolesList.find(r => r.value === role);
    if (roleObject) {
      name = roleObject.label;
    }
  }

  return name;
};
